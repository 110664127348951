/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/auth/authService'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {
      x: router.history.current.query.tab === 'wallet' ? '' : 0, 
      y: router.history.current.query.tab === 'wallet' ? '' : 0 
    }
  },
  routes: [
    {
      path: '/',
      redirect: '/ar'
    },
    // =============================================================================
    // ADMIN LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/admin/AdminMain.vue'),
      children: [
        {
          path: '/',
          redirect: '/pages/login'
        },
        {
          path: 'admin/notifications',
          name: 'admin-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: 'admin/notifications'},
              { title: 'Notifications', active: true }
            ],
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/dashboard',
          name: 'admin-dashboard',
          component: () => import('@/views/admin/DashboardAnalytics.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/account-settings',
          name: 'admin-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/orders/delivery-orders',
          name: 'admin-delivery-orders-view',
          component: () => import('@/views/admin/Orders.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/orders/returns',
          name: 'admin-returns-view',
          component: () => import('@/views/admin/Returns.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/orders/exchanges/:orderID',
          name: 'admin-exchanges',
          component: () => import('@/views/merchant/order-view/ExchangeView.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/orders/:type/:orderID',
          name: 'admin-delivery-orders',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/orders/:type/:orderID',
          name: 'admin-returns',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/orders/exchanges',
          name: 'admin-exchanges-view',
          component: () => import('@/views/merchant/Exchanges.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/orders/cash-collections',
          name: 'admin-cash-collections-view',
          component: () => import('@/views/merchant/CashCollection.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/orders/:type/:orderID',
          name: 'admin-cash-collections',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/orders/supplies',
          name: 'admin-supplies',
          component: () => import('@/views/admin/Supplies.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/fulfillment/inventory',
          name: 'admin-fulfillment-inventory',
          component: () => import('@/views/fcOperationsSupervisor/Inventory.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/pricing-packages',
          name: 'admin-accounts-pricing-packages',
          component: () => import('@/views/admin/PricingPackages.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/merchants',
          name: 'admin-accounts-merchants',
          component: () => import('@/views/admin/Merchants.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/merchants/:merchantID/:tab',
          name: 'admin-merchant-view',
          component: () => import('@/views/admin/merchant-view/MerchantView.vue'),
          meta: {
            authRequired: true,
            rule: 'admin',
            breadcrumb: [
              { title: 'Dashboard', url: 'admin/dashboard' },
              { title: 'Merchants', url:'admin/accounts/merchants'},
              { title: 'Merchant View', active: true }
            ],
            pageTitle: 'Merchant View'
          }
        },
        {
          path: 'admin/accounts/blacklist',
          name: 'admin-blacklist',
          component: () => import('@/views/admin/Blacklist.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/management/warehouses',
          name: 'admin-management-warehouses',
          component: () => import('@/views/admin/Warehouses.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/management/warehouses/:warehouseID/orders',
          name: 'admin-warehouse-orders',
          component: () => import('@/views/admin/WarehouseOrders.vue'),
          meta: {
            authRequired: true,
            rule: 'admin',
            breadcrumb: [
              { title: 'Dashboard', url: 'admin/dashboard' },
              { title: 'Warehouses', url:'admin/management/warehouses'},
              { title: 'Warehouse View', active: true}
            ],
            pageTitle: 'Warehouse View'
          }
        },
        {
          path: 'admin/management/warehouses/:warehouseID/zone-groups',
          name: 'admin-warehouse-zone-groups',
          component: () => import('@/views/headOfFleet/ZoneGroups.vue'),
          meta: {
            authRequired: true,
            rule: 'admin',
            breadcrumb: [
              { title: 'Dashboard', url: 'admin/dashboard' },
              { title: 'Warehouses', url:'admin/management/warehouses'},
              { title: 'Warehouse View', active: true}
            ],
            pageTitle: 'Warehouse View'
          }
        },
        {
          path: 'admin/management/warehouses/:warehouseID/:type',
          name: 'admin-warehouse-data',
          component: () => import('@/views/admin/WarehouseView.vue'),
          meta: {
            authRequired: true,
            rule: 'admin',
            breadcrumb: [
              { title: 'Dashboard', url: 'admin/dashboard' },
              { title: 'Warehouses', url:'admin/management/warehouses'},
              { title: 'Warehouse View', active: true}
            ],
            pageTitle: 'Warehouse View'
          }
        },
        {
          path: 'admin/management/zone-groups',
          name: 'admin-management-zone-groups',
          component: () => import('@/views/admin/ZoneGroups.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/warehouse-agents',
          name: 'admin-management-managers',
          component: () => import('@/views/admin/Managers.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/customer-merchant-agents',
          name: 'admin-customer-merchant-agents',
          component: () => import('@/views/admin/CAMAs.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/growth-hackers',
          name: 'admin-management-growth-hacker',
          component: () => import('@/views/admin/GrowthHackers.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/team-leaders',
          name: 'admin-management-heads-of-fleet',
          component: () => import('@/views/admin/HeadsOfFleet.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/ops-admins',
          name: 'admin-management-ops-admin',
          component: () => import('@/views/admin/opsAdmin.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/customer-support',
          name: 'admin-customer-support',
          component: () => import('@/views/admin/CustomerSupport.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/merchant-agents',
          name: 'admin-merchant-agents',
          component: () => import('@/views/admin/MerchantAgents.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/customer-agents',
          name: 'admin-customer-agents',
          component: () => import('@/views/admin/CustomerAgents.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/travellers',
          name: 'admin-management-transporters',
          component: () => import('@/views/admin/Transporters.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/management/holidays',
          name: 'admin-holidays',
          component: () => import('@/views/admin/Holidays.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/accounting',
          name: 'admin-management-finances',
          component: () => import('@/views/admin/Finance.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/accounts/sales',
          name: 'admin-management-sales',
          component: () => import('@/views/admin/Sales.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/fulfillment/fc-operations-supervisor',
          name: 'admin-fc-operations-supervisor',
          component: () => import('@/views/admin/fulfillment/FcOperationsSupervisor.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/fulfillment/fc-receiving-agent',
          name: 'admin-fc-receiving-agent',
          component: () => import('@/views/admin/fulfillment/FcReceivingAgent.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/fulfillment/fc-inbounding-agent',
          name: 'admin-fc-inbounding-agent',
          component: () => import('@/views/admin/fulfillment/FcInboundingAgent.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/fulfillment/fc-storing-agent',
          name: 'admin-fc-storing-agent',
          component: () => import('@/views/admin/fulfillment/FcStoringAgent.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/fulfillment/fc-packing-agent',
          name: 'admin-fc-packing-agent',
          component: () => import('@/views/admin/fulfillment/FcPackingAgent.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        },
        {
          path: 'admin/fulfillment/fc-picking-agent',
          name: 'admin-fc-picking-agent',
          component: () => import('@/views/admin/fulfillment/FcPickingAgent.vue'),
          meta: {
            authRequired: true,
            rule: 'admin'
          }
        }
      ]
    },
    // =============================================================================
    // TRANSPORTER LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/agent/AgentMain.vue'),
      children: [
        {
          path: 'traveller/notifications',
          name: 'transporter-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: 'traveller/notifications'},
              { title: 'Notifications', active: true }
            ],
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'transporter'
          }
        },
        {
          path: 'traveller/account-settings',
          name: 'transporter-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'transporter'
          }
        },
        {
          path: 'traveller/dashboard',
          name: 'transporter-dashboard',
          component: () => import('@/views/transporter/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'transporter'
          }
        }
      ]
    },
    // =============================================================================
    // FINANCE LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/admin/AdminMain.vue'),
      children: [
        {
          path: 'accounting/notifications',
          name: 'finance-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: 'accounting/notifications'},
              { title: 'Notifications', active: true }
            ],
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/account-settings',
          name: 'finance-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/dashboard',
          name: 'finance-dashboard',
          component: () => import('@/views/finance/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/pricing-packages',
          name: 'finance-accounts-pricing-packages',
          component: () => import('@/views/admin/PricingPackages.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/search',
          name: 'finance-search',
          component: () => import('@/views/headOfFleet/Search.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/management/warehouses/:warehouseID/task-lists/:taskListID/:type/:orderID',
          name: 'finance-order',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'finance',
            pageTitle: 'Order View'
          }
        },
        {
          path: 'accounting/search/:type/:orderID',
          name: 'finance-search-order-view',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/accounts/merchants',
          name: 'finance-accounts-merchants',
          component: () => import('@/views/finance/Merchants.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/merchants/:merchantID/:tab',
          name: 'finance-merchant-view',
          component: () => import('@/views/admin/merchant-view/MerchantView.vue'),
          meta: {
            authRequired: true,
            rule: 'finance',
            breadcrumb: [
              { title: 'Dashboard', url: 'accounting/dashboard' },
              { title: 'Merchants', url:'accounting/accounts/merchants'},
              { title: 'Merchant View', active: true }
            ],
            pageTitle: 'Merchant View'
          }
        },
        {
          path: 'accounting/orders/exchanges/:orderID',
          name: 'finance-exchanges',
          component: () => import('@/views/merchant/order-view/ExchangeView.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/orders/:type/:orderID',
          name: 'finance-delivery-orders',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/orders/:type/:orderID',
          name: 'finance-returns',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/orders/:type/:orderID',
          name: 'finance-cash-collections',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/billing/invoices',
          name: 'finance-accounts-invoices',
          component: () => import('@/views/finance/Invoices.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/billing/statements',
          name: 'finance-accounts-statments',
          component: () => import('@/views/finance/Statements.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/billing/bank-deposits',
          name: 'finance-accounts-bank-deposits',
          component: () => import('@/views/finance/BankDeposits.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/billing/discounts',
          name: 'finance-accounts-discounts',
          component: () => import('@/views/finance/Discounts.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/payment-accounts',
          name: 'finance-accounts-payment-accounts',
          component: () => import('@/views/finance/PaymentAccounts.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/payments',
          name: 'finance-accounts-payments',
          component: () => import('@/views/finance/Payments.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/orders/supplies',
          name: 'finance-orders-supplies',
          component: () => import('@/views/finance/Supplies.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/management/warehouses/:warehouseID/task-lists',
          name: 'finance-task-lists',
          component: () => import('@/views/headOfFleet/TaskLists.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/management/warehouses/:warehouseID/task-lists/:taskListID',
          name: 'finance-task-list',
          component: () => import('@/views/headOfFleet/TaskListView.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/management/warehouses/:warehouseID/khazna',
          name: 'finance-operations-warehouses-khazna',
          component: () => import('@/views/finance/Khazna.vue'),
          meta: {
            authRequired: true,
            rule: 'finance',
            breadcrumb: [
              { title: 'Dashboard', url: 'accounting/dashboard' },
              { title: 'Warehouses', url:'accounting/management/warehouses'},
              { title: 'Khazna', active: true}
            ],
            pageTitle: 'Khazna'
          }
        },
        {
          path: 'accounting/promo-codes',
          name: 'finance-promo-codes',
          component: () => import('@/views/finance/PromoCodes.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/automated-codes',
          name: 'finance-automated-codes',
          component: () => import('@/views/finance/PromoCodes.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/promo-codes/:promoCodeID',
          name: 'finance-promo-code-view',
          component: () => import('@/views/finance/PromoCodes.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/exception-discounts',
          name: 'finance-exception-discounts',
          component: () => import('@/views/finance/ExceptionDiscount.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/instant-payouts',
          name: 'finance-instant-payouts',
          component: () => import('@/views/finance/InstantPayouts.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        },
        {
          path: 'accounting/upfront',
          name: 'finance-upfront',
          component: () => import('@/views/finance/Upfront.vue'),
          meta: {
            authRequired: true,
            rule: 'finance'
          }
        }
      ]
    },
    // =============================================================================
    // MERCHANT LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/merchant/MerchantMain.vue'),
      children: [
        {
          path: 'merchant/notifications',
          name: 'merchant-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: 'merchant/notifications'},
              { title: 'Notifications', active: true }
            ],
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/dashboard',
          name: 'merchant-dashboard',
          component: () => import('@/views/merchant/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/account-settings/:tab',
          name: 'merchant-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/integrations/:tab/:webhookID',
          name: 'webhook-events',
          component: () => import('@/views/pages/user-settings/webhooks/WebhookEvents.vue'),
          meta: {
            pageTitle: 'Webhook Events',
            authRequired: true,
            breadcrumb: [
              { title: 'Dashboard', url: 'merchant/dashboard' },
              { title: 'Webhooks', url:'merchant/integrations/webhooks'},
              { title: 'Webhook Events', active: true }
            ],
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/orders/incoming-orders',
          name: 'merchant-incoming-orders',
          component: () => import('@/views/merchant/OnlineOrders.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/orders/delivery-orders',
          name: 'merchant-shipments',
          component: () => import('@/views/merchant/Shipments.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/orders/returns',
          name: 'merchant-returns-view',
          component: () => import('@/views/merchant/Returns.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/orders/exchanges',
          name: 'merchant-exchanges-view',
          component: () => import('@/views/merchant/Exchanges.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/orders/cash-collections',
          name: 'merchant-cash-collections-view',
          component: () => import('@/views/merchant/CashCollection.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/orders/exchanges/:orderID',
          name: 'merchant-exchanges',
          component: () => import('@/views/merchant/order-view/ExchangeView.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/orders/:type/:orderID',
          name: 'merchant-delivery-orders',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/orders/:type/:orderID',
          name: 'merchant-returns',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/orders/:type/:orderID',
          name: 'merchant-cash-collections',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/orders/supplies-shop',
          name: 'merchant-supplies-shop',
          component: () => import('@/views/merchant/SuppliesShop.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/action-center',
          name: 'merchant-action-center',
          component: () => import('@/views/merchant/ActionCenter.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/supplies-payment/xpay/:transactionID',
          name: 'merchant-supplies-payment',
          component: () => import('@/views/merchant/XPayIframe.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/orders/upload-order',
          name: 'upload-order',
          component: () => import('@/views/merchant/UploadOrder.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/fulfillment/dashboard',
          name: 'merchant-fulfillment-dashboard',
          component: () => import('@/views/merchant/fulfillment/FulfillmentDashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/fulfillment/product-catalog',
          name: 'merchant-fulfillment-product-catalog',
          component: () => import('@/views/merchant/fulfillment/FulfillmentProductCatalog.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/fulfillment/stock-requests',
          name: 'merchant-fulfillment-stock-requests',
          component: () => import('@/views/merchant/fulfillment/FulfillmentStockRequests.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/fulfillment/unidentified-stock-requests',
          name: 'merchant-fulfillment-unidentified-stock-requests',
          component: () => import('@/views/merchant/fulfillment/UnidentifiedStockRequests.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/fulfillment/unidentified-stock-requests/:stockID',
          name: 'merchant-unidentified-stock-request-view',
          component: () => import('@/views/merchant/fulfillment/fulfillment-view/UnidentifiedStockRequestView.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant',
            breadcrumb: [
              { title: 'Dashboard', url: 'merchant/dashboard' },
              { title: 'UROs', url:'merchant/fulfillment/unidentified-stock-requests'},
              { title: 'URO View', active: true }
            ],
            pageTitle: 'URO View'
          }
        },
        {
          path: 'merchant/fulfillment/:type/:stockID',
          name: 'merchant-stock-request-view',
          component: () => import('@/views/merchant/fulfillment/fulfillment-view/StockRequestView.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant',
            breadcrumb: [
              { title: 'Dashboard', url: 'merchant/dashboard' },
              { title: 'Stock Requests', url:'merchant/fulfillment/stock-requests'},
              { title: 'Stock Request View', active: true }
            ],
            pageTitle: 'Stock Request View'
          }
        },
        {
          path: 'merchant/fulfillment/inbound-orders',
          name: 'merchant-fulfillment-inbound-orders',
          component: () => import('@/views/merchant/fulfillment/InboundOrders.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/fulfillment/outbound-orders',
          name: 'merchant-fulfillment-outbound-orders',
          component: () => import('@/views/merchant/fulfillment/OutboundOrders.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/fulfillment/orders/:type/:orderID',
          name: 'merchant-fulfillment-order-view',
          component: () => import('@/views/merchant/fulfillment/fulfillment-view/FulfillmentOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant',
            breadcrumb: []
          }
        },
        {
          path: 'merchant/fulfillment/inventory',
          name: 'merchant-fulfillment-inventory',
          component: () => import('@/views/merchant/fulfillment/FulfillmentInventory.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/fulfillment/reports',
          name: 'merchant-fulfillment-reports',
          component: () => import('@/views/merchant/fulfillment/FulfillmentReports.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/financials',
          name: 'merchant-account-financials',
          component: () => import('@/views/merchant/Financials.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/integrations/:tab',
          name: 'merchant-integrations',
          component: () => import('@/views/merchant/Integrations.vue'),
          meta: {
            pageTitle: 'Integrations',
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/analytics',
          name: 'merchant-analytics',
          component: () => import('@/views/merchant/Analytics.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/magic-links',
          name: 'merchant-magic-links',
          component: () => import('@/views/merchant/ExternalLinks.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        },
        {
          path: 'merchant/year-in-review',
          name: 'merchant-year-in-review',
          component: () => import('@/views/merchant/merchant-year-review/YearInReview.vue'),
          meta: {
            authRequired: true,
            rule: 'merchant'
          }
        }
      ]
    },
    // =============================================================================
    // MANAGER LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/admin/AdminMain.vue'),
      children: [
        {
          path: 'warehouse-agent/notifications',
          name: 'manager-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: 'warehouse-agent/notifications'},
              { title: 'Notifications', active: true }
            ],
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'manager'
          }
        },
        {
          path: 'warehouse-agent/dashboard',
          name: 'captain-dashboard',
          component: () => import('@/views/manager/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'manager'
          }
        },
        {
          path: 'warehouse-agent/search',
          name: 'captain-search',
          component: () => import('@/views/manager/Search.vue'),
          meta: {
            authRequired: true,
            rule: 'manager'
          }
        },
        {
          path: 'warehouse-agent/search/:type/:orderID',
          name: 'manager-search-order-view',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'manager'
          }
        },
        {
          path: 'warehouse-agent/account-settings',
          name: 'captain-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'manager'
          }
        },
        {
          path: 'warehouse-agent/warehouses/:warehouseID/orders',
          name: 'captain-orders',
          component: () => import('@/views/manager/WarehouseOrders.vue'),
          meta: {
            authRequired: true,
            rule: 'manager'
          }
        },
        {
          path: 'warehouse-agent/warehouses/:warehouseID/cash-collections',
          name: 'manager-cash-collections-view',
          component: () => import('@/views/manager/CashCollection.vue'),
          meta: {
            authRequired: true,
            rule: 'manager'
          }
        },
        {
          path: 'warehouse-agent/warehouses/:warehouseID/task-lists',
          name: 'manager-task-lists',
          component: () => import('@/views/manager/TaskSheets.vue'),
          meta: {
            authRequired: true,
            rule: 'manager'
          }
        },
        {
          path: 'warehouse-agent/warehouses/:warehouseID/task-lists/:taskListID',
          name: 'manager-task-list',
          component: () => import('@/views/manager/TaskListView.vue'),
          meta: {
            authRequired: true,
            rule: 'manager'
          }
        },
        {
          path: 'warehouse-agent/warehouses/:warehouseID/master-bags',
          name: 'manager-master-bags',
          component: () => import('@/views/manager/MasterBags.vue'),
          meta: {
            authRequired: true,
            rule: 'manager'
          }
        },
        {
          path: 'warehouse-agent/warehouses/:warehouseID/master-bags/:sealId',
          name: 'manager-seal-view',
          component: () => import('@/views/manager/SealView.vue'),
          meta: {
            authRequired: true,
            rule: 'manager',
            pageTitle: 'Master Bag View'

          }
        },
        {
          path: 'warehouse-agent/warehouses/:warehouseID/slots',
          name: 'manager-warehouse-slots',
          component: () => import('@/views/manager/SlotView.vue'),
          meta: {
            authRequired: true,
            rule: 'manager'
          }
        },
        {
          path: 'warehouse-agent/warehouses/:warehouseID/bins',
          name: 'captain-warehouse-bins',
          component: () => import('@/views/manager/BinsView.vue'),
          meta: {
            authRequired: true,
            rule: 'manager',
            pageTitle: 'Bins'
          }
        },
        {
          path: 'warehouse-agent/warehouses/:warehouseID/bins/:unitID/packages',
          name: 'manager-packages-in-bin',
          component: () => import('@/views/manager/BinPackages.vue'),
          meta: {
            authRequired: true,
            rule: 'manager',
            pageTitle: 'View Packages',
            breadcrumb: []
          }
        },
        {
          path: 'warehouse-agent/warehouses/:warehouseID/:type/:unitID/packages',
          name: 'manager-packages-in-unit',
          component: () => import('@/views/manager/PackagesInUnit.vue'),
          meta: {
            authRequired: true,
            rule: 'manager',
            pageTitle: 'View Packages',
            breadcrumb: []
          }
        },
        {
          path: 'warehouse-agent/warehouses/:warehouseID/inventory',
          name: 'manager-inventory',
          component: () => import('@/views/headOfFleet/Inventory.vue'),
          meta: {
            authRequired: true,
            rule: 'manager'
          }
        }
      ]
    },
    // =============================================================================
    // GROWTH HACKER LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/admin/AdminMain.vue'),
      children: [
        {
          path: 'growth-hacker/notifications',
          name: 'growth-hacker-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            authRequired: true,
            rule: 'growthHacker'
          }
        },
        {
          path: 'growth-hacker/account-settings',
          name: 'growth-hacker-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'growthHacker'
          }
        },
        {
          path: 'growth-hacker/dashboard',
          name: 'growth-hacker-dashboard',
          component: () => import('@/views/growthHacker/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'growthHacker'
          }
        },
        {
          path: 'growth-hacker/promo-codes',
          name: 'growth-hacker-promo-codes',
          component: () => import('@/views/growthHacker/PromoCodes.vue'),
          meta: {
            authRequired: true,
            rule: 'growthHacker'
          }
        },
        {
          path: 'growth-hacker/automated-codes',
          name: 'growth-hacker-automated-codes',
          component: () => import('@/views/growthHacker/PromoCodes.vue'),
          meta: {
            authRequired: true,
            rule: 'growthHacker'
          }
        },
        {
          path: 'growth-hacker/exception-discounts',
          name: 'growth-hacker-exception-discount',
          component: () => import('@/views/growthHacker/ExceptionDiscount.vue'),
          meta: {
            authRequired: true,
            rule: 'growthHacker'
          }
        }
      ]
    },
    // =============================================================================
    // HEAD OF FLEET LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/admin/AdminMain.vue'),
      children: [
        {
          path: 'team-leader/notifications',
          name: 'head-of-fleet-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: 'team-leader/notifications'},
              { title: 'Notifications', active: true }
            ],
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/account-settings',
          name: 'head-of-fleet-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/dashboard',
          name: 'head-of-fleet-dashboard',
          component: () => import('@/views/headOfFleet/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/search',
          name: 'head-of-fleet-search',
          component: () => import('@/views/headOfFleet/Search.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/search/:type/:orderID',
          name: 'head-of-fleet-search-order-view',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/dashboard',
          name: 'head-of-fleet-wareshouse-dashboard',
          component: () => import('@/views/headOfFleet/WarehouseDashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/khazna',
          name: 'head-of-fleet-khazna',
          component: () => import('@/views/headOfFleet/Khazna.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/first-mile-orders',
          name: 'head-of-fleet-first-mile-orders',
          component: () => import('@/views/headOfFleet/MAOrders.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/last-mile-orders',
          name: 'head-of-fleet-orders',
          component: () => import('@/views/headOfFleet/Orders.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/returns',
          name: 'head-of-fleet-returns',
          component: () => import('@/views/headOfFleet/Returns.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/orders/:type/:orderID',
          name: 'head-of-fleet-order-queue-view',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet',
            pageTitle: 'Order View'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/orders-awaiting-actions/:type/:orderID',
          name: 'head-of-fleet-orders-awaiting-actions-order-view',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/orders-awaiting-actions',
          name: 'head-of-fleet-orders-awaiting-actions',
          component: () => import('@/views/headOfFleet/OrdersAwaitingActions.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/cash-collections',
          name: 'head-of-fleet-cash-collections-view',
          component: () => import('@/views/headOfFleet/CashCollection.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/cash-collections/:type/:orderID',
          name: 'head-of-fleet-cash-collections',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            pageTitle: 'Order View',
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/zone-groups',
          name: 'head-of-fleet-zone-groups',
          component: () => import('@/views/headOfFleet/ZoneGroups.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/supplies',
          name: 'head-of-fleet-supplies',
          component: () => import('@/views/headOfFleet/SuppliesRequests.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/customer-agents',
          name: 'head-of-fleet-customer-agents',
          component: () => import('@/views/admin/CustomerAgents.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/customer-merchant-agents',
          name: 'head-of-fleet-customer-agents',
          component: () => import('@/views/admin/CAMAs.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/merchant-agents',
          name: 'head-of-fleet-merchant-agents',
          component: () => import('@/views/admin/MerchantAgents.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/task-lists',
          name: 'head-of-fleet-task-lists',
          component: () => import('@/views/headOfFleet/TaskLists.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/add-task-list',
          name: 'head-of-fleet-add-task-list',
          component: () => import('@/views/headOfFleet/components/AddTaskList.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet',
            pageTitle: 'Add Task List'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/agents-transactions',
          name: 'head-of-fleet-agents-transactions',
          component: () => import('@/views/headOfFleet/components/AgentsTransactions.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet',
            pageTitle: 'Agents Transactions'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/task-lists/:taskListID/add-orders',
          name: 'head-of-fleet-add-orders-to-task-list',
          component: () => import('@/views/headOfFleet/components/AddTaskList.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet',
            pageTitle: 'Add Orders'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/task-lists/:taskListID',
          name: 'head-of-fleet-task-list',
          component: () => import('@/views/headOfFleet/TaskListView.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet',
            pageTitle: 'Task List View'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/task-lists/:taskListID/:type/:orderID',
          name: 'head-of-fleet-order',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet',
            pageTitle: 'Order View'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/in-house-orders',
          name: 'head-of-fleet-in-house-orders',
          component: () => import('@/views/headOfFleet/InHouseOrders.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/orders/governorates',
          name: 'head-of-fleet-governorates',
          component: () => import('@/views/headOfFleet/Governorates.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet',
            pageTitle: 'Governorates Deliveries'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/master-bags',
          name: 'manager-master-bags',
          component: () => import('@/views/manager/MasterBags.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/master-bags/:sealId',
          name: 'head-of-fleet-seal-view',
          component: () => import('@/views/manager/SealView.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet',
            pageTitle: 'Master Bag View'

          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/slots',
          name: 'team-leader-warehouse-slots',
          component: () => import('@/views/manager/SlotView.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/bins',
          name: 'team-leader-warehouse-bins',
          component: () => import('@/views/manager/BinsView.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet',
            pageTitle: 'Bins'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/bins/:unitID/packages',
          name: 'head-of-fleet-packages-in-bin',
          component: () => import('@/views/manager/BinPackages.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet',
            pageTitle: 'View Packages'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/:type/:unitID/packages',
          name: 'head-of-fleet-packages-in-unit',
          component: () => import('@/views/manager/PackagesInUnit.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet',
            pageTitle: 'View Packages'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/inventory',
          name: 'team-leader-inventory',
          component: () => import('@/views/headOfFleet/Inventory.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/re-zoning',
          name: 'team-leader-rezoning',
          component: () => import('@/views/headOfFleet/ReZoning.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        },
        {
          path: 'team-leader/warehouses/:warehouseID/shared-zones',
          name: 'team-leader-shared-zones',
          component: () => import('@/views/headOfFleet/SharedZones.vue'),
          meta: {
            authRequired: true,
            rule: 'headOfFleet'
          }
        }
      ]
    },
    // =============================================================================
    // CUSTOMER SUPPORT LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/admin/AdminMain.vue'),
      children: [
        {
          path: 'customer-support/notifications',
          name: 'customer-support-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            breadcrumb: [
              { title: 'Dashboard', url: 'customer-support/notifications'},
              { title: 'Notifications', active: true }
            ],
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/account-settings',
          name: 'customer-support-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/dashboard',
          name: 'customer-support-dashboard',
          component: () => import('@/views/customerSupport/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/accounts/customers-support',
          name: 'customer-support-customer-support',
          component: () => import('@/views/customerSupport/CustomerSupport.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/orders/delivery-orders',
          name: 'customer-support-delivery-orders-view',
          component: () => import('@/views/admin/Orders.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/orders/returns',
          name: 'customer-support-returns-view',
          component: () => import('@/views/admin/Returns.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/orders/exchanges',
          name: 'customer-support-exchanges-view',
          component: () => import('@/views/merchant/Exchanges.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/orders/cash-collections',
          name: 'customer-support-cash-collections-view',
          component: () => import('@/views/customerSupport/CashCollection.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/orders/supplies',
          name: 'customer-support-supplies',
          component: () => import('@/views/customerSupport/Supplies.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/orders/exchanges/:orderID',
          name: 'customer-support-exchanges',
          component: () => import('@/views/merchant/order-view/ExchangeView.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/orders/:type/:orderID',
          name: 'customer-support-delivery-orders',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/orders/:type/:orderID',
          name: 'customer-support-returns',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/orders/:type/:orderID',
          name: 'customer-support-cash-collections',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/fulfillment/inventory',
          name: 'customer-support-fulfillment-inventory',
          component: () => import('@/views/fcOperationsSupervisor/Inventory.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/pricing-packages',
          name: 'customer-support-accounts-pricing-packages',
          component: () => import('@/views/customerSupport/PricingPackages.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/merchants',
          name: 'customer-support-accounts-merchants',
          component: () => import('@/views/customerSupport/Merchants.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        },
        {
          path: 'customer-support/merchants/:merchantID/:tab',
          name: 'customer-support-merchant-view',
          component: () => import('@/views/admin/merchant-view/MerchantView.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport',
            breadcrumb: [
              { title: 'Dashboard', url: 'customer-support/dashboard' },
              { title: 'Merchants', url:'customer-support/merchants'},
              { title: 'Merchant View', active: true }
            ],
            pageTitle: 'Merchant View'
          }
        },
        {
          path: 'customer-support/billing/statements',
          name: 'customer-support-statements',
          component: () => import('@/views/customerSupport/Statments.vue'),
          meta: {
            authRequired: true,
            rule: 'customerSupport'
          }
        }
      ]
    },
    // =============================================================================
    // SALES LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/admin/AdminMain.vue'),
      children: [
        {
          path: 'sales/notifications',
          name: 'sales-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/account-settings',
          name: 'sales-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/dashboard',
          name: 'sales-dashboard',
          component: () => import('@/views/sales/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/orders/delivery-orders',
          name: 'sales-delivery-orders-view',
          component: () => import('@/views/sales/Orders.vue'),
          meta: {
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/orders/returns',
          name: 'sales-returns-view',
          component: () => import('@/views/sales/Returns.vue'),
          meta: {
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/orders/exchanges',
          name: 'sales-exchanges-view',
          component: () => import('@/views/sales/Exchanges.vue'),
          meta: {
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/orders/cash-collections',
          name: 'sales-cash-collections-view',
          component: () => import('@/views/sales/CashCollections.vue'),
          meta: {
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/orders/supplies',
          name: 'sales-supplies',
          component: () => import('@/views/sales/Supplies.vue'),
          meta: {
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/orders/exchanges/:orderID',
          name: 'sales-exchanges',
          component: () => import('@/views/merchant/order-view/ExchangeView.vue'),
          meta: {
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/orders/:type/:orderID',
          name: 'sales-delivery-orders',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/orders/:type/:orderID',
          name: 'sales-returns',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/orders/:type/:orderID',
          name: 'sales-cash-collections',
          component: () => import('@/views/merchant/merchant-order-view/MerchantOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/merchants',
          name: 'sales-accounts-merchants',
          component: () => import('@/views/customerSupport/Merchants.vue'),
          meta: {
            authRequired: true,
            rule: 'sales'
          }
        },
        {
          path: 'sales/merchants/:merchantID/:tab',
          name: 'sales-merchant-view',
          component: () => import('@/views/admin/merchant-view/MerchantView.vue'),
          meta: {
            authRequired: true,
            rule: 'sales',
            breadcrumb: [
              { title: 'Dashboard', url: 'sales/dashboard' },
              { title: 'Merchants', url:'sales/merchants'},
              { title: 'Merchant View', active: true }
            ],
            pageTitle: 'Merchant View'
          }
        }
      ]
    },
    // =============================================================================
    // FC OPERATIONS SUPERVISOR LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/fulfillment/FulfillmentMain.vue'),
      children: [
        {
          path: 'fc-operations-supervisor/notifications',
          name: 'fc-operations-supervisor-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/account-settings',
          name: 'fc-operations-supervisor-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/dashboard',
          name: 'fc-operations-supervisor-dashboard',
          component: () => import('@/views/fcOperationsSupervisor/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/stock-requests',
          name: 'fc-operations-supervisor-fulfillment-stock-requests',
          component: () => import('@/views/fcOperationsSupervisor/StockRequests.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/unidentified-stock-requests',
          name: 'fc-operations-supervisor-fulfillment-unidentified-stock-requests',
          component: () => import('@/views/merchant/fulfillment/UnidentifiedStockRequests.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/unidentified-stock-requests/:stockID',
          name: 'fc-operations-supervisor-unidentified-stock-request-view',
          component: () => import('@/views/merchant/fulfillment/fulfillment-view/UnidentifiedStockRequestView.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior',
            breadcrumb: [
              { title: 'Dashboard', url: 'fc-operations-supervisor/dashboard' },
              { title: 'UROs', url:'fc-operations-supervisor/unidentified-stock-requests'},
              { title: 'URO View', active: true }
            ],
            pageTitle: 'URO View'
          }
        },
        {
          path: 'fc-operations-supervisor/stock-requests/:stockID',
          name: 'fc-operations-supervisor-stock-request-view',
          component: () => import('@/views/fcOperationsSupervisor/StockRequestView.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior',
            breadcrumb: [
              { title: 'Dashboard', url: 'fc-operations-supervisor/dashboard' },
              { title: 'Stock Requests', url:'fc-operations-supervisor/stock-requests'},
              { title: 'Stock Request View', active: true }
            ],
            pageTitle: 'Stock Request View'
          }
        },
        {
          path: 'fc-operations-supervisor/inventory',
          name: 'fc-operations-supervisor-inventory',
          component: () => import('@/views/fcOperationsSupervisor/Inventory.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/fulfillment/outbound-orders',
          name: 'fc-operations-supervisor-fulfillment-outbound-orders',
          component: () => import('@/views/merchant/fulfillment/OutboundOrders.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/fulfillment/inbound-orders',
          name: 'fc-operations-supervisor-fulfillment-inbound-orders',
          component: () => import('@/views/merchant/fulfillment/InboundOrders.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/fulfillment/orders/:type/:orderID',
          name: 'fc-operations-supervisor-fulfillment-order-view',
          component: () => import('@/views/merchant/fulfillment/fulfillment-view/FulfillmentOrderView.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior',
            breadcrumb: []
          }
        },
        {
          path: 'fc-operations-supervisor/bins',
          name: 'fc-operations-supervisor-bins',
          component: () => import('@/views/fcOperationsSupervisor/Bins.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/bins/:binID/products',
          name: 'fc-operations-supervisor-store-product-in-bins',
          component: () => import('@/views/fcOperationsSupervisor/StoreProductsInBin.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/bins/:binID',
          name: 'fc-operations-supervisor-bin-logs-view',
          component: () => import('@/views/fcOperationsSupervisor/BinLogs.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior',
            pageTitle: 'Bin Logs'
          }
        },
        {
          path: 'fc-operations-supervisor/boxes',
          name: 'fc-operations-supervisor-boxes',
          component: () => import('@/views/fcOperationsSupervisor/Box.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/totes',
          name: 'fc-operations-supervisor-totes',
          component: () => import('@/views/fcOperationsSupervisor/Tote.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/pallets',
          name: 'fc-operations-supervisor-pallets',
          component: () => import('@/views/fcOperationsSupervisor/Pallet.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/shelves',
          name: 'fc-operations-supervisor-shelves',
          component: () => import('@/views/fcOperationsSupervisor/Shelf.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/picking-bins',
          name: 'fc-operations-supervisor-picking-bins',
          component: () => import('@/views/fcPickingAgent/PickingBins.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        },
        {
          path: 'fc-operations-supervisor/task-lists',
          name: 'fc-operations-task-lists',
          component: () => import('@/views/fcPickingAgent/TaskLists.vue'),
          meta: {
            authRequired: true,
            rule: 'fcOperationsSupervior'
          }
        }
      ]
    },
    // =============================================================================
    // FC RECEIVING AGENT LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/fulfillment/FulfillmentMain.vue'),
      children: [
        {
          path: 'fc-receiving-agent/notifications',
          name: 'fc-receiving-agent-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'fcReceivingAgent'
          }
        },
        {
          path: 'fc-receiving-agent/account-settings',
          name: 'fc-receiving-agent-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'fcReceivingAgent'
          }
        },
        {
          path: 'fc-receiving-agent/dashboard',
          name: 'fc-receiving-agent-dashboard',
          component: () => import('@/views/fcReceivingAgent/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'fcReceivingAgent'
          }
        },
        {
          path: 'fc-receiving-agent/stock-requests',
          name: 'fc-receiving-agent-fulfillment-stock-requests',
          component: () => import('@/views/fcOperationsSupervisor/StockRequests.vue'),
          meta: {
            authRequired: true,
            rule: 'fcReceivingAgent'
          }
        },
        {
          path: 'fc-receiving-agent/unidentified-stock-requests',
          name: 'fc-receiving-agent-fulfillment-unidentified-stock-requests',
          component: () => import('@/views/merchant/fulfillment/UnidentifiedStockRequests.vue'),
          meta: {
            authRequired: true,
            rule: 'fcReceivingAgent'
          }
        },
        {
          path: 'fc-receiving-agent/stock-requests/:stockID',
          name: 'fc-receiving-agent-stock-request-view',
          component: () => import('@/views/fcReceivingAgent/StockRequestView.vue'),
          meta: {
            authRequired: true,
            rule: 'fcReceivingAgent',
            breadcrumb: [
              { title: 'Dashboard', url: 'fc-receiving-agent/dashboard' },
              { title: 'Stock Requests', url:'fc-receiving-agent/stock-requests'},
              { title: 'Stock Request View', active: true }
            ],
            pageTitle: 'Stock Request View'
          }
        },
        {
          path: 'fc-receiving-agent/unidentified-stock-requests/:stockID',
          name: 'fc-receiving-agent-unidentified-stock-request-view',
          component: () => import('@/views/fcReceivingAgent/UnidentifiedStockRequestView.vue'),
          meta: {
            authRequired: true,
            rule: 'fcReceivingAgent',
            breadcrumb: [
              { title: 'Dashboard', url: 'fc-receiving-agent/dashboard' },
              { title: 'UROs', url:'fc-receiving-agent/unidentified-stock-requests'},
              { title: 'URO View', active: true }
            ],
            pageTitle: 'URO View'
          }
        }
      ]
    },
    // =============================================================================
    // FC INBOUNDING AGENT LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/fulfillment/FulfillmentMain.vue'),
      children: [
        {
          path: 'fc-inbounding-agent/notifications',
          name: 'fc-inbounding-agent-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'fcInboundingAgent'
          }
        },
        {
          path: 'fc-inbounding-agent/account-settings',
          name: 'fc-inbounding-agent-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'fcInboundingAgent'
          }
        },
        {
          path: 'fc-inbounding-agent/dashboard',
          name: 'fc-inbounding-agent-dashboard',
          component: () => import('@/views/fcInboundingAgent/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'fcInboundingAgent'
          }
        },
        {
          path: 'fc-inbounding-agent/stock-requests',
          name: 'fc-inbounding-agent-fulfillment-stock-requests',
          component: () => import('@/views/fcOperationsSupervisor/StockRequests.vue'),
          meta: {
            authRequired: true,
            rule: 'fcInboundingAgent'
          }
        },
        {
          path: 'fc-inbounding-agent/unidentified-stock-requests',
          name: 'fc-inbounding-agent-fulfillment-unidentified-stock-requests',
          component: () => import('@/views/merchant/fulfillment/UnidentifiedStockRequests.vue'),
          meta: {
            authRequired: true,
            rule: 'fcInboundingAgent'
          }
        },
        {
          path: 'fc-inbounding-agent/stock-requests/:stockID',
          name: 'fc-inbounding-agent-stock-request-view',
          component: () => import('@/views/fcReceivingAgent/StockRequestView.vue'),
          meta: {
            authRequired: true,
            rule: 'fcInboundingAgent',
            breadcrumb: [
              { title: 'Dashboard', url: 'fc-inbounding-agent/dashboard' },
              { title: 'Stock Requests', url:'fc-inbounding-agent/stock-requests'},
              { title: 'Stock Request View', active: true }
            ],
            pageTitle: 'Stock Request View'
          }
        },
        {
          path: 'fc-inbounding-agent/unidentified-stock-requests/:stockID',
          name: 'fc-inbounding-agent-unidentified-stock-request-view',
          component: () => import('@/views/fcReceivingAgent/UnidentifiedStockRequestView.vue'),
          meta: {
            authRequired: true,
            rule: 'fcInboundingAgent',
            breadcrumb: [
              { title: 'Dashboard', url: 'fc-inbounding-agent/dashboard' },
              { title: 'UROs', url:'fc-inbounding-agent/unidentified-stock-requests'},
              { title: 'URO View', active: true }
            ],
            pageTitle: 'URO View'
          }
        },
        {
          path: 'fc-inbounding-agent/bins',
          name: 'fc-inbounding-agent-bins',
          component: () => import('@/views/fcOperationsSupervisor/Bins.vue'),
          meta: {
            authRequired: true,
            rule: 'fcInboundingAgent'
          }
        },
        {
          path: 'fc-inbounding-agent/bins/:binID',
          name: 'fc-inbounding-agent-bin-logs-view',
          component: () => import('@/views/fcOperationsSupervisor/BinLogs.vue'),
          meta: {
            authRequired: true,
            rule: 'fcInboundingAgent',
            pageTitle: 'Bin Logs'
          }
        }
      ]
    },
    // =============================================================================
    // FC STORING AGENT LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/fulfillment/FulfillmentMain.vue'),
      children: [
        {
          path: 'fc-storing-agent/notifications',
          name: 'fc-storing-agent-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'fcStoringAgent'
          }
        },
        {
          path: 'fc-storing-agent/account-settings',
          name: 'fc-storing-agent-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'fcStoringAgent'
          }
        },
        {
          path: 'fc-storing-agent/dashboard',
          name: 'fc-storing-agent-dashboard',
          component: () => import('@/views/fcReceivingAgent/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'fcStoringAgent'
          }
        },
        {
          path: 'fc-storing-agent/stock-requests',
          name: 'fc-storing-agent-fulfillment-stock-requests',
          component: () => import('@/views/fcOperationsSupervisor/StockRequests.vue'),
          meta: {
            authRequired: true,
            rule: 'fcStoringAgent'
          }
        },
        {
          path: 'fc-storing-agent/unidentified-stock-requests',
          name: 'fc-storing-agent-fulfillment-unidentified-stock-requests',
          component: () => import('@/views/merchant/fulfillment/UnidentifiedStockRequests.vue'),
          meta: {
            authRequired: true,
            rule: 'fcStoringAgent'
          }
        },
        {
          path: 'fc-storing-agent/stock-requests/:stockID',
          name: 'fc-storing-agent-stock-request-view',
          component: () => import('@/views/fcReceivingAgent/StockRequestView.vue'),
          meta: {
            authRequired: true,
            rule: 'fcStoringAgent',
            breadcrumb: [
              { title: 'Dashboard', url: 'fc-storing-agent/dashboard' },
              { title: 'Stock Requests', url:'fc-storing-agent/stock-requests'},
              { title: 'Stock Request View', active: true }
            ],
            pageTitle: 'Stock Request View'
          }
        },
        {
          path: 'fc-storing-agent/unidentified-stock-requests/:stockID',
          name: 'fc-storing-agent-unidentified-stock-request-view',
          component: () => import('@/views/fcReceivingAgent/UnidentifiedStockRequestView.vue'),
          meta: {
            authRequired: true,
            rule: 'fcStoringAgent',
            breadcrumb: [
              { title: 'Dashboard', url: 'fc-storing-agent/dashboard' },
              { title: 'UROs', url:'fc-storing-agent/unidentified-stock-requests'},
              { title: 'URO View', active: true }
            ],
            pageTitle: 'URO View'
          }
        },
        {
          path: 'fc-storing-agent/bins',
          name: 'fc-storing-agent-bins',
          component: () => import('@/views/fcOperationsSupervisor/Bins.vue'),
          meta: {
            authRequired: true,
            rule: 'fcStoringAgent'
          }
        },
        {
          path: 'fc-storing-agent/boxes',
          name: 'fc-storing-agent-boxes',
          component: () => import('@/views/fcOperationsSupervisor/Box.vue'),
          meta: {
            authRequired: true,
            rule: 'fcStoringAgent'
          }
        },
        {
          path: 'fc-storing-agent/totes',
          name: 'fc-storing-agent-totes',
          component: () => import('@/views/fcOperationsSupervisor/Tote.vue'),
          meta: {
            authRequired: true,
            rule: 'fcStoringAgent'
          }
        },
        {
          path: 'fc-storing-agent/pallets',
          name: 'fc-storing-agent-pallets',
          component: () => import('@/views/fcOperationsSupervisor/Pallet.vue'),
          meta: {
            authRequired: true,
            rule: 'fcStoringAgent'
          }
        },
        {
          path: 'fc-storing-agent/shelves',
          name: 'fc-storing-agent-shelves',
          component: () => import('@/views/fcOperationsSupervisor/Shelf.vue'),
          meta: {
            authRequired: true,
            rule: 'fcStoringAgent'
          }
        }
      ]
    },
    // =============================================================================
    // FC PACKING AGENT LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/fulfillment/FulfillmentMain.vue'),
      children: [
        {
          path: 'fc-packing-agent/notifications',
          name: 'fc-packing-agent-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'fcPackingAgent'
          }
        },
        {
          path: 'fc-packing-agent/account-settings',
          name: 'fc-packing-agent-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'fcPackingAgent'
          }
        },
        {
          path: 'fc-packing-agent/dashboard',
          name: 'fc-packing-agent-dashboard',
          component: () => import('@/views/fcPackingAgent/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'fcPackingAgent'
          }
        },
        {
          path: 'fc-packing-agent/picking-tasklists',
          name: 'fc-packing-agent-picking-tasklists',
          component: () => import('@/views/fcPickingAgent/PickingTaskList.vue'),
          meta: {
            authRequired: true,
            rule: 'fcPickingAgent'
          }
        },
        {
          path: 'fc-packing-agent/packing-tasklists',
          name: 'fc-packing-agent-packing-tasklists',
          component: () => import('@/views/fcPackingAgent/PackingTaskList.vue'),
          meta: {
            authRequired: true,
            rule: 'fcPackingAgent'
          }
        },
        {
          path: 'fc-packing-agent/picking-bins',
          name: 'fc-packing-agent-picking-bins',
          component: () => import('@/views/fcPickingAgent/PickingBins.vue'),
          meta: {
            authRequired: true,
            rule: 'fcPackingAgent'
          }
        }
      ]
    },
    // =============================================================================
    // FC PICKING AGENT LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/fulfillment/FulfillmentMain.vue'),
      children: [
        {
          path: 'fc-picking-agent/notifications',
          name: 'fc-picking-agent-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'fcPickingAgent'
          }
        },
        {
          path: 'fc-picking-agent/account-settings',
          name: 'fc-picking-agent-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'fcPickingAgent'
          }
        },
        {
          path: 'fc-picking-agent/dashboard',
          name: 'fc-picking-agent-dashboard',
          component: () => import('@/views/fcPickingAgent/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'fcPickingAgent'
          }
        },
        {
          path: 'fc-picking-agent/picking-bins',
          name: 'fc-picking-agent-picking-bins',
          component: () => import('@/views/fcPickingAgent/PickingBins.vue'),
          meta: {
            authRequired: true,
            rule: 'fcPickingAgent'
          }
        },
        {
          path: 'fc-picking-agent/picking-tasklists',
          name: 'fc-picking-agent-picking-tasklists',
          component: () => import('@/views/fcPickingAgent/PickingTaskList.vue'),
          meta: {
            authRequired: true,
            rule: 'fcPickingAgent'
          }
        }
      ]
    },
    // =============================================================================
    // OPS ADMIN LAYOUT ROUTES
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/admin/AdminMain.vue'),
      children: [
        {
          path: 'ops-admin/notifications',
          name: 'ops-admin-notifications',
          component: () => import('@/views/pages/Notifications.vue'),
          meta: {
            pageTitle: 'Notifications',
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/account-settings',
          name: 'ops-admin-profile',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'Account Settings',
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/dashboard',
          name: 'ops-admin-dashboard',
          component: () => import('@/views/ops-admin/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/management/holidays',
          name: 'ops-admin-holidays',
          component: () => import('@/views/admin/Holidays.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/fulfillment/fc-operations-supervisor',
          name: 'ops-admin-fc-operations-supervisor',
          component: () => import('@/views/admin/fulfillment/FcOperationsSupervisor.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/fulfillment/fc-receiving-agent',
          name: 'ops-admin-fc-receiving-agent',
          component: () => import('@/views/admin/fulfillment/FcReceivingAgent.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/fulfillment/fc-inbounding-agent',
          name: 'ops-admin-fc-inbounding-agent',
          component: () => import('@/views/admin/fulfillment/FcInboundingAgent.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/fulfillment/fc-storing-agent',
          name: 'ops-admin-fc-storing-agent',
          component: () => import('@/views/admin/fulfillment/FcStoringAgent.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/fulfillment/fc-packing-agent',
          name: 'ops-admin-fc-packing-agent',
          component: () => import('@/views/admin/fulfillment/FcPackingAgent.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/fulfillment/fc-picking-agent',
          name: 'ops-admin-fc-picking-agent',
          component: () => import('@/views/admin/fulfillment/FcPickingAgent.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/accounts/team-leaders',
          name: 'ops-admin-management-heads-of-fleet',
          component: () => import('@/views/admin/HeadsOfFleet.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/accounts/warehouse-agents',
          name: 'ops-admin-management-managers',
          component: () => import('@/views/admin/Managers.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/accounts/merchant-agents',
          name: 'ops-admin-merchant-agents',
          component: () => import('@/views/admin/MerchantAgents.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/accounts/customer-agents',
          name: 'ops-admin-customer-agents',
          component: () => import('@/views/admin/CustomerAgents.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        },
        {
          path: 'ops-admin/accounts/travellers',
          name: 'ops-admin-management-transporters',
          component: () => import('@/views/admin/Transporters.vue'),
          meta: {
            authRequired: true,
            rule: 'opsAdmin'
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '/:lang',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: 'auth-callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: 'pages/sign-up',
          name: 'page-sign-up',
          component: () => import('@/views/pages/signUp/SignUp.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: 'pages/terms-and-conditions',
          name: 'terms-and-conditions',
          component: () => import('@/views/pages/Terms.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'guest'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.params.warehouseID && localStorage.getItem('PrimaryWarehouse') !== null && to.params.warehouseID !== localStorage.getItem('PrimaryWarehouse')) {
    next({name: 'page-error-404'})
  }
  if (to.meta.authRequired) {
    if (!(auth.isAuthenticated())) {
      next({name: 'page-login'})
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
