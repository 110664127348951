// en, ar
export default {
  en: {
    'Shipment': 'Shipment',
    'Dashboard': 'Dashboard',
    'Operations': 'Operations',
    'Orders': 'Orders',
    'Support': 'Support',
    'Accounts': 'Accounts',
    'Account': 'Account',
    'Merchants': 'Merchants',
    'Merchant View': 'Merchant View',
    'Invoices': 'Invoices',
    'Statements': 'Statements',
    'Management': 'Management',
    'Warehouses': 'Warehouses',
    'Captains': 'Warehouse Agents',
    'Agents': 'Agents',
    'Transporters': 'Travellers',
    'Points': 'Points',
    'Shipments': 'Shipments',
    'Exchanges': 'Exchanges',
    'Supplies Requests': 'Supplies Requests',
    'Insights': 'Insights',
    'Supplies Inventory': 'Supplies Inventory',
    'Restock Requests': 'Restock Requests',
    'Settings': 'Settings',
    'Need Help?': 'Need Help?',
    'Pickup Points': 'Pickup Points',
    'Fulfillment': 'Fulfillment',
    'We are still working on adding this feature, check back soon!': 'We are still working on adding this feature, check back soon!',
    'We are working to bring you some great features here soon': 'We are working to bring you some great features here soon',
    'Name': 'Name',
    'Add Transporter': 'Add Traveller',
    'Add': 'Add',
    'Cancel': 'Cancel',
    'Tracking No.': 'Tracking No.',
    'Created On': 'Created On',
    'Merchant Name': 'Merchant Name',
    'Customer Name': 'Customer Name',
    'Customer Location': 'Customer Location',
    'Customer Phone': 'Customer Phone',
    'Status': 'Status',
    'Actions': 'Actions',
    'View': 'View',
    'Cash Deposit': 'Cash Deposit',
    'Cancel Shipment': 'Cancel Shipment',
    'Print Label': 'Print Label',
    'Download': 'Download',
    'Code': 'Code',
    'Size': 'Size',
    'Capacity': 'Capacity',
    'Linked Accounts': 'Linked Accounts',
    'Customer Info': 'Customer Info',
    'Location': 'Location',
    'Phone': 'Phone',
    'Packages': 'Packages',
    'CoD': 'CoD',
    'Cash on Delivery': 'Cash on Delivery',
    'View Return': 'View Return',
    'Edit Return': 'Edit Return',
    'Edit': 'Edit',
    'Request Pickup': 'Request Pickup',
    'Cancel Request': 'Cancel Request',
    'Store URL': 'Store URL',
    'Store Phone': 'Store Phone',
    'Store Email': 'Store Email',
    'First Name': 'First Name',
    'Pricing Table': 'Pricing Table',
    'Last Name': 'Last Name',
    'Delete': 'Delete',
    'Change Password': 'Change Password',
    'New Merchant Account': 'New Merchant Account',
    'Email': 'Email',
    'Phone Number': 'Phone Number',
    'Your customer phone number': 'Your customer phone number',
    'Add Merchant': 'Add Merchant',
    'Password': 'Password',
    'Confirm Password': 'Confirm Password',
    'New Transporter': 'New Traveller',
    'Change': 'Change',
    'Main Warehouse': 'Main Warehouse',
    'Secondary Warehouses': 'Secondary Warehouses',
    'New Agent': 'New Agent',
    'Task Sheet': 'Task Sheet',
    'Agent': 'Agent',
    'Captain': 'Warehouse Agent',
    'Merchant': 'Merchant',
    'New Captain': 'New Warehouse Agent',
    'Warehouse': 'Warehouse',
    'Upload photo': 'Upload photo',
    'Remove': 'Remove',
    'Save Changes': 'Save Changes',
    'Reset': 'Reset',
    'General': 'General',
    'Allowed JPG, GIF or PNG. Max size of 800kB': 'Allowed JPG, GIF or PNG. Max size of 800kB',
    'View Merchant': 'View Merchant',
    'Edit Merchant': 'Edit Merchant',
    'Merchant Information': 'Merchant Information',
    'Drop Image or': 'Drop Image or',
    'Browse': 'Browse',
    'Governorate': 'Governorate',
    'City': 'City',
    'Zone': 'Zone',
    'Zones': 'Zones',
    'Address 1': 'Building Number, Street Name',
    'Address 2': 'Apartment Number, Floor Number',
    'Address 3': 'Directions',
    'CoD Fees': 'CoD Fees',
    'Cash on Delivery Fees': 'Cash on Delivery Fees',
    'Cash On Delivery': 'Cash On Delivery',
    'Confirm': 'Confirm',
    'Zip Code': 'Zip Code',
    'New Item': 'New Item',
    'Quantity': 'Quantity',
    'Price': 'Price',
    'Add Supply Items': 'Add Supply Items',
    'Edit Supply Items': 'Edit Supply Items',
    'Preview': 'Preview',
    'Fulfillment Insights': 'Fulfillment Insights',
    'Help': 'Help',
    'To contact your ShipBlu team, you can always reach us at': 'To contact our team, you can always reach us at',
    'and your account manager will follow up with you shortly': 'and your account manager will follow up with you shortly',
    'New Shipment': 'New Delivery Order',
    'New Return': 'New Return Order',
    'New Exchange': 'New Exchange Order',
    'Exchange Order': 'Exchange Order',
    'Exchange': 'Exchange',
    'Exchange Packages': 'Exchange Packages',
    'Pickup Information': 'Pickup Information',
    'New Cash Collection': 'New Cash Collection Order',
    'Add Cash Collection': 'Add Cash Collection',
    'New Supply': 'New Supply',
    'Upload Multiple Orders': 'Upload Multiple Orders',
    'Overview': 'Overview',
    'Scheduled Pickups': 'Scheduled Pickups',
    'Scheduled Returns': 'Scheduled Returns',
    'Cash Balance': 'Cash Balance',
    'Order Type': 'Order Type',
    'Issue Type': 'Issue Type',
    'Return to Store': 'Return to Store',
    'Re-attempt Delivery': 'Re-attempt Delivery',
    'Add Shipment': 'Add Shipment',
    'Customer Information': 'Customer Information',
    'Customer Address 1': 'Customer Building Number, Street Name',
    'Customer Address 2': 'Customer Apartment Number, Land Mark',
    'Customer Address 3': 'Directions',
    'Package Size': 'Package Size',
    'Description': 'Description',
    'Shipment Details': 'Shipment Details',
    'Service Level': 'Service Level',
    'Delivery Fees': 'Delivery Fees',
    'Retrurn Fees': 'Retrurn Fees',
    'Cash Refund': 'Cash Refund',
    'Refund Fees': 'Refund Fees',
    'Amount (EGP)': 'Amount (EGP)',
    'Fragile/Hazardous': 'Fragile/Hazardous',
    'Fragile': 'Fragile',
    'Additional Information': 'Additional Information',
    'Merchant Order Reference No.': 'Merchant Order Reference No.',
    'Pickup Notes': 'Pickup Notes',
    'Your account is': 'Your account is',
    'On hold': 'On hold',
    'You only have view access. Contact with your account manager.': 'You only have view access. Contact with your account manager.',
    'Please, do not forget to add any applicable fees': 'Please, do not forget to add any applicable fees',
    '(return, refund) to return Amount!': '(return, refund) to return Amount!',
    '(delivery, CoD) to CoD Amount!': '(delivery, CoD) to CoD Amount!',
    'Add Return': 'Add Return',
    'Upload Shipments': 'Upload Shipments',
    'Download Template': 'Download Template',
    'Drop Excel File or': 'Drop Excel File or',
    'Pickup to Exchange': 'Pickup to Exchange',
    'Pickup to Return': 'Pickup to Return',
    'From Warehouse': 'From Warehouse',
    'To Warehouse': 'To Warehouse',
    'En Route': 'En Route',
    'Arrived': 'Arrived',
    'Receive': 'Receive',
    'Dispatch': 'Dispatch',
    'Scan Tracking Number': 'Scan Tracking Number',
    'Scan With Package': 'Scan With Package',
    'Collect Cash': 'Collect Cash',
    'Tracking Number': 'Tracking Number',
    'From': 'From',
    'To': 'To',
    'Pick Up': 'Pick Up',
    'Edit Shipment': 'Edit Shipment',
    'Order Information': 'Order Information',
    'Products declared value': 'Products Declared Value',
    'Insurance fees will be applied on this amount in case the COD amount = 0': 'ShipBlu Shield fees will be applied on this amount if provided.',
    'This amount will not be shown to your customer': 'This amount will not be shown to your customer',
    'Merchant Order': 'Merchant Order',
    'Reference No.': 'Reference No.',
    'Close': 'Close',
    'Store Name': 'Store Name',
    'Return Packages': 'Return Packages',
    'We have attempted delivery for 3 times but failed.': 'We have attempted delivery for 3 times but failed.',
    'Cash-only orders. No shipment to be delivered.': 'Cash-only orders. No packages to be delivered.',
    'Select any': 'Select any',
    'shipment to exchange. We will come pick up the new shipment first.': 'order to exchange. We will come pick up the new order first.',
    //Agent
    'Good Morning': 'Good Morning',
    'Loading today\'s task sheet': 'Loading today\'s task sheet',
    'Please Wait': 'Please Wait',
    'Countinue': 'Countinue',
    'Today': 'Today',
    'Supplies': 'Supplies',
    'Deliveries & Pickups': 'Deliveries & Pickups',
    'Deliveries': 'Deliveries',
    'Pickups': 'Pickups',
    'Collect': 'Collect',
    'Get Labels': 'Get Labels',
    'Cash Collection': 'Cash Collection',
    'Cash Collections': 'Cash Collections',
    'Cash Collected': 'Cash Collected',
    'Your Total Commission': 'Your Total Commission',
    'Get Ready': 'Get Ready',
    'EGP': 'EGP',
    'Address Information': 'Address Information',
    'Take Image': 'Take Image',
    'Getting Ready': 'Getting Ready',
    'Live Tracking Link': 'Live Tracking Link',
    'Start Google Maps': 'Start Google Maps',
    'Open Google Map': 'Open Google Map',
    'Checkout Packages': 'Checkout Packages',
    'Collect Supplies': 'Collect Supplies',
    'Take Cash': 'Take Cash',
    'Take': 'Take',
    'Cash': 'Cash',
    'Scan': 'Scan',
    'Go!': 'Go!',
    'Save': 'Save',
    'Complete checks above to start.': 'Complete checks above to start.',
    'You are ready to go!': 'You are ready to go!',
    'Stops': 'Stops',
    'Pickup': 'Pickup',
    'Delivery': 'Delivery',
    'Start Location': 'Start Location',
    'Navigate': 'Navigate',
    'Arrive': 'Arrive',
    'Next Stop': 'Next Stop',
    'Checkout at warehouse': 'Checkout at warehouse',
    'Address': 'Address',
    'Contact Person': 'Contact Person',
    'We might have been here before': 'We might have been here before',
    'Add Address Info': 'Add Address Info',
    'Delivery Notes': 'Delivery Notes',
    'Drop Supplies': 'Drop Supplies',
    'Service': 'Service',
    'Delivery Only': 'Delivery Only',
    'Confirm Pickups': 'Confirm Pickups',
    'Thanks For a successful Delivery!': 'Thanks For a successful Delivery!',
    'NEXT': 'NEXT',
    'Next': 'Next',
    'REPORT FAILURE': 'REPORT FAILURE',
    'Failed to Deliver': 'Failed to Deliver',
    'Scan For Successful Delivery': 'Scan For Successful Delivery',
    'Choose reason for failed delivery': 'Choose reason for failed delivery',
    'Have you notified your manager?': 'Have you notified your manager?',
    'Have you received approval to fail delivery from your manager?': 'Have you received approval to fail delivery from your manager?',
    'Customer\'s phone is turned off': 'Customer\'s phone is turned off',
    'Customer is not answering our calls': 'Customer is not answering our calls',
    'Wrong address provided': 'Wrong address provided',
    'Address not completed': 'Address not completed',
    'No one at home': 'No one at home',
    'Out Of Zone': 'Out Of Zone',
    'Customer Rescheduled': 'Customer Rescheduled',
    'Customer request to change address': 'Customer request to change address',
    'Customer refused to accept delivery': 'Customer refused to accept delivery',
    'Customer didn\'t have Money': 'Customer didn\'t have Money',
    'Customer evade to receive': 'Customer evade to receive',
    'Customer wants to open the package': 'Customer wants to open the package',
    'Yes': 'Yes',
    'NO': 'NO',
    'FLEET MANAGER': 'FLEET MANAGER',
    'FAILED DELIVERY': 'FAILED DELIVERY',
    'Checkout': 'Checkout',
    'Failed Drop Offs': 'Failed Drop Offs',
    'Drop Pickups': 'Drop Pickups',
    'Hand Over Cash': 'Hand Over Cash',
    'Hand Over': 'Hand Over',
    'Supplies Delivered': 'Supplies Delivered',
    'Pickups Picked Up': 'Pickups Picked Up',
    'Failed Deliveries': 'Failed Deliveries',
    'END DAY!': 'END DAY!',
    'Account Settings': 'Account Settings',
    'Logout': 'Logout',
    'PACKAGES DELIVERED': 'PACKAGES DELIVERED',
    'Great job! You made': 'Great job! You made',
    'Thank you': 'Thank you',
    'I Collected Cash': 'I Collected Cash',
    'Pickup & Return': 'Pickup & Return',
    'Return': 'Return',
    'Delivery & Return': 'Delivery & Return',
    'Returns': 'Returns',
    'Collect Returns': 'Collect Returns',
    'Returns Delivered': 'Returns Delivered',
    'Give Money': 'Give Money',
    //Client
    'ShipBlu Tracking System': 'ShipBlu Tracking System',
    'Welcome Dear Customer': 'Welcome Dear Customer',
    'Please input your shipment Tracking Number': 'Please input your order Tracking Number',
    'Track Order': 'Track Order',
    'Shipped On': 'Shipped On',
    'Recipient': 'Recipient',
    'Help us get to you faster!': 'Help us get to you faster!',
    'Select your delivery location on the map': 'Select your delivery location on the map',
    'Your package is scheduled to be delivered on': 'Your package is scheduled to be delivered on',
    'Want to change day? Select below': 'Want to change day? Select below',
    'Your selection': 'Your selection',
    'Select Preferred Delivery Window': 'Select Preferred Delivery Window',
    'You can choose more than one time': 'You can choose more than one time',
    'Delivery Preference': 'Delivery Preference',
    'Expected Delivery': 'Expected Delivery',
    'Success': 'Success',
    'User': 'User',
    'Failed': 'Failed',
    'Error. Please try again!': 'Error. Please try again!',
    'Head Of Fleet': 'Team Leader',
    'New Head Of Fleet': 'New Team Leader',
    'Heads Of Fleet': 'Team Leaders',
    'Customer Agent': 'Customer Agent',
    'New Customer Agent': 'New Customer Agent',
    'Customer Agents': 'Customer Agents',
    'Merchant Agent': 'Merchant Agent',
    'New Merchant Agent': 'New Merchant Agent',
    'Merchant Agents': 'Merchant Agents',
    'Username': 'Username',
    'Add Captain': 'Add Warehouse Agent',
    'Edit Captain': 'Edit Warehouse Agent',
    'Add Head Of Fleet': 'Add Team Leader',
    'Edit Head Of Fleet': 'Edit Team Leader',
    'Add Merchant Agent': 'Add Merchant Agent',
    'Edit Merchant Agent': 'Edit Merchant Agent',
    'Add Customer Agent': 'Add Customer Agent',
    'Edit Customer Agent': 'Edit Customer Agent',
    'National ID': 'National ID',
    'Not Found!': 'Not Found!',
    ' has been created successfully!': ' has been created successfully!',
    ' has been updated successfully!': ' has been updated successfully!',
    ' has been downloaded successfully!': ' has been downloaded successfully!',
    ' has been changed successfully!': ' has been changed successfully!',
    ' has been deleted successfully!': ' has been deleted successfully!',
    'Transporter': 'Traveller',
    'Data': 'Data',
    'Template': 'Template',
    'Amount': 'Amount',
    'Order Info': 'Order Info',
    'Notes': 'Notes',
    'New Supplies Request': 'New Supplies Order',
    'Order View': 'Order View',
    'Select packages to return': 'Select packages to return',
    'Package': 'Package',
    'Return Order': 'Return Order',
    'Delivery Order': 'Delivery Order',
    'Your account is not subscribed to our fulfillment service.': 'Your account is not subscribed to our fulfillment service.',
    'Wanna explore great savings and exceptional customer experience?': 'Wanna explore great savings and exceptional customer experience?',
    'Talk to us now!': 'Talk to us now!',
    'Alerts': 'Alerts',
    'Orders Feed': 'Orders Feed',
    'Edit Transporter': 'Edit Traveller',
    'Merchant Info': 'Merchant Info',
    'Pricing': 'Pricing',
    'Cash Processing': 'Cash Processing',
    'Accounting': 'Accounting',
    'New Merchant': 'New Merchant',
    'Merchant Pricing': 'Merchant Pricing',
    'Merchant Cash Processing Fees': 'Merchant Cash Processing Fees',
    'Cash processing fees': 'Cash processing fees',
    'Cash processing fees max': 'Cash processing fees max',
    'Cash processing percentage': 'Cash processing percentage',
    'Cash processing threshold': 'Cash processing threshold',
    'Pricing lookup': 'Pricing lookup',
    'Open in a new tab': 'Open in a new tab',
    //New
    'Capacity Utilization': 'Capacity Utilization',
    'All Orders': 'All Orders',
    'Paste link here': 'Paste link here',
    'Add Task List': 'Add Task List',
    'Downtown Cairo Warehouse': 'Downtown Cairo Warehouse',
    'New Cairo Warehouse': 'New Cairo Warehouse',
    'Alexandria Warehouse': 'Alexandria Warehouse',
    'October Warehouse': 'October Warehouse',
    'Mansoura Warehouse': 'Mansoura Warehouse',
    'Tanta Warehouse': 'Tanta Warehouse',
    'Monoufia Warehouse': 'Monoufia Warehouse',
    'Task List': 'Task List',
    'Task Lists': 'Task Lists',
    'Last Mile Agents': 'Last Mile Agents',
    'First Mile Agents': 'First Mile Agents',
    'Created By': 'Created By',
    'Assigned To': 'Assigned To',
    'New Task List': 'New Task List',
    'Delivery Orders': 'Delivery Orders',
    'delivery orders': 'delivery orders',
    'Agent Type': 'Agent Type',
    'Abort Task List': 'Abort Task List',
    'Task List View': 'Task List View',
    'ID': 'ID',
    'Tracking Portal': 'Tracking Portal',
    'Delivery Window': 'Delivery Window',
    'Call': 'Call',
    'Below are orders with missing customer inputs!': 'Below are orders with missing customer inputs!',
    'Invoice Number': 'Invoice Number',
    'Issue Date': 'Issue Date',
    'Due Date': 'Due Date',
    'Total Amout': 'Total Amout',
    'Link to PDF': 'Link to PDF',
    'Subscription': 'Subscription',
    'Pickup Point': 'Pickup Point',
    'DropOff Point': 'DropOff Point',
    'DropOff Counter': 'DropOff Counter',
    'Merchant Pickup Points': 'Merchant Pickup Points',
    'New Pickup Point': 'New Pickup Point',
    'Add Pickup Point': 'Add Pickup Point',
    'PUDOs': 'PUDOs',
    'PUDO': 'PUDO',
    'New PUDO': 'New PUDO',
    'Add PUDO': 'Add PUDO',
    'Display Name': 'Display Name',
    'Google Maps Link': 'Google Maps Link',
    'In-house Orders': 'In-house Orders',
    'Task Sheets': 'Task Sheets',
    'Merchant API Keys': 'Merchant API Keys',
    'New API Key': 'New API Key',
    'API Key': 'API Key',
    'Renew': 'Renew',
    'How will we get your package?': 'How will we get your package?',
    'Warehouse View': 'Warehouse View',
    'Hi': 'Hi',
    'I\'m at': 'I\'m at',
    'Drop': 'Drop',
    'Going to': 'Going to',
    'Pick': 'Pick',
    'Drop Off': 'Drop Off',
    'Drop Offs': 'Drop Offs',
    'Confirm Loading': 'Confirm Loading',
    'Package to': 'Package to',
    'Exit': 'Exit',
    'You don\'t have assigned task lists!': 'You don\'t have assigned task lists!',
    'New Delivery Order': 'New Delivery Order',
    'Zone Groups': 'Zone Groups',
    'Zone Group': 'Zone Group',
    'New Zone Group': 'New Zone Group',
    'Edit Zone Group': 'Edit Zone Group',
    'Pricing Packages': 'Pricing Packages',
    'Scan with barcode or click camera': 'Scan with barcode or click camera',
    'Submit': 'Submit',
    'Warning': 'Warning',
    'You can take a photo with mobile phones only!': 'You can take a photo with mobile phones only!',
    'Please, complete drop off or pickup!': 'Please, complete drop off or pickup!',
    'Confirm Pickups CheckIn': 'Confirm Pickups CheckIn',
    'Khazna': 'Khazna',
    'Khazna Transactions': 'Khazna Transactions',
    'New Khazna Transaction': 'New Khazna Transaction',
    'Transaction Type': 'Transaction Type',
    'Issued By': 'Issued By',
    'Reference Number': 'Reference Number',
    'Add Khazna Transaction': 'Add Khazna Transaction',
    'Edit Khazna Transaction': 'Edit Khazna Transaction',
    'Deposit at Warehouse': 'Deposit at Warehouse',
    'Withdraw': 'Withdraw from Warehouse',
    'Transaction Created On': 'Completed On',
    'withdraw': 'withdraw from warehouse',
    'Confirm Cash Collected': 'Confirm Cash Collected',
    'Cash received at warehouse successfully!': 'Cash received at warehouse successfully!',
    'Customer Support': 'Client Services',
    'Billing Days': 'Billing Days',
    'Missing Input': 'Missing Input',
    'Estimated Delivery Date': 'Estimated Delivery Date',
    'Current': 'Current',
    'All': 'All',
    'Completed': 'Completed',
    'Agent Name': 'Agent Name',
    'Last Mile Agent': 'Last Mile Agent',
    'First Mile Agent': 'First Mile Agent',
    'Number Of Stops': 'Number Of Stops',
    'Number Of Packages': 'Number Of Packages',
    'Requests': 'Requests',
    'Delivered On': 'Delivered On',
    'Returned On': 'Returned On',
    'Statement Number': 'Statement Number',
    'New PDF has been generated successfully. Click download!': 'New PDF has been generated successfully. Click download!',
    'Generate PDF': 'Generate PDF',
    'Discounts': 'Discounts',
    'Net Service Fees Due': 'Net Service Fees Due',
    'Net COD Due': 'Net COD Due',
    'Accounting Status': 'Accounting Status',
    'Expected Deposit Date': 'Expected Deposit Date',
    'Statement PDF': 'Statement PDF',
    'Bank Deposits': 'Bank Deposits',
    'Bank Deposit': 'Bank Deposit',
    'Bank Reference Number': 'Bank Reference Number',
    'Deposit Date': 'Deposit Date',
    'Subtotal': 'Subtotal',
    'VAT': 'VAT',
    'Total': 'Total',
    'Link to Order': 'Link to Order',
    'Download Invoice': 'Download Invoice',
    'Merchant Invoices': 'Merchant Invoices',
    'Deposited On': 'Deposited On',
    'Deposit Slip': 'Deposit Slip',
    'Statement': 'Statement',
    'New Bank Deposit': 'New Bank Deposit',
    'Merchant Bank Deposits': 'Merchant Bank Deposits',
    'Merchant Statements': 'Merchant Statements',
    'PACKAGES DELIVERED & CASH COLLECTED': 'PACKAGES DELIVERED & CASH COLLECTED',
    'This Order is part of an exchange order . Please, Check the price on the exchange order.': 'This Order is part of an exchange order . Please, Check the price on the exchange order.',
    'Delivery failed. Thanks for trying.': 'Delivery failed. Thanks for trying.',
    'Delivery Time': 'Delivery Time',
    'Tracking': 'Tracking',
    'Not found!': 'Not found!',
    'Picked up On': 'Picked Up On',
    'No. Packages': 'No. Packages',
    'From here you can see your cash balance': 'This shows how much cash you have with us.',
    'From here you can see the total scheduled pickups': 'This is the number of packages our agent expects to pick up from you next.',
    'From here you can see the total scheduled returns': 'This is the number of packages we will return from your customers.',
    'From here you can see the total alerts on orders being delivered': 'We also show you some alerts for orders that need your attention.',
    'From here you can add new delivery order': 'You can create a new delivery order.',
    'Done!': 'Done!',
    'Back': 'Back',
    'From here you can': 'Here, you can',
    '1- Add new delivery order': '1- Create a new delivery order',
    '2- Add new return': '2- Request a return from a customer',
    '3- Add new exchange': '3- Request to exchange an order that we already delivered',
    '4- Add new cash collection': '4- Create a new cash collection request',
    '5- Add new supplies request': '5- Request supplies',
    '6- Upload a large number of shipments using excel sheet': '6- Batch-upload delivery orders from an excel sheet',
    'From here you can see all ': 'Here, you can see all ',
    'You can select status then will appear all delivery orders belongs to this status': 'You can filter orders by their status',
    'Date of adding order on ShipBlu': 'Date of order creation',
    'Customer\'s Name': 'Customer\'s Name',
    'Customer\'s Address': 'Customer\'s Address',
    'Customer\'s Phone': 'Customer\'s Phone',
    'Number of pieces or packages sent to the customer': 'Number of packages in the order',
    'Total amount to be collected': 'Total cash amount to be collected',
    'Date of receiving order': 'The date we picked up the order from you.',
    'Order status': 'Order status',
    'Order arrival time': 'The date we delivered the order to the customer',
    'Options to view all shipment data': 'Actions on orders (e.g. cancel an order)',
    'Pickup Date': 'Pickup Date',
    'Storage section is currently under development and will be available soon': 'If you are subscribed to our fulfillment solution, you can manage your stock from this section.',
    'Scan with barcode or click camera return orders': 'Scan with barcode or click camera to return orders',
    'Confirm DropOffs': 'Confirm DropOffs',
    'Take a Tour': 'Take a Tour',
    'Please, refresh the page to start a tour': 'Please, refresh the page to start a tour',
    'Governorates Deliveries': 'Governorates Deliveries',
    'Orders Awaiting Action': 'Orders Awaiting Action',
    'Move order to another task sheet': 'Move order to another task sheet',
    'Remove order from task sheet': 'Remove order from task sheet',
    'Add Orders': 'Add Orders',
    'Complete Task List': 'Complete Task List',
    ' has been added to task sheet successfully!': ' has been added to task sheet successfully!',
    'Summary': 'Summary',
    'Governorates': 'Governorates',
    'Are you sure you want to ': 'Are you sure you want to ',
    'Are you sure you have returned this order to the merchant?': 'Are you sure you have returned this order to the merchant?',
    'cancel this order?': 'cancel this order?',
    'Returned to Origin': 'Returned to Origin',
    'Return to Origin': 'Return to Origin',
    'return this order to origin?': 'return this order to origin?',
    'Delivered': 'Delivered',
    'Returned': 'Returned',
    'Your mobile browser does not support location services!': 'Your mobile browser does not support location services!',
    'Please, allow location services on your phone!': 'Please, allow location services on your phone!',
    'Order': 'Order',
    'Add Tracking Event': 'Add Tracking Event',
    'Picked-up Orders': 'Picked-up Orders',
    'Today Refunds': 'Today Refunds',
    'Today’s CoD': 'Today’s CoD',
    'In-house Cash': 'In-house Cash',
    'Today’s Packages': 'Today’s Packages',
    'Today’s Pickups': 'Today’s Pickups',
    'Today’s Exchanges': 'Today’s Exchanges',
    'Today’s Returns': 'Today’s Returns',
    'Today’s Deliveries': 'Today’s Deliveries',
    'Deliveries Today': 'Deliveries Today',
    'Pickups Today': 'Pickups Today',
    'Failure Reasons': 'Failure Reasons',
    'Request Return': 'Request Return',
    'Customer Address': 'Customer Address',
    'Slot Location': 'Slot Location',
    'Scheduled Delivery Date': 'Scheduled Delivery Date',
    'Transaction': 'Transaction',
    'Review Required': 'Review Required',
    'Needs Review': 'Needs Review',
    'Invoice isn\'t ready': 'Invoice isn\'t ready',
    'Packages Base Price': 'Packages Base Price',
    'VAT (14%)': 'VAT (14%)',
    'Postal Taxes (10%)': 'Postal Taxes (10%)',
    'Total Price': 'Total Price',
    'Estimated Price': 'Estimated Price',
    'Destination Governorate': 'Destination Governorate',
    'Handshake Type': 'Handshake Type',
    'Value (EGP)': 'Value (EGP)',
    'Preferred Delivery Date': 'Preferred Delivery Date',
    'All prices include taxes (VAT and Postal).': 'All prices include taxes (VAT and Postal).',
    'No Slip Available': 'No Slip Available',
    'Add Delivery Order': 'Add Delivery Order',
    'CASH COLLECTED': 'CASH COLLECTED',
    'Thanks For a successful Cash Collection!': 'Thanks For a successful Cash Collection!',
    'Cash Collection failed. Thanks for trying.': 'Cash Collection failed. Thanks for trying.',
    'Collect Request': 'Collect Request',
    'We can\'t scan orders in terminal states!': 'We can\'t scan orders in terminal states!',
    'Time': 'Time',
    'Counter DropOff': 'Counter DropOff',
    'Tracking Event': 'Tracking Event',
    'القاهرة - Cairo': 'Cairo',
    'Cairo - القاهرة': 'Cairo',
    'الاسكندرية - Alexandria': 'Alexandria',
    'Alexandria - الاسكندرية': 'Alexandria',
    'الساحل الشمالي - North Coast': 'North Coast',
    'Al-Dakahlia - الدقهلية': 'Al-Dakahlia',
    'الدقهلية  - Al-Dakahlia': 'Al-Dakahlia',
    'Qalyubia - القليوبية': 'Qalyubia',
    'القليوبية - Qalyubia': 'Qalyubia',
    'المنوفية - Monoufia': 'Monoufia',
    'Monoufia - المنوفية': 'Monoufia',
    'El Gharbia - الغربية': 'El Gharbia',
    'البحيرة - Al-Beheira': 'Al-Beheira',
    'Al-Beheira - البحيرة': 'Al-Beheira',
    'Kafr El Sheikh - كفر الشيخ': 'Kafr El Sheikh',
    'اسيوط -Assiut': 'Assiut',
    'الفيوم - Fayoum': 'Fayoum',
    'بني سويف - Beni Suef': 'Beni Suef',
    'الاسماعيلية - Ismailiya': 'Ismailiya',
    'السويس - Suez': 'Suez',
    'البحر الأحمر- Al-Bahr Al-Ahmar': 'Al-Bahr Al-Ahmar',
    'القاهرة الجديدة - New Cairo': 'New Cairo',
    'وسط البلد - Downtown Cairo': 'Downtown Cairo',
    'اكتوبر - October': 'October',
    'وسط البلد والجمرك - Downtown Alex & Gomrok': 'Downtown Alex & Gomrok',
    'Agamy & El-Amreya - العجمي والعامرية': 'Agamy & El-Amreya',
    'شرق الاسكندرية والمنتزة - East Alex & Montazah': 'East Alex & Montazah',
    'الساحل - Sahel': 'Sahel',
    'المنصورة - Mansoura': 'Mansoura',
    'بنها - Banha': 'Banha',
    'Shebeen Al Kom - شبين الكوم': 'Shebeen Al Kom',
    'Mahala El Kobra - المحلة الكبري': 'Mahala El Kobra',
    'طنطا - Tanta': 'Tanta',
    'دمنهور - Damanhour': 'Damanhour',
    'اسيوط - Assiut': 'Assiut',
    'الغردقة - Hurghada': 'Hurghada',
    'El Gouna - الجونة': 'El Gouna',
    'التجمع الثالث - 3rd Settlement': '3rd Settlement',
    'التجمع الخامس - 5th Settlement': '5th Settlement',
    '5th Settlement - District No 1': '5th Settlement - District No 1',
    '5th Settlement - District No 2': '5th Settlement - District No 2',
    '5th Settlement - District No 3': '5th Settlement - District No 3',
    '5th Settlement - District No 4': '5th Settlement - District No 4',
    '5th Settlement - District No 5': '5th Settlement - District No 5',
    'الشويفات - El-Shouyfat': 'El-Shouyfat',
    'الدبلوماسيين - El-Diplomasyeen': 'El-Diplomasyeen',
    'القطامية - El-Kattameya': 'El-Kattameya',
    'ارابيلا - Arabella': 'Arabella',
    'غرب الجولف - Gharb El Golf': 'Gharb El Golf',
    'Al Rehab - الرحاب': 'Al Rehab',
    'الياسمين 1 - El-Yasmeen 1': 'El-Yasmeen 1',
    'الياسمين 2 - El-Yasmeen 2': 'El-Yasmeen 2',
    'الياسمين 3 - El-Yasmeen 3': 'El-Yasmeen 3',
    'الياسمين 4 - El-Yasmeen 4': 'El-Yasmeen 4',
    'الياسمين 5 - El-Yasmeen 5': 'El-Yasmeen 5',
    'الياسمين 6 - El-Yasmeen 6': 'El-Yasmeen 6',
    'الياسمين 7 - El-Yasmeen 7': 'El-Yasmeen 7',
    'الياسمين 8 - El-Yasmeen 8': 'El-Yasmeen 8',
    'البنفسج 1 - El-Banafseg 1': 'El-Banafseg 1',
    'البنفسج 2 - El-Banafseg 2': 'El-Banafseg 2',
    'البنفسج 3 - El-Banafseg 3': 'El-Banafseg 3',
    'البنفسج 4 - El-Banafseg 4': 'El-Banafseg 4',
    'البنفسج 5 - El-Banafseg 5': 'El-Banafseg 5',
    'البنفسج 6 - El-Banafseg 6': 'El-Banafseg 6',
    'البنفسج 7 - El-Banafseg 7': 'El-Banafseg 7',
    'البنفسج 8 - El-Banafseg 8': 'El-Banafseg 8',
    'البنفسج 9 - El-Banafseg 9': 'El-Banafseg 9',
    'البنفسج 10 - El-Banafseg 10': 'El-Banafseg 10',
    'البنفسج 11 - El-Banafseg 11': 'El-Banafseg 11',
    'البنفسج 12 - El-Banafseg 12': 'El-Banafseg 12',
    'التجمع الأول - 1st Settlement': '1st Settlement',
    '1st Settlement - North Youth Housing': '1st Settlement - North Youth Housing',
    '2nd Settlement - South Youth Housing': '2nd Settlement - South Youth Housing',
    'كايرو فستيفال سيتي - Cairo Festival City': 'Cairo Festival City',
    'شرق الأكاديمية - Sharq El Academia': 'Sharq El Academia',
    'جنوب الاكاديمية - Ganoob Al Academia - Area A': 'Ganoob Al Academia - Area A',
    'جنوب الاكاديمية - Ganoob Al Academia - Area B': 'Ganoob Al Academia - Area B',
    'جنوب الاكاديمية - Ganoob Al Academia - Area C': 'Ganoob Al Academia - Area C',
    'جنوب الاكاديمية - Ganoob Al Academia - Area D': 'Ganoob Al Academia - Area D',
    'جنوب الاكاديمية - Ganoob Al Academia - Area\u00a0E': 'Ganoob Al Academia - Area\u00a0E',
    'جنوب الاكاديمية - Ganoob Al Academia - Area F': 'Ganoob Al Academia - Area F',
    'منطقة المستثمرين الجنوبية - South Investors Area': 'South Investors Area',
    'منطقة المسثمرين الشمالية - North Investors Area': 'North Investors Area',
    'الأندلوس - El Andalous': 'El Andalous',
    'اللوتس - El-Lotus': 'El-Lotus',
    'النرجس 1 - El-Narges 1': 'El-Lotus 1',
    'النرجس 2 - El-Narges 2': 'El-Lotus 2',
    'النرجس 3 - El-Narges 3': 'El-Lotus 3',
    'النرجس 4 - El-Narges 4': 'El-Lotus 4',
    'النرجس 5 - El-Narges 5': 'El-Lotus 5',
    'النرجس 6 - El-Narges 6': 'El-Lotus 6',
    'النرجس 7 - El-Narges 7': 'El-Lotus 7',
    'النرجس 8 - El-Narges 8': 'El-Lotus 8',
    'المنطقة الأولي - Area 1': 'Area 1',
    'المنطقة الثالثة - Area 3': 'Area 3',
    'أبو الهول - Abo El Houl': 'Abo El Houl',
    'العاشر من رمضان - 10th of Ramadan': '10th of Ramadan',
    'El Shorouk - الشروق': 'El Shorouk',
    'مدينة المستقبل - Future City': 'Future City',
    'مدينتي - Madinaty': 'Madinaty',
    'العبور - Obour': 'Obour',
    'مدينة الطلائع - El Talae3 City': 'El Talae3 City',
    'هيلوبوليس الجديدة - New Heliopolis City': 'New Heliopolis City',
    'مدينة بدر - Badr City': 'Badr City',
    'جمعية أحمد عرابي - Ahmed Oraby Association': 'Ahmed Oraby Association',
    'Al Manial - المنيل': 'Al Manial',
    'جاردن سيتي - Garden City': 'Garden City',
    'الزمالك - Zamalek': 'Zamalek',
    'Masr El Kadema - مصر القديمة': 'Masr El Kadema',
    'عابدين - Abdeen': 'Abdeen',
    'بولاق - Bulaq': 'Bulaq',
    'العجوزة - Agouza': 'Agouza',
    'الدقي - Dokki': 'Dokki',
    'الجيزة - Giza': 'Giza',
    'Giza - الجيزة': 'Giza',
    'North Coast - الساحل الشمالي': 'North Coast',
    'Damanhour - دمنهور': 'Damanhour',
    'المهندسين - Mohandeseen': 'Mohandeseen',
    'ميت عقبة - Mit Akaba': 'Mit Akaba',
    'Ard El Lewa - ارض اللوا': 'Ard El Lewa',
    'فيصل - Faisal': 'Faisal',
    'الهرم - Haram': 'Haram',
    'حدائق الأهرام - Hadayek El Haram': 'Hadayek El Haram',
    'امبابة - Imbaba': 'Imbaba',
    'El Matareya - المطرية': 'El Matareya',
    'El Qobbah - القبة': 'El Qobbah',
    'Al Marj - المرج': 'Al Marj',
    'Road El Farag - روض الفرج': 'Road El Farag',
    'العباسية - Abbassia': 'Abbassia',
    'Al Wayli - الوايلي': 'Al Wayli',
    'Al Daher': 'Al Daher',
    'شبرا - Shubra': 'Shubra',
    'New Cities - المدن الجديدة': 'New Cities',
    '6 October - السادس من أكتوبر': '6 October',
    'بلطيم - Balteem': 'Balteem',
    'Madinet Al-Salam - مدينة السلام': 'Madinet Al-Salam',
    'Shubra El Kheima - شبرا الخيمة': 'Shubra El Kheima',
    'Al Muqattam - المقطم': 'Al Muqattam',
    'الهضبة الوسطي - El-Hadaba El-Wosta': 'El-Hadaba El-Wosta',
    'حلوان - Helwan': 'Helwan',
    'المعادي - Maadi': 'Maadi',
    'شمال القاهرة - Uptown Cairo': 'Uptown Cairo',
    'الزيتون - El-Zaytoun': 'El-Zaytoun',
    'جسر السويس - Gesr elsuez': 'Gesr elsuez',
    'عين شمس - Ain Shams': 'Ain Shams',
    'مدينة نصر - Nasr City': 'Nasr City',
    'شيراتون - Sheraton': 'Sheraton',
    'مصر الجديدة - Heliopolis': 'Heliopolis',
    'Abu Rawash - ابورواش': 'Abu Rawash',
    'Alex Desert Road (Before Toll Station)': 'Alex Desert Road (Before Toll Station)',
    'Alex Desert Road (After Toll Station)': 'Alex Desert Road (After Toll Station)',
    'New Giza': 'New Giza',
    'القرية الذكية - Smart Village': 'Smart Village',
    'Pyramids Heights': 'Pyramids Heights',
    'Garana Residence': 'Garana Residence',
    'Azizia Villas': 'Azizia Villas',
    'Katr El Nada': 'Katr El Nada',
    'دريم لاند - Dream Land': 'Dream Land',
    'حدايق الاهرام - Pyramids Gardens': 'Pyramids Gardens',
    '6th of October City - اكتوبر': '6th October City',
    '6th of October City - District 1': '6th of October City - District 1',
    '6th of October City - District 2': '6th of October City - District 2',
    '6th of October City - District 3': '6th of October City - District 3',
    '6th of October City - District 4': '6th of October City - District 4',
    '6th of October City - District 5': '6th of October City - District 5',
    '6th of October City - District 6': '6th of October City - District 6',
    '6th of October City - Al Bashayer District': '6th of October City - Al Bashayer District ',
    '6th of October City - District 8': '6th of October City - District 8',
    '6th of October City - District 10': '6th of October City - District 10',
    '6th of October City - District 11': '6th of October City - District 11',
    '6th of October City - District 12': '6th of October City - District 12',
    '6th of October City - Youth Residence': '6th of October City - Youth Residence',
    '6th of October City - Al Motameyz District': '6th of October City - Al Motameyz District',
    '6th of October City - West Somid': '6th of October City - West Somid',
    'Sheikh Zayed City - الشيخ زايد': 'Sheikh Zayed City ',
    'Sheikh Zayed City - El Rabwa Compound': 'Sheikh Zayed City - El Rabwa Compound ',
    'Sheikh Zayed City - Westown Residence': 'Sheikh Zayed City - Westown Residence',
    'Sheikh Zayed City - Sodic Allegria': 'Sheikh Zayed City - Sodic Allegria',
    'Sheikh Zayed City - Casa Compound': 'Sheikh Zayed City - Casa Compound',
    'Sheikh Zayed City - Beverly Hills Zayed': 'Sheikh Zayed City - Beverly Hills Zayed',
    'Sheikh Zayed City - District 1': 'Sheikh Zayed City - District 1',
    'Sheikh Zayed City - District 2': 'Sheikh Zayed City - District 2',
    'Sheikh Zayed City - District 3': 'Sheikh Zayed City - District 3',
    'Sheikh Zayed City - District 4': 'Sheikh Zayed City - District 4',
    'Sheikh Zayed City - District 5': 'Sheikh Zayed City - District 5',
    'Sheikh Zayed City - District 7': 'Sheikh Zayed City - District 7',
    'Sheikh Zayed City - District 8': 'Sheikh Zayed City - District 8',
    'Sheikh Zayed City - District 9': 'Sheikh Zayed City - District 9',
    'Sheikh Zayed City - District 10': 'Sheikh Zayed City - District 10',
    'Sheikh Zayed City - District 11': 'Sheikh Zayed City - District 11',
    'Sheikh Zayed City - District 12': 'Sheikh Zayed City - District 12',
    'Sheikh Zayed City - District 14': 'Sheikh Zayed City - District 14',
    'Sheikh Zayed City - District 16': 'Sheikh Zayed City - District 16',
    'El Hadara - الحضرة': 'El Hadara',
    'El Labban - اللبان': 'El Labban',
    'El Shatby - الشاطبي': 'El Shatby',
    'كرموز - Karmoz': 'Karmoz',
    'Kom El Deka - كوم الدقة': 'Kom El Deka',
    'Mahatet El Raml - محطة الرمل': 'Mahatet El Raml',
    'محرم بيك - Moharam Bek': 'Moharam Bek',
    'وسط البلد - Downtown Alexandria': 'Downtown Alexandria',
    'El Atareen - العطارين': 'El Atareen',
    'El Azarita - الازاريطة': 'El Azarita',
    'El Ibrahimiya - الابراهيمية': 'El Ibrahimiya',
    'بحري - Bahary': 'Bahary',
    'El Anfoushi - الانفوشي': 'El Anfoushi',
    'El Gomrok - الجمرك': 'El Gomrok',
    'El Mansheya - المنشية': 'El Mansheya',
    'El Qabary - القباري': 'El Qabary',
    'El Wardeyan - الورديان': 'El Wardeyan',
    'العجمي - Agamy': 'Agamy',
    'أبو يوسف - Abou Youssef': 'Abou Youssef',
    'العامرية - El-Amreya': 'El-Amreya',
    'البيطاش - El-Bitash': 'El-Bitash',
    'الديخيلة - El-Dekhela': 'El-Dekhela',
    'الهانوفيل - El-Hanoveel': 'El-Hanoveel',
    'المكس - El-Max': 'El-Max',
    'أكتوبر - October': 'October',
    'باكوس - Bakos': 'Bakos',
    'بولكلي - Bolkly': 'Bolkly',
    'كامب شيزار - Camp Chezar': 'Camp Chezar',
    'كليوباترا - Cleopatra': 'Cleopatra',
    'El Saraya - السراية': 'El Saraya',
    'فليمنج - Fleming': 'Fleming',
    'جناكلس - Gianaclis': 'Gianaclis',
    'جليم - Glim': 'Glim',
    'كقر عبده - Kafr Abdu': 'Kafr Abdu',
    'لوران - Louran': 'Louran',
    'رشدي - Roshdy': 'Roshdy',
    'سابا باشا - Saba Basha': 'Saba Basha',
    'سان ستيفانو - San Stefano': 'San Stefano',
    'سيدي جابر - Sidi Gaber': 'Sidi Gaber',
    'سموحة - Smouha': 'Smouha',
    'سبورتنج - Sporting': 'Sporting',
    'ستانلي - Stanley': 'Stanley',
    'ثروت - Tharwat': 'Tharwat',
    'زيزينيا - Zezenia': 'Zezenia',
    'Abu Kir - ابوقير': 'Abu Kir',
    'El Asafra - العصافرة': 'El Asafra',
    'El Maamora - المعمورة': 'El Maamora',
    'El Mandara - المندرة': 'El Mandara',
    'El Montaza - المنتزة': 'El Montaza',
    'El Soyof - السيوف': 'El Soyof',
    'ميامي - Miami': 'Miami',
    'سيدي بشر - Sidi Bishr': 'Sidi Bishr',
    'فكتوريا - Victoria': 'Victoria',
    'عزبة الشال': 'عزبة الشال',
    'مدينة مبارك': 'مدينة مبارك',
    'سندوب': 'سندوب',
    'مدينة الفردوس': 'مدينة الفردوس',
    'مدينة السلام': 'مدينة السلام',
    'حي الجامعة': 'حي الجامعة',
    'دسوق - Desouq': 'Desouq',
    'الرياض - El-Reyad': 'El-Reyad',
    'فوه - Fuwwah': 'Fuwwah',
    'حمول - Hamool': 'Hamool',
    'كفر الشيخ - Kafr Alsheikh': 'Kafr Alsheikh',
    'مطوبس - Mutubas': 'Mutubas',
    'قلين - Qeleen': 'Qeleen',
    'سيدي سالم - Sidi Salem': 'Sidi Salem',
    'ابنوب - Abanoub': 'Abanoub',
    'Abo Teg - ابوتيج': 'Abo Teg',
    'ديروط - Dayrout': 'Dayrout',
    'El Badary - البداري': 'El Badary',
    'El Fath - الفتح': 'El Fath ',
    'El Ghanaym - الغنايم': 'El Ghanaym ',
    'El Qawsaia - القوصية': 'El Qawsaia',
    'منفلوط - Manfalout': 'Manfalout',
    'اسيوط الجديدة - New Assuit': 'New Assuit',
    'صدفا - Sadfa': 'Sadfa',
    'ساحل سليم - Sahel Selim': 'Sahel Selim',
    'اطسا - Atsa': 'Atsa',
    'ابشواي - Ebsheway': 'Ebsheway',
    'طمية - Tamya': 'Tamya',
    'يوسف الصديق - Yousef Elsedeq': 'Yousef Elsedeq',
    'ببا - Biba': 'Biba',
    'الفشن - Elfashn': 'Elfashn',
    'الواسطى - Elwasty': 'Elwasty',
    'إهناسيا - Ihnasya': 'Ihnasya',
    'ناصر - Nasser': 'Nasser',
    'بني سويف الجديدة - New Beni Suef': 'New Beni Suef',
    'سمسطا - Samsta': 'Samsta',
    'أبو عطوة - Abu Atwa': 'Abu Atwa',
    'أبو خليفة - Abu Khalifah': 'Abu Khalifah',
    'أبو صوير - Abu Soweir': 'Abu Soweir',
    'أبو سلطان - Abu sultan': 'Abu sultan',
    'التل الكبير - Al Tall Al kabir': 'Al Tall Al kabir',
    'القصاصين - El Kasasin': 'El Kasasin',
    'الصالحية الجديدة - El Saleheya Al gadida': 'El Saleheya Al gadida',
    'فايد - Fayed': 'Fayed',
    'الحي الأول - Hay 1': 'Hay 1',
    'الحي الثاني - Hay 2': 'Hay 2',
    'الحي الثالث - Hay 3': 'Hay 3',
    'الكيلو 11 - Kilo 11': 'Kilo 11',
    'قنطرة غرب - Qantara Gharab': 'Qantara Gharab',
    'قنطرة شرق - Qantara Shark': 'Qantara Shark',
    'سرابيوم - Sarabium': 'Sarabium',
    'أبو رديس - Abu Rudeis': 'Abu Rudeis',
    'أبو زنيمة - Abu Zenima': 'Abu Zenima',
    'منطقة السويس - Al Suez District': 'Al Suez District',
    'العدبية - Al-Adabya': 'Al-Adabya',
    'عتاقة - Ataka District': 'Ataka District',
    'السلام - El Salam District': 'El Salam District',
    'الأربعين - El-Arbeen District': 'El-Arbeen District',
    'الجناين - Elganaien District': 'Elganaien District',
    'فيصل - Faisal District': 'Faisal District',
    'El-Hawamdeya - الحوامدية': 'El-Hawamdeya',
    'ElBadrasheen - البدرشين': 'ElBadrasheen ',
    'الحوامدية - El-Hawamdeya': 'El-Hawamdeya',
    'البدرشين - ElBadrasheen': 'ElBadrasheen',
    'New Accounting Account': 'New Accounting Account',
    'Add Accounting': 'Add Accounting',
    'Edit Accounting': 'Edit Accounting',
    'Send': 'Send',
    'Export': 'Export',
    'Customer Email': 'Customer Email',
    'Customer Governorate': 'Customer Governorate',
    'Customer City': 'Customer City',
    'Customer Zone': 'Customer Zone',
    'Customer Governorate & City': 'Customer Governorate & City',
    'Merchant Phone': 'Merchant Phone',
    'Merchant URL': 'Merchant URL',
    'Slots': 'Slots',
    'slots': 'Slots',
    'Bins': 'Bins',
    'bins': 'Bins',
    'View Packages': 'View Packages',
    'Package ID': 'Package ID',
    'Orders Awaiting Call': 'Orders Awaiting Calls',
    'Awaiting Calls': 'Awaiting Calls',
    'City/Zone': 'City/Zone',
    'Original Delivery Date': 'Original Delivery Date',
    'Attempts': 'Attempts',
    'Outcome': 'Actions',
    'Confirmed': 'Confirmed',
    'Fail Order': 'Fail Order',
    'Notifications': 'Notifications',
    'Invoice Date': 'Invoice Date',
    'Statement Date': 'Statement Date',
    'Confirm delivery?': 'Confirm delivery?',
    'Supply': 'Supply',
    'Orders Awaiting Actions': 'Orders Awaiting Actions',
    'Awaiting Actions': 'Awaiting Actions',
    'You are all set. Thank you for calling our customers and making them happy': 'You are all set. Thank you for calling our customers and making them happy',
    'You don\'t have any scheduled pickups': 'You don\'t have any scheduled pickups',
    'Create orders by clicking the \'+\' button above. We will come over to pick them up.': 'Create orders by clicking the \'+\' button above. We will come over to pick them up.',
    'We give your customers a premium delivery service to come buy again from you.': 'We give your customers a premium delivery service to come buy again from you.',
    'Sit back and wait for your money to be collected and deposited. Our billing is fully automated.': 'Sit back and wait for your money to be collected and deposited. Our billing is fully automated.',
    'PUDO View': 'PUDO View',
    'Customer unreachable': 'Customer unreachable',
    'Adjustment': 'Adjustment',
    'Search': 'Search',
    'Enter tracking number or phone number': 'Enter tracking number or phone number',
    'You are now online. Some local updates were synced successfully!': 'You are now online. Some local updates were synced successfully!',
    'Data stored in your device and will be synced when you reconnect online!': 'Data stored in your device and will be synced when you reconnect online!',
    'Receive Returns': 'Receive Returns',
    'Refuse Return': 'Refuse Return',
    'Choose reason for refused Return': 'Choose reason for refused Return',
    'The Package is damaged': 'The Package is damaged',
    'There is a missing item in the package': 'There is a missing item in the package',
    'The AWB is missing': 'The AWB is missing',
    'I don’t have this package on me': 'I don’t have this package on me',
    'Merchant is having technical issues': 'Merchant is having technical issues',
    'Refuse Returns': 'Refuse Returns',
    'REPORT REFUSE': 'REPORT REFUSE',
    'Please, download our Android application by clicking': 'Please, download our Android application by clicking',
    'this link.': 'this link.',
    'This web application will stop working soon.': 'This web application will stop working soon.',
    'RTO Requested': 'RTO Requested',
    'Delivery Attempts': 'Delivery Attempts',
    'Upload Orders': 'Upload Orders',
    'Confirm return': 'Confirm return',
    'Postpone Returns': 'Postpone Returns',
    'Pickup Days': 'Pickup Days',
    'Integrations': 'Integrations',
    'Declined': 'Declined',
    'Approved': 'Approved',
    'Payment Account': 'Payment Account',
    'Payment Accounts': 'Payment Accounts',
    'Payments': 'Payments',
    'Payment': 'Payment',
    'Date': 'Date',
    'New Payment': 'New Payment',
    'COD': 'COD',
    'Drop File or': 'Drop File or',
    'New Payment Account': 'New Payment Account',
    'Account Name': 'Account Name',
    'Account Number': 'Account Number',
    'Number': 'Number',
    'Add Payment Account': 'Add Payment Account',
    'Create Bag': 'Create Bag',
    'You can scan packages after creating a bag.': 'You can scan packages after creating a bag.',
    'Paid To': 'Paid To',
    'Criteria': 'Criteria',
    'Discount': 'Discount',
    'Value': 'Value',
    'New Discount': 'New Discount',
    'Pricing Package': 'Pricing Package',
    'New Pricing Package': 'New Pricing Package',
    'Add Pricing Package': 'Add Pricing Package',
    'Master Bags': 'Master Bags',
    'Google Sheet URL': 'Google Sheet URL',
    'Refunds Enabled': 'Refunds Enabled',
    'Refunds Credit Limit': 'Refunds Credit Limit',
    'This is all the cash we have': 'This is all the cash we have',
    'collected on your behalf as seen': 'collected on your behalf as seen',
    'by our accounting team.': 'by our accounting team.',
    'This is all the service fees and': 'This is all the service fees and',
    'refunds you owe to ShipBlu as': 'refunds you owe to ShipBlu as',
    'seen by our accounting team.': 'seen by our accounting team.',
    'This is the expected cash balance': 'This is the expected cash balance',
    'to be deposited in your next': 'to be deposited in your next',
    'statement as seen by our': 'statement as seen by our',
    'accounting team.': 'accounting team.',
    'Last updated: ': 'Last updated: ',
    'This is all the cash': 'This is all the cash we have collected on your behalf as seen by our accounting team.',
    'This is all the service': 'This is all the service fees and refunds you owe to ShipBlu as seen by our accounting team.',
    'This is the expected': 'This is the expected cash balance to be deposited in your next statement as seen by our accounting team.',
    'COD Balance': 'COD Balance',
    'Service Fees & Refunds Due': 'Service Fees & Refunds Due',
    'Net Cash Balance': 'Net Cash Balance',
    'SLot': 'Slot',
    'Slot View': 'Slot View',
    'Bin View': 'Bin View',
    'Seal': 'Seal',
    'Slot Name': 'Slot Name',
    'Empty Slot': 'Empty Slot',
    'Checkout Date': 'Checkout Date',
    'adjustment': 'adjustment',
    'Type': 'Type',
    'Move': 'Move',
    'GPS Loaction': 'GPS Loaction',
    'UNZONED': 'UNZONED',
    'Bin Name': 'Bin Name',
    'Scanned Successfully': 'Scanned Successfully',
    'Count of Master Bags': 'Count of Master Bags',
    'Orders In-Bag': 'Orders In-Bag',
    'Seal No.': 'Seal No.',
    'Bag Type': 'Bag Type',
    'Incoming': 'Incoming',
    'Outgoing': 'Outgoing',
    'Seal Number': 'Seal Number',
    'Packages In-Bag': 'Packages In-Bag',
    'Master Bag View': 'Master Bag View',
    'Master Bag Information': 'Master Bag Information',
    'To warehouse': 'To warehouse',
    'Current warehouse': 'Current warehouse',
    'Please, open camera permission from your browser!': 'Please, open camera permission from your browser!',
    'Growth Hackers': 'Growth Hackers',
    'Growth Hacking': 'Growth Hacking',
    'New Growth Hacker': 'New Growth Hacker',
    'Edit Growth Hacker': 'Edit Growth Hacker',
    'Add Growth Hacker': 'Add Growth Hacker',
    'Other': 'Other',
    'Count': 'Count',
    'Item': 'Item',
    'New Inventoy Item': 'New Inventoy Item',
    'Credit Notes': 'Credit Notes',
    'Promo Code': 'Promo Code',
    'If you have a promo code': 'If you have a promo code',
    'Exception Discount': 'Exception Discount',
    'Promo Codes': 'Promo Codes',
    'Exception Discounts': 'Exception Discounts',
    'Scope': 'Scope',
    'Service Types': 'Service Types',
    'Auto Apply': 'Auto Apply',
    'Start Date': 'Start Date',
    'Expiry Date': 'Expiry Date',
    'Units': 'Units',
    'Unit': 'Unit',
    'Limitations': 'Limitations',
    'Use Limit per Code': 'Use Limit per Code',
    'Use Limit per Merchant': 'Use Limit per Merchant',
    'EGP Limit per Code': 'EGP Limit per Code',
    'EGP Limit per Merchant': 'EGP Limit per Merchant',
    'Qualifier': 'Qualifier',
    'Source Governorates': 'Source Governorates',
    'Destination Governorates': 'Destination Governorates',
    'New Promo Code': 'New Promo Code',
    'Add Promo Code': 'Add Promo Code',
    'Service Type': 'Service Type',
    'New Exception Discount': 'New Exception Discount',
    'Add Exception Discount': 'Add Exception Discount',
    'Edit Promo Code': 'Edit Promo Code',
    'Edit Exception Discount': 'Edit Exception Discount',
    'Task Sheet Order': 'Task Sheet Order',
    'Custody': 'Custody',
    'Cash In-hand': 'Cash In-hand',
    'Paid by credit card': 'Paid by credit card',
    'Task Cash Collection': 'Payment Collection',
    'Are you sure to receive': 'Are you sure to receive',
    'Are you sure to collect cash': 'Are you sure to collect cash',
    'Sharkia Warehouse': 'Sharkia Warehouse',
    'Blacklist': 'Blacklist',
    'Reason': 'Reason',
    'Block Customer': 'Block Customer',
    'Block': 'Block',
    'Are you sure to remove this customer from blacklist?': 'Are you sure to remove this customer from blacklist?',
    'Task sheet already closed.': 'Task sheet already closed.',
    ' has been blocked successfully!': ' has been blocked successfully!',
    'Packaging does not follow ShipBlu guidelines': 'Packaging does not follow ShipBlu guidelines',
    'Copy link': 'Copy link',
    'Incoming Orders': 'Incoming Orders',
    'Magic': 'Magic',
    'Magic Links': 'Magic Links',
    'copied to clipboard!': 'Copied to clipboard!',
    'Link': 'Link',
    'Enabled': 'Enabled',
    'Copy': 'Copy',
    'Current Usage': 'Current Usage',
    'Max Usage': 'Max Usage',
    'am': 'am',
    'pm': 'pm',
    'URO Info': 'URO Info',
    'Main Stock Request': 'Main Stock Request',
    'New stock Request': 'New stock Request',
    'Open Parcel': 'Open Packages',
    ' not found!': ' not found!',
    'Please select the order you want to ship!': 'Please select the order you want to ship!',
    'Please select order you want to download!': 'Please select order you want to download!',
    'Please select the order you want to request!': 'Please select the order you want to request!',
    'Please select only the created order you want to cancel!': 'Please select only the created order you want to cancel!',
    'Please select the order you want to print!': 'Please select the order you want to print!',
    'This order is not pickup requested, We can\'t add it to your task list!': 'This order is not pickup requested, We can\'t add it to your task list!',
    'The web app is no longer supported on Android devices. Please, use the Android app from: ': 'The web app is no longer supported on Android devices. Please, use the Android app from: ',
    'Tracking number, merchant name or reference number...': 'Tracking number, merchant name or reference number...',
    'Webhook': 'Webhook',
    'Webhooks': 'Webhooks',
    'Webhook Events': 'Webhook Events',
    'Select your dropoff point:': 'Select your dropoff point:',
    'Select your pickup point:': 'Select your pickup point:',
    'Customer Information & Address': 'Customer Information & Address',
    'Full Name': 'Full Name',
    'Your customer full name': 'Your customer full name',
    'Email Address': 'Email Address',
    'Additional Phone Number': 'Additional Phone Number',
    'The parcel': 'The parcel',
    'Can Open': 'Can Open',
    'Can\'t Open': 'Can\'t Open',
    'Cash On Delivery (COD) ': 'Cash On Delivery (COD)',
    '"Notes" will be printed on the order\'s label.': '"Notes" will be printed on the order\'s label.',
    'Do you want to leave notes?': 'Do you want to leave notes?',
    'your notes..': 'your notes..',
    'Done': 'Done',
    'Add Description': 'Add Description',
    'Add Supplies Items': 'Add Supplies Items',
    'In order to complete your exchange fill in the required info below': 'In order to complete your exchange fill in the required info below',
    'Add new Exchange Request': 'New Exchange Order',
    'Here you can add all the supplies you need': 'Here you can add all the supplies you need',
    'Create Order': 'Create Order',
    'Create Return': 'Create Return',
    'Holidays': 'Holidays',
    'Holiday': 'Holiday',
    'Add Holiday': 'Add Holiday',
    'Applies To': 'Applies To',
    'First Mile Orders': 'First Mile Orders',
    'Last Mile Orders': 'Last Mile Orders',
    'RTOs': 'RTOs',
    'Order Tracking': 'Order Tracking',
    'Good Job!': 'Good Job!',
    'Your order has been created,': 'Your order has been created,',
    'You can now request your pickup!': 'You can now request your pickup!',
    'Success!': 'Success!',
    'We are working on your export,': 'We are working on your export,',
    'We will send you a notification once it’s done.': 'We will send you a notification once it’s done.',
    'Your exchange hass been created. We’ll come to pickup the order first,': 'Your exchange hass been created. We’ll come to pickup the order first,',
    'Find your order in Exchanges!': 'Find your order in Exchanges!',
    'Performance': 'Performance',
    'Merchant Profile': 'Merchant Profile',
    'Financials': 'Financials',
    'Tax Profile': 'Tax Profile',
    'Payout Methods': 'Payout Methods',
    'Tax Registration Number': 'Tax Registration Number',
    'Building Number': 'Building Number',
    'Legal Name': 'Legal Name',
    'Street': 'Street',
    'Tax Profile Type': 'Tax Profile Type',
    'Exchange Request': 'Exchange Request',
    'Select the delivered order you want, and pickup to exchange!': 'Select the delivered order you want, and pickup to exchange!',
    'Upload Accepted E-Invoices': 'Upload Accepted E-Invoices',
    'Export E-Invoices': 'Export E-Invoices',
    ' field is required': ' field is required',
    ' field only accepts english letters': ' field only accepts english letters',
    'Tax profile only accepts english letters': 'Tax profile only accepts english letters',
    'The legal name': 'The legal name',
    'The governorate': 'The governorate',
    'The city': 'The city',
    'The street': 'The street',
    'The building number': 'The building number',
    'The full name': 'The full name',
    'The tax registration number': 'The tax registration number',
    'The tax registration number only accepts english numbers': 'The tax registration number only accepts english numbers',
    'Export Task Lists': 'Export Task Lists',
    'Select agent type!': 'Select agent type!',
    'Select your export range!': 'Select your export range!',
    'delivery-orders': 'Delivery Orders',
    'cash-collections': 'Cash Collections',
    'returns': 'returns',
    'exchanges': 'Exchanges',
    'orders': 'All Orders',
    'orders-awaiting-calls': 'Orders Awaiting Action',
    'Delivery Operations': 'Delivery Operations',
    'Fulfillment Operations': 'Fulfillment Operations',
    'Fulfillment Dashboard': 'Dashboard',
    'Product Catalog': 'Product Catalog',
    'Stock Requests': 'Stock Requests',
    'Fulfillment Orders': 'Fulfillment Orders',
    'Inventory': 'Inventory',
    'Reports': 'Reports',
    'Delivery Pricing Packages': 'Delivery Pricing Packages',
    'Fulfillment Packages': 'Fulfillment Packages',
    'Are you sure to remove this product?': 'Are you sure to remove this product?',
    'Product': 'Product',
    ' has been subscribed successfully!': ' has been subscribed successfully!',
    'Stock request': 'Stock request',
    'Stock Request View': 'Stock Request View',
    'FC Operations Supervisor': 'FC Operations Supervisor',
    'Add FC Operations Supervisor': 'Add FC Operations Supervisor',
    'Edit FC Operations Supervisor': 'Edit FC Operations Supervisor',
    'Stock Request Info': 'Stock Request Info',
    'SBSIN': 'SBSIN',
    'SKU': 'SKU',
    'SKU Code': 'SKU Code',
    'Group': 'Group',
    'Weight (kg)': 'Weight (kg)',
    'Height (cm)': 'Height (cm)',
    'Width (cm)': 'Width (cm)',
    'Length (cm)': 'Length (cm)',
    'Channels': 'Channels',
    'Supplier': 'Supplier',
    'Product Group': 'Product Group',
    'Current Inventory': 'Current Inventory',
    'Reorder count': 'Reorder count',
    'New Product': 'New Product',
    'Edit Product': 'Edit Product',
    'Generate': 'Generate',
    'Product Name': 'Product Name',
    'Color': 'Color',
    'Dimensions': 'Dimensions',
    'Weight': 'Weight',
    'Incoming Quantity': 'Incoming Quantity',
    'Received Quantity': 'Received Quantity',
    'Flags': 'Flags',
    'Fulfillment Center': 'Fulfillment Center',
    'Number Of Vehicles': 'Number Of Vehicles',
    'Add New Product': 'Add New Product',
    'Add more products': 'Add more products',
    'By': 'By',
    'WROs': 'WROs',
    'Request ID': 'Request ID',
    'Number of SKUs': 'Number of SKUs',
    'Total Quantity': 'Total Quantity',
    'Received On': 'Received On',
    'Sales': 'Sales',
    'Roles': 'Roles',
    'Ready to Send': 'Ready to Send',
    'Current Inventory Level': 'Current Inventory Level',
    'Assiut Warehouse': 'Assiut Warehouse',
    'Gesr El Suez Warehouse': 'Gesr El Suez Warehouse',
    'customer_name': 'Customer Name',
    'merchant_name': 'Merchant Name',
    'item_count': 'Item Count',
    'number_of_packages': 'Number of Packages',
    'ميت عنتر': 'ميت عنتر',
    'منية سندوب': 'منية سندوب',
    'البرلس - Borolles': 'Borolles',
    'El Kilo 21 - 21 الكيلو': 'El Kilo 21',
    'El-Bitash Qbli - البيطاش قبلي': 'El-Bitash Qbli',
    'El Soyof Qbli - السيوف قبلي': 'El Soyof Qbli',
    'Borj El Arab - برج العرب': 'Borj El Arab',
    'جامعة القاهرة - Cairo University': 'Cairo University',
    'البحر الاعظم - Al Bahr Al Aazam': 'Al Bahr Al Aazam',
    'الكنيسة - Al Kenisah': 'Al Kenisah',
    'سقارة - Sakaraa': 'Sakaraa',
    'خاتم المرسلين - Khatem El Morsaleen': 'Khatem El Morsaleen',
    'المريوطية - Maryotia': 'Maryotia',
    'الرماية - Rimaya': 'Rimaya',
    'الطالبية - Eltalbia': 'Eltalbia',
    'العمرانية - El Omraniya': 'El Omraniya',
    'المنيب - Elmoneeb': 'Elmoneeb',
    'مصر الجديدة-النزهة الجديدة - Heliopolis-New Nozha': 'Heliopolis-New Nozha',
    'مصر الجديدة-الماظة - Heliopolis-Almaza': 'Heliopolis-Almaza',
    'مصر الجديدة-الحجاز - Heliopolis-Elhegaz': 'Heliopolis-Elhegaz',
    'مصر الجديدة-كلية البنات - Heliopolis-Kolet Elbanat': 'Heliopolis-Kolet Elbanat',
    'مصر الجديدة-منشية البكري - Heliopolis-Manshet Elbakry': 'Heliopolis-Manshet Elbakry',
    'مصر الجديدة-روكسي - Heliopolis-Roxy': 'Heliopolis-Roxy',
    'المعادي-كورنيش المعادي - Maadi-Ridge': 'Maadi-Ridge',
    'المعادي-المعادي الجديدة - Maadi-New Maadi': 'Maadi-New Maadi',
    'المعادي-صقر قريش - Maadi-Sakr Korish': 'Maadi-Sakr Korish',
    'المعادي-البساتين - Maadi-Elbasateen': 'Maadi-Elbasateen',
    'المعادي-دار السلام - Maadi-Dar Elsalam': 'Maadi-Dar Elsalam',
    'المعادي-ثكنات المعادي - Maadi-Sakanat': 'Maadi-Sakanat',
    'المعادي-دجلة - Maadi-Degla': 'Maadi-Degla',
    'المعادي-اللاسلكي - Maadi-Ellaselky': 'Maadi-Ellaselky',
    'المعادي-المعراج - Maadi-Elmaaraj': 'Maadi-Elmaaraj',
    'المعادي-الزهراء - Maadi-Zahraa': 'Maadi-Zahraa',
    'مدينة نصر الأوتوستراد - Nasr City Autostrad': 'Nasr City Autostrad',
    'مدينة نصر مساكن الظباط - Nasr City Masakin Aldubbat': 'Nasr City Masakin Aldubbat',
    'مدينة نصر الواحة - Nasr City Alwaha': 'Nasr City Alwaha',
    'مدينة نصر الحى الثامن- Nasr City 8th District': 'Nasr City 8th District',
    'زهراء مدينة نصر - Zahraa Nasr City': 'Zahraa Nasr City',
    'مدينة نصر الحي العاشر - Nasr City 10th District': 'Nasr City 10th District',
    'مدينة نصر المقاولين العرب - Nasr City Arab Contractors': 'Nasr City Arab Contractors',
    'مدينة نصر الوفاء والأمل - Nasr City Elwafa w Alamal': 'Nasr City Elwafa w Alamal',
    'مدينة نصر الجبل الأحمر - Nasr City Aljabal Alahmar': 'Nasr City Aljabal Alahmar',
    'مدينة نصر الحى السابع - Nasr City 7th District': 'Nasr City 7th District',
    'مدينة نصر عباس العقاد - Nasr City Abbas ElAkkad': 'Nasr City Abbas ElAkkad',
    'مدينة نصر - Nasr City Emtedad Ramsis': 'Nasr City Emtedad Ramsis',
    'مدينة نصر منشية ناصر - Nasr City Monshaat Naser': 'Nasr City Monshaat Naser',
    'مدينة نصر الأستاد - Nasr City Cairo Stadium': 'Nasr City Cairo Stadium',
    'مدينة نصر جامعة الأزهر - Nasr City Al Azhar University': 'Nasr City Al Azhar University',
    'مدينة نصر الحي السادس - Nasr City 6th District': 'Nasr City 6th District',
    'مركز منيا القمح': 'مركز منيا القمح',
    'مركز مشتول السوق': 'مركز مشتول السوق',
    'مركز كفر صقر': 'مركز كفر صقر',
    'مركز ههيا': 'مركز ههيا',
    'مركز فاقوس': 'مركز فاقوس',
    'مركز ديرب نجم': 'مركز ديرب نجم',
    'مركز بلبيس': 'مركز بلبيس',
    'مركز أولاد صقر': 'مركز أولاد صقر',
    'مركز الابراهيمية': 'مركز الابراهيمية',
    'مركز الحسينية': 'مركز الحسينية',
    'مركز أبوكبير': 'مركز أبوكبير',
    'مركز أبو حماد': 'مركز أبو حماد',
    'مركز الزقازيق': 'مركز الزقازيق',
    'مركز القناطر الخيرية': 'مركز القناطر الخيرية',
    'مركز قليوب': 'مركز قليوب',
    'مركز قها': 'مركز قها',
    'مركز شبين القناطر': 'مركز شبين القناطر',
    'مركز طوخ': 'مركز طوخ',
    'مركز كفر شكر': 'مركز كفر شكر',
    'مركز تلا': 'مركز تلا',
    'مركز قويسنا': ' مركز قويسنا',
    'مركز منوف': 'مركز منوف',
    'مركز بركة السبع': 'مركز بركة السبع',
    'مركز الشهداء': 'مركز الشهداء',
    'مركز أشمون': 'مركز أشمون',
    'مركز الباجور': 'مركز الباجور',
    'قسم بورفؤاد': 'قسم بورفؤاد',
    'قسم شرطة الزهور': 'قسم شرطة الزهور',
    'قسم الشرق': 'قسم الشرق',
    'قسم المناخ': 'قسم المناخ',
    'قسم الجنوب': 'قسم الجنوب',
    'قسم العرب': 'قسم العرب',
    'كفر سعد': 'كفر سعد',
    'قسم شرطة الضواحي': 'قسم شرطة الضواحي',
    'البطيخ': 'البطيخ',
    'الزرقا': 'الزرقا',
    'فارسكور': 'فارسكور',
    'دمياط': 'دمياط',
    'مركز شبراخيت': 'مركز شبراخيت',
    'مركز رشيد': 'مركز رشيد',
    'مركز كوم حمادة': 'مركز كوم حمادة',
    'مركز كفر الدوار': 'مركز كفر الدوار',
    'مركز إيتاى البارود': 'مركز إيتاى البارود',
    'مركز إدكو': 'مركز إدكو',
    'مركز حوش عيسى': 'مركز حوش عيسى',
    'مركز ابو المطامير': 'مركز ابو المطامير',
    'مركز المحمودية': 'مركز المحمودية',
    'مركز الدلنجات': 'مركز الدلنجات',
    'مركز ابوحمص': 'مركز ابوحمص',
    'مركز زفتى': 'مركز زفتى',
    'مركز سمنود': 'مركز سمنود',
    'مركز قطور': 'مركز قطور',
    'مركز كفر الزيات': 'مركز كفر الزيات',
    'مركز بسيون': 'مركز بسيون',
    'مركز السنطة': 'مركز السنطة',
    'مركز منية النصر': 'مركز منية النصر',
    'مركز دكرنس': 'مركز دكرنس',
    'مركز المنزلة': 'مركز المنزلة',
    'مركز السنبلاوين': 'مركز السنبلاوين',
    'مركز أجا': 'مركز أجا',
    'قسم ميت غمر': 'قسم ميت غمر',
    'مركز نبروة': 'مركز نبروة',
    'مركز شربين': 'مركز شربين',
    'مركز بلقاس': 'مركز بلقاس',
    'El Soyof Bahree - السيوف بحري': 'El Soyof Bahree',
    'المنصورة': 'المنصورة',
    'مركز طلخا': 'مركز طلخا',
    'مركز بنها': 'مركز بنها',
    'مركز شبين الكوم': 'مركز شبين الكوم',
    'مركز المحلة الكبرى': 'مركز المحلة الكبرى',
    'مركز طنطا': 'مركز طنطا',
    'مركز دمنهور': 'مركز دمنهور',
    'مركز بيلا': 'مركز بيلا',
    'مركز دسوق': 'مركز دسوق',
    'مركز الرياض': 'مركز الرياض',
    'مركز فوه': 'مركز فوه',
    'مركز الحامول': 'مركز الحامول',
    'مركز كفر الشيخ': 'مركز كفر الشيخ',
    'مركز مطوبس': 'مركز مطوبس',
    'مركز قلين': 'مركز قلين',
    'مركز سيدى سالم': 'مركز سيدى سالم',
    'مركز أسيوط': 'مركز أسيوط',
    'مركز أبنوب': 'مركز أبنوب',
    'مركز ساحل سليم': 'مركز ساحل سليم',
    'مركز صدفا': 'مركز صدفا',
    'مركز منفلوط': 'مركز منفلوط',
    'مركز القوصية': 'مركز القوصية',
    'مركز الغنايم': 'مركز الغنايم',
    'مركز الفتح': 'مركز الفتح',
    'مركز البدارى': 'مركز البدارى',
    'مركز ديروط': 'مركز ديروط',
    'مركز أبوتيج': 'مركز أبوتيج',
    'Customer name, ': 'Customer name, ',
    'Tracking number, ': 'Tracking number, ',
    'or reference number...': 'or reference number...',
    'We will automatically create a fulfillment order for you. Once pickup is requested, the fulfillment order is requested to ship.': 'We will automatically create a fulfillment order for you. Once pickup is requested, the fulfillment order is requested to ship.',
    'Fulfilling with us?': 'Fulfilling with us?',
    'Update Products': 'Update Products',
    'Add products': 'Add products',
    'fulfillment products': 'fulfillment products',
    'Merchant Display Name': 'Display Name',
    'Cannot create/update fulfillment order after delivery order creation.': 'Cannot create/update fulfillment order after delivery order creation.',
    'Cannot create/update fulfillment order after return order creation.': 'Cannot create/update fulfillment order after return order creation.',
    'URO View': 'URO View',
    'UROs': 'UROs',
    'Should return at least one product': 'Should return at least one product',
    'This package has been scan successfully': 'This package has been scan successfully',
    'Upload Bank Deposits': 'Upload Bank Deposits',
    'Successful Deliveries': 'Successful Deliveries',
    'Total Packages': 'Total Packages',
    'Gross Revenue + VAT': 'Gross Revenue + VAT',
    'Gross Revenue': 'Gross Revenue',
    'Failed Attempts': 'Failed Attempts',
    'Net Revenue': 'Net Revenue',
    'Total CoD Collected To Date': 'Total CoD Collected To Date',
    'Cancel Shipments': 'Cancel Shipments',
    'Select orders criteria you want to search!': 'Select orders criteria you want to search!',
    'Select orders statuses you want to export!': 'Select orders statuses you want to export!',
    'Select your search range!': 'Select your search range!',
    'No Date': 'No Date',
    'Return Attempts': 'Return Attempts',
    'Customer': 'Customer',
    'All Logs': 'All Logs',
    'Hide logs ..': 'Hide logs ..',
    'Show logs ..': 'Show logs ..',
    'Outcoming Quantity': 'Outcoming Quantity',
    'Product SKU': 'Product SKU',
    'Outbound Products': 'Outbound Products',
    'Inbound Products': 'Inbound Products',
    'Account Manager': 'Account Manager',
    'Subscribe to Fulfillment': 'Subscribe to Fulfillment',
    'Committed Volume': 'Committed Volume',
    'Force Scan Package Size Barcode': 'Force Scan Package Size Barcode',
    'Access Financials': 'Access Financials',
    'Delivery Pricing': 'Delivery Pricing',
    'Fulfillment Pricing': 'Fulfillment Pricing',
    'Default': 'Default',
    'Set Default': 'Set Default',
    'Opening Balance': 'Opening Balance',
    'Wallet Number': 'Wallet Number',
    'Bank Name': 'Bank Name',
    'Bank': 'Bank',
    'Wallet': 'Wallet',
    'Visit Days': 'Visit Days',
    'Primary Warehouse': 'Primary Warehouse',
    'Select All Zones': 'Select All Zones',
    'Today’s Last-mile': 'Today’s Last-mile',
    'Today’s First-mile': 'Today’s First-mile',
    'Today’s Financials': 'Today’s Financials',
    'Customer Pickups': 'Customer Pickups',
    'Merchant Pickups': 'Merchant Pickups',
    'Merchant Returns': 'Merchant Returns',
    'In-House Cash EGP': 'In-House Cash EGP',
    'COD EGP': 'COD EGP',
    'Refunds EGP': 'Refunds EGP',
    'Cash Collections EGP': 'Cash Collections EGP',
    'Warehouse Stats': 'Warehouse Stats',
    'In-Coming Orders': 'In-Coming Orders',
    'In-House RTOs': 'In-House RTOs',
    'Incoming Master Bags': 'Incoming Master Bags',
    'Outgoing Master Bags': 'Outgoing Master Bags',
    'DW': 'DW',
    'GPS': 'GPS',
    'PD': 'PD',
    'Return request date': 'Return request date',
    'Return attempts': 'Return attempts',
    'Return date': 'Return date',
    'Request Date': 'Request Date',
    'Col - Attempts': 'Col - Attempts',
    'Col - Date': 'Col - Date',
    'Actual Quantity': 'Actual Quantity',
    'Cash Requests': 'Cash Requests',
    'Today\'s task list': 'Today\'s task list',
    'Previous task lists': 'Previous task lists',
    'First Mile': 'First Mile',
    'Last Mile': 'Last Mile',
    'Select a date': 'Select a date',
    'Filter': 'Filter',
    'In-House Orders': 'In-House Orders',
    'Details': 'Details',
    'Action': 'Action',
    'Code / Tracking No.': 'Code / Tracking No.',
    'Products': 'Products',
    'Regular': 'Regular',
    'Total Flyers Selected:': 'Total Flyers Selected:',
    'SLA': 'SLA',
    'Online Orders': 'Online Orders',
    'Update CoD': 'Update CoD',
    'Update Packages': 'Update Packages',
    'Update SLA': 'Update SLA',
    'Open Packages': 'Open Packages',
    'Generate Magic Link': 'Generate Magic Link',
    'New Stock Request': 'New Stock Request',
    'Are you sure to delete?': 'Are you sure to delete?',
    'Unidentified Stock Requests': 'Unidentified Stock Requests',
    'Stock Request': 'Stock Request',
    'Product Description': 'Product Description',
    'Confirm to Ship': 'Confirm to Ship',
    'Return Orders': 'Return Orders',
    'Order ID': 'Order ID',
    'No. of Items': 'No. of Items',
    'inbound': 'inbound',
    'outbound': 'outbound',
    'Packaging': 'Packaging',
    'Shipping Info': 'Shipping Info',
    'other': 'other',
    'Inbound Order': 'Inbound Order',
    'Outbound Order': 'Outbound Order',
    'Merchant Reference': 'Merchant Reference',
    'Add packaging': 'Add packaging',
    'Account name': 'Account name',
    'The Legal Name': 'The Legal Name',
    'The Tax Registration Number': 'The Tax Registration Number',
    'eCommerce Store': 'eCommerce Store',
    'Endpoint': 'Endpoint',
    'Subscribed To': 'Subscribed To',
    'Payload': 'Payload',
    'Response': 'Response',
    'Slot Title': 'Slot Title',
    'No Space': 'No Space',
    'View Bin': 'View Bin',
    'Supply Inventory': 'Supply Inventory',
    'Add Comment': 'Add Comment',
    'Comment': 'Comment',
    'CoD Balance': 'CoD Balance',
    'Service fees & Refunds due': 'Service fees & Refunds due',
    'Net cash balance': 'Net cash balance',
    'Quickbooks last updated': 'Quickbooks last updated',
    'Drop Json File or': 'Drop Json File or',
    'Payout Method': 'Payout Method',
    'No. Merchants': 'No. Merchants',
    'Total Usage Count': 'Total Usage Count',
    'Total Usage Value': 'Total Usage Value',
    'Threshold': 'Threshold',
    'Time Window': 'Time Window',
    'Actual sent quantity': 'Actual sent quantity',
    'Create URO': 'Create URO',
    'Add URO': 'Add URO',
    'Inbound Orders': 'Inbound Orders',
    'Outbound Orders': 'Outbound Orders',
    'Update Product': 'Update Product',
    'Add Customer Support': 'Add Customer Support',
    'Edit Customer Support': 'Edit Customer Support',
    '(Optional)': '(Optional)',
    'Reserved Inventory': 'Reserved Inventory',
    'Total Current Inventory': 'Total Current Inventory',
    'Total products': 'Total products',
    'Total Reserved Inventory': 'Total Reserved Inventory',
    'Sync Quickbooks': 'Sync Quickbooks',
    'Outbound Linked Tracking Number': 'Linked Tracking Number',
    'Inbound Linked Tracking Number': 'Linked Tracking Number',
    'Are you sure you want to confirm receiving': 'Are you sure you want to confirm receiving',
    'Product(s)?': 'Product(s)?',
    'Are you sure you want to cancel this stock request?': 'Are you sure you want to cancel this stock request?',
    'Your Active Insurance Plan': 'Your Active Coverage Plan',
    'Insurance Fees / Order': 'Coverage Fees / Order',
    'Payout Coverage': 'Payout Coverage',
    'Your default declared value :': 'Your default declared value',
    'Your default declared value': 'Your Default Declared Value',
    'Disable': 'Disable',
    'Insurance Plans': 'Coverage Plans',
    'Submit Plan': 'Submit Plan',
    'ShipBlu Ensures The Safe Transport Of Your Goods And Its Responsibilty For Lossess Or Damages Due To Negligence.': 'ShipBlu ensures the safe transport of your packages against any risk that may exist during their transit.',
    'Insurance Fees:': 'Coverage Plans:',
    'The Insurance service fees per order': 'The coverage service fees per order',
    'Payout Coverage %:': 'Payout Coverage %:',
    'The Compensation amount in case of any loss or damage': 'The compensation amount in case of any loss or damage',
    'Your default declared value:': 'Your Default Declared Value:',
    'Incase you have created an order with no cod or declared value,we will apply the default declared value': 'Incase you have created an order with no cod or declared value,we will apply the default declared value',
    'Please Choose One Of The Below Insurance Plans': 'Please Choose One Of The Below Coverage Plans',
    'Insurance Fees Per Order': 'Coverage Fees Per Order',
    'Payout Coverage For Order Up To': 'Payout Coverage For Order Up To',
    'If COD For Order = 0.00 EGP': 'If COD For Order = 0.00 EGP',
    'I Agree With The': 'I Agree With The',
    'Terms and Conditions': 'Terms and Conditions',
    'Are you Syre That You Want This Insurance Plan ?': 'Are you Syre That You Want This Coverage Plan ?',
    ' Insurance Fees Per Order - ': ' Coverage Fees Per Order - ',
    ' Payout Coverage': ' Payout Coverage',
    'Insurance History': 'Coverage History',
    'Insurance Package Name': 'Coverage Package Name',
    'Effective Date': 'Effective Date',
    'Are you Sure That You Want To Disable This Insurance Plan ?': 'Are you sure that you want to disable this coverage plan?',
    'Are you Sure That You Want This Insurance Plan?': 'Are you sure that you want this coverage plan?',
    'Are you Sure That You Want To Disable This Insurance Plan?': 'Are you sure that you want to disable this coverage plan?',
    'ShipBlu Shield': 'ShipBlu Shield',
    'Are you sure you want to confirm inbounding': 'Are you sure you want to confirm inbounding',
    'ShipBlu Shield Fees': 'ShipBlu Shield Fees',
    'tote': 'Tote',
    'box': 'Box',
    'shelf': 'Shelf',
    'pallet': 'Pallet',
    'Are you sure you want to cancel this supply?': 'Are you sure you want to cancel this supply?',
    'No.of products': 'No.of products',
    'Didn’t receive code?': 'Didn’t receive code?',
    'Enter OTP Code sent to': 'Enter OTP Code sent to',
    'OTP Verification': 'OTP Verification',
    'Resend code': 'Resend code',
    'seconds': 'Seconds',
    'URO': 'URO',
    'FulFillment': 'Fulfillment',
    'view more': 'View More',
    'Add Payout': 'Add Payout',
    'Mobile Wallet': 'Mobile Wallet',
    'Confirm Account Number': 'Confirm Account Number',
    'Access Payout Methods': 'Access Payout Methods',
    'Automatic Pickup, Return, Collection Request': 'Automatic Pickup, Return, Collection Request',
    'Next Statement Issuing Day': 'Next Statement Issuing Day',
    'Next Transfer Day': 'Next Transfer Day',
    'Your Statements & Bank Transfers Full Schedule': 'Your Statements & Bank Transfers Full Schedule',
    'Bank Transfer Days': 'Bank Transfer Days',
    'Statements Issuing Days': 'Statements Issuing Days',
    'from the previous week': 'from the previous week',
    'Transfer covers': 'Transfer covers',
    'Monday': 'Monday',
    'Tuesday': 'Tuesday',
    'Wednesday': 'Wednesday',
    'Thursday': 'Thursday',
    'Friday': 'Friday',
    'Saturday': 'Saturday',
    'Sunday': 'Sunday',
    'Qty': 'Qty',
    'when you create a new order, do you want it to be pickup requested automatically or manually?': 'When you create a new order, do you want it to be pickup requested automatically or manually?',
    'Your choice will become the default and you can change it from your account settings': 'Your choice will become the default and you can change it from your account settings',
    'Manual Pickup Request': 'Manual Pickup Request',
    'Your newly created order will be on "Created" status till you manually change them to "Pickup Requested"': 'Your newly created order will be on "Created" status till you manually change them to "Pickup Requested"',
    'Your newly created order will be on "pickup requested" status automatically': 'Your newly created order will be on "pickup requested" status automatically',
    'Your newly created orders will be on "Created" status till you manually change them to "Pickup Requested"': 'Your newly created orders will be on "Created" status till you manually change them to "Pickup Requested"',
    'Your newly created orders will be on "pickup requested - return requested - collection requested" status automatically': 'Your newly created orders will be on "pickup requested - return requested - collection requested" status automatically',
    'make as default': 'make as default',
    'Order Settings': 'Order Settings',
    'Pickup Request Default': 'Pickup Request Default',
    'Pickup Request preference updated successfully': 'Pickup Request preference updated successfully',
    'Download Statement': 'Download Statement',
    'The date we issue your next statement': 'The date we issue your next statement',
    'The date we transfer the collected cash': 'The date we transfer the collected cash',
    'Collected & not yet transfered': 'Collected & not yet transfered',
    'The cash we collected but not yet processed into a statement': 'The cash we collected but not yet processed into a statement',
    'Cash on the way': 'Cash on the way',
    'The cash amount we will transfer to you': 'The cash amount we will transfer to you',
    'Transfer Details': 'Transfer Details',
    ',': ',',
    'add': 'Add',
    'edit': 'Edit',
    'Picking Bins':'Picking Bins',
    'The Transfer details will be available once the amount is successfully transferred to your account': 'The Transfer details will be available once the amount is successfully transferred to your account',
    'Minya El Qamh - منيا القمح': 'Minya El Qamh',
    'Minya - المنيا': 'Minya',
    'Suhaj - سوهاج': 'Suhaj',
    'Qina - قنا': 'Qina',
    'Al Montazah 1st - حى المنتزه اول': 'Al Montazah 1st',
    'Bahary – بحري': 'Bahary',
    'Burj Al Arab and Al Ameria - برج العرب و العامرية': 'Burj Al Arab and Al Ameria',
    'Al Awaid - العوايد': 'Al Awaid',
    'District East - حى شرقى': 'District East',
    'Al-Raml district - حى الرمل': 'Al-Raml district',
    'Mashtool El Souk - مشتول السوق': 'Mashtool El Souk',
    'Kafr Saqr - كفر صقر': 'Kafr Saqr',
    'Hihya - ههيا': 'Hihya',
    'Faqous - فاقوس': 'Faqous',
    'Diyarb Negm - ديرب نجم': 'Diyarb Negm',
    'Bilbeis - بلبيس': 'Bilbeis',
    'Awlad Saqr - أولاد صقر': 'Awlad Saqr',
    'ElIbrahimiya - الابراهيمية': 'ElIbrahimiya',
    'ElHusseiniya - الحسينية': 'ElHusseiniya',
    'Abu Kabir - أبو كبير': 'Abu Kabir',
    'Abu Hammad - أبو حماد': 'Abu Hammad',
    'ELZagazig - الزقازيق': 'ELZagazig',
    'ElKanater ElKhayreya - القناطر الخيرية': 'ElKanater ElKhayreya',
    'Qalyub - قليوب': 'Qalyub',
    'Qaha - قها': 'Qaha',
    'Shebeen ElKanater - شبين القناطر': 'Shebeen ElKanater',
    'Tukh - طوخ': 'Tukh',
    'Kafr Shokr - كفر شكر': 'Kafr Shokr',
    'Tala - تلا': 'Tala',
    'Quesna - قويسنا': 'Quesna',
    'Menouf - منوف': 'Menouf',
    'Berket ElSabaa - بركة السبع': 'Berket ElSabaa',
    'ElShohdaa - الشهداء': 'ElShohdaa',
    'Ashmoun - أشمون': 'Ashmoun',
    'Portsaid - بورسعيد': 'Portsaid',
    'ElBagor - الباجور': 'ElBagor',
    'Kafr Saad - كفر سعد': 'Kafr Saad',
    'Kafr ElBateekh - كفر البطيخ': 'Kafr ElBateekh',
    'ElZarka - الزرقا': 'ElZarka',
    'Faraskour - فارسكور': 'Faraskour',
    'Damietta - دمياط': 'Damietta',
    'Shabrakheet - شبراخيت': 'Shabrakheet',
    'Rasheed - رشيد': 'Rasheed',
    'Kom Hamada - كوم حمادة': 'Kom Hamada',
    'Kafr Aldawar - كفر الدوار': 'Kafr Aldawar',
    'Etay Albarood - إيتاى البارود': 'Etay Albarood',
    'Edko - إدكو': 'Edko',
    'Hosh Eissa - حوش عيسى': 'Hosh Eissa',
    'Abo Almatameer - ابو المطامير': 'Abo Almatameer',
    'Almahmoudia - المحمودية': 'Almahmoudia',
    'Aldalangat - الدلنجات': 'Aldalangat',
    'Abo Hommos - ابوحمص': 'Abo Hommos',
    'ElRiyadh - الرياض': 'ElRiyadh',
    'Mutuwbas - مطوبس': 'Mutuwbas',
    'Keleen - قلين': 'Keleen',
    'ElHamoul - الحامول': 'ElHamoul',
    'Sidi Salem - سيدى سالم': 'Sidi Salem',
    'Biyla - بيلا': 'Biyla',
    'Fuwah - فوه': 'Fuwah',
    'Dessouk - دسوق': 'Dessouk',
    'Zeftah - زفتى': 'Zeftah',
    'Samanoud - سمنود': 'Samanoud',
    'Ketour - قطور': 'Ketour',
    'Kafr ElZayat - كفر الزيات': 'كفر الزيات',
    'Basyoun - بسيون': 'Basyoun',
    'ElSantah - السنطة': 'ElSantah',
    'Minyet ElNasr - منية النصر': 'Minyet ElNasr',
    'Dikrinis - دكرنس': 'Dikrinis',
    'ElManzalah - المنزلة': 'ElManzalah',
    'Simbalween - السنبلاوين': 'Simbalween',
    'Aga - أجا': 'Aga',
    'Mit Ghamr - ميت غمر': 'Mit Ghamr',
    'Nabarwuah - نبروة': 'Nabarwuah',
    'Sherbin - شربين': 'Sherbin',
    'Belkas - بلقاس': 'Belkas',
    'Talkha - طلخا': 'Talkha',
    'Hurghada - الغردقة': 'Hurghada',
    'Suez - السويس': 'Suez',
    'Ismailiya - الاسماعيلية': 'Ismailiya',
    'Beni Suef - بني سويف': 'Beni Suef',
    'Fayoum - الفيوم': 'Fayoum',
    'Assiut - أسيوط': 'Assiut',
    'Kafr ElSheikh - كفر الشيخ': 'Kafr ElSheikh',
    'Tanta - طنطا': 'Tanta',
    'ElMahala ElKobra - المحلة الكبرى': 'ElMahala ElKobra',
    'Banha - بنها': 'Banha',
    'Mansoura - المنصورة': 'Mansoura',
    'Sahel - الساحل': 'Sahel',
    'East Alex & Montazah - شرق الاسكندرية والمنتزة': 'East Alex & Montazah',
    'Downtown Alex & Gomrok - وسط البلد والجمرك': 'Downtown Alex & Gomrok',
    'New Cairo - القاهرة الجديدة': 'New Cairo',
    'El Mandara Qibli - المندرة قبلي': 'El Mandara Qibli',
    'El Asafra Qibli - العصافرة قبلي': 'El Asafra Qibli',
    'Sidi Bishr Qibli - سيدي بشر قبلي': 'Sidi Bishr Qibli',
    'Oct Degla Palms - دجلة بالمز': 'Oct Degla Palms',
    'Oct Industrial Zone - المنطقة الصناعية أكتوبر': 'Oct Industrial Zone',
    'Oct Palm Hills - بالم هيلز أكتوبر': 'Oct Palm Hills',
    'October Gardens - حدايق أكتوبر': 'October Gardens',
    'El Amiria - الأميرية': 'El Amiria',
    'El Zawia El Hamra - الزاوية الحمراء': 'El Zawia El Hamra',
    'El Sharabia - الشرابية': 'El Sharabia',
    'El Sabtiyyah - السبتية': 'El Sabtiyyah',
    '15th May - ١٥ مايو': '15th May',
    'Saft Al Laban - صفط اللبن': 'Saft Al Laban',
    'Bashtil - بشتيبل': 'Bashtil',
    'Al Barajil - البرجيل': 'Al Barajil',
    'El Waraaq - الوراق': 'El Waraaq',
    'Mivida - مافيدا': 'Mivida',
    'Mountain View - ماونتن فيو': 'Mountain View',
    '1st Settlement El Merage city - التجمع الأول الميراج': '1st Settlement El Merage city',
    'El Kilo 21 - الكيلو ٢١': 'El Kilo 21',
    'El Bitash Qbli - البيطاش قبلي': 'El Bitash Qbli',
    'El Soyof Qibli - السيوف قبلي': 'El Soyof Qibli',
    'Cairo University - جامعة القاهرة': 'Cairo University',
    'Al Bahr Al Aazam - البحر الأعظم': 'Al Bahr Al Aazam',
    'Al Kenisah - الكنيسة': 'Al Kenisah',
    'Sakaraa - سقارة': 'Sakaraa',
    'Khatem El Morsaleen - خاتم المرسلين': 'Khatem El Morsaleen',
    'Maryotia - المريوطية': 'Maryotia',
    'Rimaya - الرماية': 'Rimaya',
    'Eltalbia - الطالبية': 'Eltalbia',
    'El Omraniya - العمرانية': 'El Omraniya',
    'Elmoneeb - المنيب': 'Elmoneeb',
    'Heliopolis New Nozha - مصر الجديدة النزهة الجديدة': 'Heliopolis New Nozha',
    'Heliopolis Almaza - مصر الجديدة الماظة': 'Heliopolis Almaza',
    'Heliopolis Elhegaz - مصر الجديدة الحجاز': 'Heliopolis Elhegaz',
    'Heliopolis Ard El Golf - مصر الجديدة أرض الجولف': 'Heliopolis Ard El Golf',
    'Heliopolis Manshet Elbakry - مصر الجديدة منشية البكري': 'Heliopolis Manshet Elbakry',
    'Heliopolis Roxy - مصر الجديدة روكسي': 'Heliopolis Roxy',
    'Maadi Ridge - المعادي كورنيش المعادي': 'Maadi Ridge',
    'New Maadi - المعادي الجديدة': 'New Maadi',
    'Maadi Sakr Korish - المعادي صقر قريش': 'Maadi Sakr Korish',
    'Maadi Elbasateen - المعادي البساتين': 'Maadi Elbasateen',
    'Maadi Dar Elsalam - المعادي دار السلام': 'Maadi Dar Elsalam',
    'Maadi Sakanat - ثكنات المعادي': 'Maadi Sakanat',
    'Maadi Degla - المعادي دجلة': 'Maadi Degla',
    'Maadi Ellaselky - المعادي اللاسلكي': 'Maadi Ellaselky',
    'Maadi Elmaaraj - المعادي المعراج': 'Maadi Elmaaraj',
    'Maadi Zahraa - المعادي الزهراء': 'المعادي Maadi Zahraa',
    'Nasr City El Ahly Club - مدينة نصر النادي الأهلي': 'Nasr City El Ahly Club',
    'Nasr City Masakin Aldubbat - مدينة نصر مساكن الظباط': 'Nasr City Masakin Aldubbat',
    'Nasr City Alwaha - مدينة نصر الواحة': 'Nasr City Alwaha',
    'Nasr City 8th District - مدينة نصر الحى الثامن': 'Nasr City 8th District',
    'Zahraa Nasr City - زهراء مدينة نصر': 'Zahraa Nasr City',
    'Nasr City 10th District - مدينة نصر الحي العاشر': 'Nasr City 10th District',
    'Nasr City Arab Contractors - مدينة نصر المقاولين العرب': 'Nasr City Arab Contractors',
    'Nasr City Elwafa w Alamal - مدينة نصر الوفاء والأمل': 'Nasr City Elwafa w Alamal',
    'Nasr City Aljabal Alahmar - مدينة نصر الجبل الأحمر': 'Nasr City Aljabal Alahmar',
    'Nasr City 7th District - مدينة نصر الحى السابع': 'Nasr City 7th District',
    'Nasr City Abbas ElAkkad - مدينة نصر عباس العقاد': 'Nasr City Abbas ElAkkad',
    'Nasr City Emtedad Ramsis - مدينة نصر امتداد رمسيس': 'Nasr City Emtedad Ramsis',
    'Monshaat Naser - منشية ناصر': 'Monshaat Naser',
    'Nasr City Rabaa - مدينة نصر رابعة': 'Nasr City Rabaa',
    'Nasr City Al Azhar University - مدينة نصر جامعة الأزهر': 'Nasr City Al Azhar University',
    'Nasr City 6th District - مدينة نصر الحي السادس': 'Nasr City 6th District',
    'Faisal District - فيصل': 'Faisal District',
    'El Ganaien District - الجناين': 'El Ganaien District',
    'El Arbeen District - الأربعين': 'El Arbeen District',
    'El Salam District - السلام': 'El Salam District',
    'Ataka District - عتاقة': 'Ataka District',
    'Al-Adabya - العدبية': 'Al-Adabya',
    'Al Suez District - منطقة السويس': 'Al Suez District',
    'Abu Zenima - أبو زنيمة': 'Abu Zenima',
    'Abu Rudeis - أبو رديس': 'Abu Rudeis',
    'Sarabium - سرابيوم': 'Sarabium',
    'Qantara Shark - قنطرة شرق': 'Qantara Shark',
    'Qantara Gharab - قنطرة غرب': 'Qantara Gharab',
    'Kilo 11 - الكيلو ١١': 'Kilo 11',
    'Hay 3 - الحي الثالث': 'Hay 3',
    'Hay 2 - الحي الثاني': 'Hay 2',
    'Hay 1 - الحي الأول': 'Hay 1',
    'Fayed - فايد': 'Fayed',
    'Saleheya Al gadida - الصالحية الجديدة': 'Saleheya Al gadida',
    'El Kasasin - القصاصين': 'El Kasasin',
    'Al Tall Al kabir - التل الكبير': 'Al Tall Al kabir',
    'Abu Sultan - أبو سلطان': 'Abu Sultan',
    'Abu Soweir - أبو صوير': 'Abu Soweir',
    'Abu Khalifah - أبو خليفة': 'Abu Khalifah',
    'Abu Atwa - أبو عطوة': 'Abu Atwa',
    'Samsta - سمسطا': 'Samsta',
    'New Beni Suef - بني سويف الجديدة': 'New Beni Suef',
    'Nasser - ناصر': 'Nasser',
    'Ihnasya - إهناسيا': 'Ihnasya',
    'Elwasta - الواسطى': 'Elwasta',
    'Elfashn - الفشن': 'Elfashn',
    'Biba - ببا': 'Biba',
    'Yousef Elsedeq - يوسف الصديق': 'Yousef Elsedeq',
    'Tamya - طمية': 'Tamya',
    'Ebsheway - ابشواي': 'Ebsheway',
    'Atsa - اطسا': 'Atsa',
    'New Assuit - أسيوط الجديدة': 'New Assuit',
    'Balteem - بلطيم': 'Balteem',
    'Victoria - فكتوريا': 'Victoria',
    'Sidi Bishr Bahary - سيدي بشر بحري': 'Sidi Bishr Bahary',
    'Miami - ميامي': 'Miami',
    'El Soyof Bahary - السيوف بحري': 'El Soyof Bahary',
    'Mandara Bahary - المندرة بحري': 'Mandara Bahary',
    'El Asafra Bahary - العصافرة بحري': 'El Asafra Bahary',
    'Abu Kir - أبوقير': 'Abu Kir',
    'Zezenia - زيزينيا': 'Zezenia',
    'Tharwat - ثروت': 'Tharwat',
    'Stanley - ستانلي': 'Stanley',
    'Sporting - سبورتنج': 'Sporting',
    'Smouha - سموحة': 'Smouha',
    'Sidi Gaber - سيدي جابر': 'Sidi Gaber',
    'San Stefano - سان ستيفانو': 'San Stefano',
    'Saba Basha - سابا باشا': 'Saba Basha',
    'Roshdy - رشدي': 'Roshdy',
    'Louran - لوران': 'Louran',
    'Kafr Abdu - كفر عبده': 'Kafr Abdu',
    'Glim - جليم': 'Glim',
    'Gianaclis - جناكلس': 'Gianaclis',
    'Fleming - فليمنج': 'Fleming',
    'Cleopatra - كليوباترا': 'Cleopatra',
    'Camp Chezar - كامب شيزار': 'Camp Chezar',
    'Bolkly - بولكلي': 'Bolkly',
    'Bakos - باكوس': 'Bakos',
    'October - أكتوبر': 'October',
    'El Max - الماكس': 'El Max',
    'El Hanoveel - الهانوفيل': 'El Hanoveel',
    'El Dekhela - الدخيلة': 'El Dekhela',
    'El Bitash Bahree - البيطاش بحري': 'El Bitash Bahree',
    'Abou Youssef - أبو يوسف': 'Abou Youssef',
    'Agamy - العجمي': 'Agamy',
    'Sheikh Zayed Districts - أحياء الشيخ زايد': 'Sheikh Zayed Districts',
    'Beverly Hills - بيفرلى هيلز': 'Beverly Hills',
    'Casa Compound - كمبوند كازا زايد': 'Casa Compound',
    'El Rabwa Compound - كومباوند الربوة': 'El Rabwa Compound',
    'West Somid - غرب سوميد': 'West Somid',
    'Al Motameyz District - الحي المتميز': 'Al Motameyz District',
    'Oct Youth Residence - أكتوبر اسكان الشباب': 'Oct Youth Residence',
    'October Districts - أحياء السادس من أكتوبر': 'October Districts',
    'Al Bashayer District - البشاير': 'Al Bashayer District',
    '6th of October City - أكتوبر': '6th of October City',
    'Pyramids Gardens - حدايق الأهرام': 'Pyramids Gardens',
    'Dream Land - دريم لاند': 'Dream Land',
    'Pyramids Heights - مرتفعات الأهرام': 'Pyramids Heights',
    'Smart Village - القرية الذكية': 'Smart Village',
    'New Giza - نيو جيزة': 'New Giza',
    'Oct Alex Desert Road - أكتوبر الطريق الصحراوي': 'Oct Alex Desert Road',
    'Abu Rawash - أبورواش': 'Abu Rawash',
    'Heliopolis - مصر الجديدة': 'Heliopolis',
    'Sheraton - شيراتون': 'Sheraton',
    'Nasr City - مدينة نصر': 'Nasr City',
    'Ain Shams - عين شمس': 'Ain Shams',
    'Gesr Elsuez - جسر السويس': 'Gesr Elsuez',
    'El Zaytoun - الزيتون': 'El Zaytoun',
    'EL Fustat - الفسطاط': 'EL Fustat',
    'Maadi - المعادي': 'Maadi',
    'Helwan - حلوان': 'Helwan',
    'El Hadaba El Wosta - الهضبة الوسطي': 'El Hadaba El Wosta',
    'Shubra Masr - شبرا مصر': 'Shubra Masr',
    'Abbassia - العباسية': 'Abbassia',
    'El Marj - المرج': 'El Marj',
    'Imbaba - امبابة': 'Imbaba',
    'Hadayek El Haram - حدائق الأهرام': 'Hadayek El Haram',
    'Haram - الهرم': 'Haram',
    'Faisal - فيصل': 'Faisal',
    'Ard El Lewa - أرض اللوا': 'Ard El Lewa',
    'Mit Akaba - ميت عقبة': 'Mit Akaba',
    'Mohandeseen - المهندسين': 'Mohandeseen',
    'Dokki - الدقي': 'Dokki',
    'Agouza - العجوزة': 'Agouza',
    'Bulaq - بولاق': 'Bulaq',
    'Abdeen - عابدين': 'Abdeen',
    'Zamalek - الزمالك': 'Zamalek',
    'Garden City - جاردن سيتي': 'Garden City',
    'Downtown Cairo - وسط البلد': 'Downtown Cairo',
    'Ahmed Oraby Association - جمعية أحمد عرابي': 'Ahmed Oraby Association',
    'Badr City - مدينة بدر': 'Badr City',
    'New Heliopolis City - هيلوبوليس الجديدة': 'New Heliopolis City',
    'El Talae3 City - مدينة الطلائع': 'El Talae3 City',
    'Obour - العبور': 'Obour',
    'Madinaty - مدينتي': 'Madinaty',
    'Future City - مدينة المستقبل': 'Future City',
    '10th of Ramadan - العاشر من رمضان': '10th of Ramadan',
    'Abo El Houl - أبو الهول': 'Abo El Houl',
    'Area 3 - المنطقة الثالثة': 'Area 3',
    '5th Settlement Industrial Area - المنطقة الصناعية التجمع الخامس': '5th Settlement Industrial Area',
    'El Narges 8 - النرجس ٨': 'El Narges 8',
    'El Narges 7 - النرجس ٧': 'El Narges 7',
    'El Narges 6 - النرجس ٦': 'El Narges 6',
    'El Narges 5 - النرجس ٥': 'El Narges 5',
    'El Narges 4 - النرجس ٤': 'El Narges 4',
    'El Narges 3 - النرجس ٣': 'El Narges 3',
    'El Narges 2 - النرجس ٢': 'El Narges 2',
    'El Narges - النرجس': 'El Narges',
    'El Lotus - اللوتس': 'El Lotus',
    'El Andalous - الأندلس': 'El Andalous',
    'North Investors Area - منطقة المسثمرين الشمالية': 'North Investors Area',
    'South Investors Area - منطقة المستثمرين الجنوبية': 'South Investors Area',
    'Ganoob Al Academia - Area F - جنوب الاكاديمية': 'Ganoob Al Academia - Area F',
    'Ganoob Al Academia - Area E - جنوب الاكاديمية': 'Ganoob Al Academia - Area E',
    'Ganoob Al Academia - Area D - جنوب الاكاديمية': 'Ganoob Al Academia - Area D',
    'Ganoob Al Academia - Area C - جنوب الاكاديمية': 'Ganoob Al Academia - Area C',
    'Ganoob Al Academia - Area B - جنوب الاكاديمية': 'Ganoob Al Academia - Area B',
    'Ganoob Al Academia - جنوب الاكاديمية': 'Ganoob Al Academia',
    'Sharq El Academia - شرق الأكاديمية': 'شرق Sharq El Academia',
    'Cairo Festival City - كايرو فستيفال سيتي': 'Cairo Festival City',
    '1st Settlement - مجاورات التجمع الأول': '1st Settlement',
    '1st Settlement El Nakhil - التجمع الأول النخيل': '1st Settlement El Nakhil',
    'El Banafseg 12 - البنفسج ١٢': 'El Banafseg 12',
    'El Banafseg 11 - البنفسج ١١': 'El Banafseg 11',
    'El Banafseg 10 - البنفسج ١٠': 'El Banafseg 10',
    'El Banafseg 9 - البنفسج ٩': 'El Banafseg 9',
    'El Banafseg 8 - البنفسج ٨': 'El Banafseg 8',
    'El Banafseg 7 - البنفسج ٧': 'El Banafseg 7',
    'El Banafseg 6 - البنفسج ٦': 'El Banafseg 6',
    'El Banafseg 5 - البنفسج ٥': 'El Banafseg 5',
    'El Banafseg 4 - البنفسج ٤': 'El Banafseg 4',
    'El Banafseg 3 - البنفسج ٣': 'El Banafseg 3',
    'El Banafseg 2 - البنفسج ٢': 'El Banafseg 2',
    'El Banafseg - البنفسج': 'El Banafseg',
    'El Yasmeen 8 - الياسمين ٨': 'El Yasmeen 8',
    'El Yasmeen 7 - الياسمين ٧': 'El Yasmeen 7',
    'El Yasmeen 4 - الياسمين ٦': 'El Yasmeen 4',
    'El Yasmeen 4 - الياسمين ٥': 'El Yasmeen 4',
    'El Yasmeen 4 - الياسمين ٤': 'El Yasmeen 4',
    'El Yasmeen 3 - الياسمين ٣': 'El Yasmeen 3',
    'El Yasmeen 2 - الياسمين ٢': 'El Yasmeen 2',
    'El Yasmeen - الياسمين': 'El Yasmeen',
    'El Rehab - الرحاب': 'El Rehab',
    'Gharb El Golf - غرب الجولف': 'Gharb El Golf',
    'Arabella - ارابيلا': 'Arabella',
    'El Kattameya - القطامية': 'El Kattameya',
    'El Diplomasyeen - الدبلوماسيين': 'El Diplomasyeen',
    'El Shouyfat - الشويفات': 'El Shouyfat',
    '5th Settlement - التجمع الخامس': '5th Settlement',
    '3rd Settlement - التجمع الثالث': '3rd Settlement',
    'الشرقية - Sharkia': 'Sharkia',
    'Sharkia - الشرقية': 'Sharkia',
    'بورسعيد - Portsaid': 'Portsaid',
    'دمياط - Damietta': 'Damietta',
    'الدقهلية - Al-Dakahlia': 'Al-Dakahlia',
    'cannot copy!': 'Cannot copy!',
    'Last Date for Delivery': 'Last Date for Delivery',
    'Order Reference No.': 'Order Reference No.',
    '1st': '1st',
    '2nd': '2nd',
    '3rd': '3rd',
    'tracking order': 'tracking order',
    'View Detailed Timeline': 'View Detailed Timeline',
    'Detailed Timeline': 'Detailed Timeline',
    'Packages Info': 'Packages Info',
    'Supplies type': 'Supplies type',
    'Opening Package': 'Opening Package',
    'Return Point': 'Return Point',
    'Apply': 'Apply',
    'Apply Promo Code': 'Apply Promo Code',
    'Packing Task Lists': 'Packing Task Lists',
    'Picking Task Lists': 'Picking Task Lists',
    'Packing Tasklists': 'Packing Tasklists',
    'pickup requested': 'Pickup Requested',
    'created': 'Created',
    'picked up': 'Picked Up', 
    'in transit': 'In Transit',
    'out for delivery': 'Out For Delivery',
    'out for pickup': 'Out For Pickup',
    'pickup rescheduled': 'Pickup Rescheduled',
    'Pickup rescheduled': 'Pickup rescheduled',
    'rescheduled': 'Rescheduled',
    'delivery attempted': 'Delivery Attempted',
    'return to origin': 'Return To Origin',
    'out for return': 'Out For Return',
    'return rescheduled': 'Return Rescheduled',
    'rto requested':'rto requested',
    'fulfillment':'fulfillment',
    'delivered':'delivered',
    'on hold': 'On Hold',
    'In Transit (NC)': 'In Transit (NC)',
    'In Transit (DW)': 'In Transit (DW)',
    'In Transit (OCT)': 'In Transit (OCT)',
    'In Transit (ALX)': 'In Transit (ALX)',
    'In Transit (MNS)': 'In Transit (MNS)',
    'In Transit (SYT)': 'In Transit (SYT)',
    'In Transit (MNF)': 'In Transit (MNF)',
    'In Transit (TNT)': 'In Transit (TNT)',
    'In Transit (SHR)': 'In Transit (SHR)',
    'In Transit (GSZ)': 'In Transit (GSZ)',
    'cancelled': 'Cancelled',
    'Cancel Order': 'Cancel',
    'Restart': 'Restart',
    'Copied': 'Copied',
    'expected delivery date': 'expected delivery date',
    'Allow open packages': 'Allow open packages',
    'Additional Package': 'Additional Package',
    'Supplies Shop': 'Supplies Shop',
    'Supplies Orders': 'Supplies Orders',
    'Flyers': 'Flyers',
    'Boxes': 'Boxes',
    'Other Items': 'Other Items',
    'Cart': 'Cart',
    'Your cart is empty': 'Your cart is empty',
    'Add items to your cart': 'Add items to your cart',
    'Large Box': 'صندوق كبير',
    'Small Box': 'صندوق صغير',
    'Medium Box': 'صندوق متوسط',
    'Try Beta': 'Try Beta',
    'Stable Release': 'Stable Release',
    'Free': 'Free',
    'Items': 'Items',
    'QTY': 'QTY',
    'Small Flyer': 'Small Flyer',
    'Medium Flyer': 'Medium Flyer',
    'Large Flyer': 'Large Flyer',
    'Extra Large Flyer': 'Extra Large Flyer',
    'Bubble Wrap': 'Bubble Wrap',
    'Stretch Wrap': 'Stretch Wrap',
    'Fragile Stickers': 'Fragile Stickers',
    'Packing Tape': 'Packing Tape',
    'Security Stickers': 'Security Stickers',
    'Check out': 'Check out',
    'Assigned Merchants': 'Assigned Merchants',
    'Our new': 'Our new',
    'technology is now verifying delivery attempts. Our system will automatically contact the customer to confirm the attempt reason provided by the courier. This ensures accurate and reliable information, improving our service and your experience.': 'technology is now verifying delivery attempts. Our system will automatically contact the customer to confirm the attempt reason provided by the courier. This ensures accurate and reliable information, improving our service and your experience.',
    'Got It': 'Got It',
    'ShipBlu Verify™ contacted the customer, and the delivery attempt was confirmed.': 'ShipBlu Verify™ contacted the customer, and the delivery attempt was confirmed.',
    'Our new ShipBlu Verify™ technology is now verifying delivery attempts. Our system will automatically contact the customer to confirm the attempt reason provided by the courier. This ensures accurate and reliable information, improving our service and your experience.': 'Our new ShipBlu Verify™ technology is now verifying delivery attempts. Our system will automatically contact the customer to confirm the attempt reason provided by the courier. This ensures accurate and reliable information, improving our service and your experience.',
    'ShipBlu Verify™ contacted the customer, but the customer did not pick up the phone.': 'ShipBlu Verify™ contacted the customer, but the customer did not pick up the phone.',
    'ShipBlu Verify™ contacted the customer, and the delivery attempt was not confirmed.': 'ShipBlu Verify™ contacted the customer, and the delivery attempt was not confirmed.',
    'All successful data has been sent, and failed data has been downloaded into another file.': 'All successful data has been sent, and failed data has been downloaded into another file.',
    'All SMS has been added successfully!': 'All SMS has been added successfully!',
    'Outgoing Messages': 'Outgoing Messages',
    'External Links': 'External Links',
    'Send Individual Message': 'Send Individual Message',
    'Upload Multiple SMS': 'Upload Multiple SMS',
    'ShipBlu Shield fees will be applied on this amount if it is provided.\n This amount will not be shown to your customer.': 'ShipBlu Shield fees will be applied on this amount if it is provided.\n This amount will not be shown to your customer.',
    'Pickup from your location': 'Pickup from your location',
    'Select your pickup Location:': 'Select your pickup Location:',
    'More Information': 'More Information',
    'Full Address': 'Full Address',
    'Packages Details': 'Packages Details',
    'Number of Packages': 'Number of Packages',
    'This is the amount we will collect from your customer': 'This is the amount we will collect from your customer',
    'Your Delivery Notes...': 'Your Delivery Notes...',
    'Add your delivery notes here': 'Add your delivery notes here',
    'Add Package Description...': 'Add Package Description...',
    'The number of flyers or boxes': 'The number of flyers or boxes',
    'Wrong File!': 'Wrong File!',
    'Legacy Upload': 'Legacy Upload',
    'For help and guidance on how to upload multiple orders, check out our ': 'For help and guidance on how to upload multiple orders, check out our ',
    'knowledge center': 'knowledge center',
    'Click To Upload': 'Click To Upload',
    'Supports files of .xls and .xlsx formats': 'Supports files of .xls and .xlsx formats',
    'Upload File': 'Upload File',
    'Fill Manually': 'Fill Manually',
    'All added orders are with': 'All added orders are with',
    'Are you sure you want to proceed?': 'Are you sure you want to proceed?',
    'Merchant Reference Number': 'Merchant Reference Number',
    'Secondary Phone': 'Secondary Phone',
    'Line 2': 'Line 2',
    'Optional columns': 'Optional columns',
    'Allow all packages to be opened': 'Allow all packages to be opened',
    'Open package': 'Open package',
    'Add Extra Rows': 'Add Extra Rows',
    'Discount Code': 'Discount Code',
    'Products Declared Value': 'Products Declared Value',
    'Counter Drop Off': 'Counter DropOff',
    'Number of packages': 'Number of packages',
    'We will auto-select the Governorate, City & Zone values based on the “Address” field you fill': 'We will auto-select the Governorate, City & Zone values based on the “Address” field you fill',
    'Line 1': 'Line 1',
    'or drag and drop your orders sheet': 'or drag and drop your orders sheet',
    'Mark all orders as Fragile': 'Mark all orders as Fragile',
    'Successful orders': 'Successful orders',
    'Unsuccessful orders': 'Unsuccessful orders',
    'Replace File': 'Replace File',
    'Address Line 2': 'Address Line 2',
    'Choose this option if you will drop the package to the nearest ShipBlu branch': 'Choose this option if you will drop the package to the nearest ShipBlu branch',
    'Are you sure to remove this row?': 'Are you sure to remove this row?',
    'For help and guidance on how to pack your orders, check out our':'For help and guidance on how to pack your orders, check out our',
    'packaging guidelines':'packaging guidelines',
    'our': '',
    'We are constantly expanding our shipping coverage. Meanwhile, please check the current ': 'We are constantly expanding our shipping coverage. Meanwhile, please check the current ',
    'uncovered zones': 'uncovered zones',
    'NOW': '',
    'Add your order reference No.': 'Add your order reference No.',
    'We will auto select the Governorate ,City & Zone based on what you filled in the “Full Address” field': 'We will auto select the Governorate ,City & Zone based on what you filled in the “Full Address” field',
    'The easiest way to find and share an exact location by sharing its W3words': 'The easiest way to find and share an exact location by sharing its W3words',
    'Choose this option if you will drop the Package to the nearest ShipBlu Branch': 'Choose this option if you will drop the Package to the nearest ShipBlu Branch',
    'Print Returns': 'Print Returns',
    'Move to Another Task List': 'Move to Another Task List',
    'This amount will be paid to your customer.': 'This amount will be paid to your customer.',
    'Refund orders will only be attempted if there are sufficient funds in your account.': 'Refund orders will only be attempted if there are sufficient funds in your account.',
    'Mokatam Warehouse': 'Mokatam Warehouse',
    'Cash already collected or some orders are still with agent. Refresh the page.': 'Cash already collected or some orders are still with agent. Refresh the page.',
    'Please, confirm you collected': 'Please, confirm you collected',
    '. Type in the number below!': '. Type in the number below!',
    'Are you sure to empty this slot?': 'Are you sure to empty this slot?',
    'Are you sure to delete this package?': 'Are you sure to delete this package?',
    'Packages IDs': 'Packages IDs',
    'due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.': 'due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.',
    'Cancel Supplies Request': 'Cancel Supplies Request',
    'Download auto-zoning template': 'Download auto-zoning template',
    'New Order': 'New Order',
    'Please, Scan all packaes in the order first!':'Please, Scan all packaes in the order first!',
    'Your Locations':'Your Locations',
    'attempted':'Attempted',
    'Start Shipping!': 'Start Shipping!',
    'One per page': 'One per page',
    'Expired': 'Expired',
    'New': 'New',
    'App Notifications': 'App Notifications',
    'pending payment': 'pending payment',
    'expired': 'expired',
    'requested': 'requested',
    'Payment Status': 'Payment Status',
    'Pay': 'Pay',
    'successful': 'successful',
    'failed': 'failed',
    'Fees': 'Fees',
    'pending': 'pending',
    'Price Details': 'Price Details',
    'Payment Method': 'Payment Method',
    'Payment Type': 'Payment Type',
    'Your Order has been confirmed': 'Your Order has been confirmed',
    'Select one of your locations': 'Select one of your locations',
    'Terms of Service': 'Terms of Service',
    'I have read and agree to the Terms and Conditions.': 'I have read and agree to the Terms and Conditions.',
    'Accept': 'Accept',
    'Decline': 'Decline',
    'Autozoning Upload': 'Autozoning Upload',
    'Shipping Fees': 'Shipping Fees',
    'FC Receiving Agent': 'FC Receiving Agent',
    'FC Inbounding Agent': 'FC Inbounding Agent',
    'FC Storing Agent': 'FC Storing Agent',
    'FC Packing Agent': 'FC Packing Agent',
    'FC Picking Agent': 'FC Picking Agent',
    'submitted': 'Submitted',
    'Ready': 'Ready',
    'received': 'Received',
    'inbounded': 'Inbounded',
    'stored': 'Stored',
    'Cancelled': 'Cancelled',
    'partially received': 'partially received',
    'resolved': 'Resolved',
    'processing': 'Processing',
    'confirmed to ship': 'Confirmed to ship',
    'picking': 'Picking',
    'packing': 'Packing',
    'shipped': 'Shipped',
    'completed': 'Completed',
    'failed to receive': 'Failed to receive',
    'Edit Bins': 'Edit Bins',
    'Add Bins': 'Add Bins',
    'Edit Bin': 'Edit Bin',
    'Add Bin': 'Add Bin',
    'Delete Bin': 'Delete Bin',
    'No. Products': 'No. Products',
    'Storage Units': 'Storage Units',
    'Tote': 'Tote',
    'Box': 'Box',
    'Pallet': 'Pallet',
    'Shelf': 'Shelf',
    'Picking List': 'Picking List',
    'Packing List': 'Packing List',
    'new': 'New',
    'single': 'Single',
    'ready to ship': 'ready to ship',
    'failed to fulfill': 'Failed to Fulfill',
    'Sign Up': 'Start shipping with ShipBlu in one step',
    'Balance Due': 'Balance Due',
    'Cash Collection Balance': 'Cash Collection Balance',
    'Payout': 'Payout',
    'Mark Reviewed': 'Mark Reviewed',
    'Are you sure you want to mark': 'Are you sure you want to mark',
    'statements as reviewed?': 'statements as reviewed?',
    'Please, select issued statements.': 'Please, select issued statements.',
    'ready': 'Ready',
    'reviewed': 'Reviewed',
    'Estimated Pickup Date': 'Estimated Pickup Date',
    'Preferred Pickup Date': 'Preferred Pickup Date',
    'Last Date for Pickup': 'Last Date for Pickup',
    'Pickup Window': 'Pickup Window',
    'Cannot generate a shipping label for unzoned orders.': 'Cannot generate a shipping label for unzoned orders.',
    ' EGP': ' EGP',
    'Select your payment method.': 'Select your payment method.',
    'Pending':'Pending',
    'Re-zoning': 'Re-zoning',
    'Address Line 1' : 'Address Line 1',
    'Your order has been confirmed successfully!' : 'Your order has been confirmed successfully!',
    'Shipment doesn\'t follow the packaging guidelines' : 'Shipment doesn\'t follow the packaging guidelines',
    'Here you can view all your delivery orders.' : 'Here you can view all your delivery orders.',
    'You can also filter by their status' : 'You can also filter by their status',
    'If your profile is reviewed and verified, you can request pickup here.' : 'Once your profile is reviewed and verified, you can request pickup here.',
    'You may request flyers or buy shipping boxes and supplies from here.' : 'You may request flyers or buy shipping boxes and supplies from here.',
    'Click here to add a payout method, know when you will get your COD and explore your pricing plan.' : 'Click here to add a payout method, know when you will get your COD and explore your pricing plan.',
    'If you have larger monthly volume of shipping, contact our sales team to receive discounts. Thank you for choosing ShipBlu. We look forward to serve you!' : 'Thank you for choosing ShipBlu. We look forward to serve you!<br>If you have larger monthly volume of shipping, contact our sales team to receive discounts.',
    'Dashboard overview.' : 'Dashboard overview.',
    'Click here to open the creation options' : 'Click here to open the creation options',
    'Click here to create a new delivery order.' : 'Click here to create a new delivery order.',
    'Now, add customer details below' : 'Now, add customer details below',
    'Great! Now, select the number of packages and optionally add description and cash on delivery amount.' : 'Great! Now, select the number of packages and optionally add description and cash on delivery amount.',
    'Optionally, add a reference number for the order and leave any note for our delivery agent.' : 'Optionally, add a reference number for the order and leave any note for our delivery agent.',
    'Your are now one-step ahead from creating your first order. Click Create Order now!' : 'Your are now one-step ahead from creating your first order. Click Create Order now!',
    'Now, print your shipping label' : 'Now, print your shipping label',
    '<br>1- Create a new delivery order <br> 2- Request a return from a customer <br> 3- Request to exchange an order that we already delivered<br> 4- Create a new cash collection request<br> 5- Request supplies<br> 6- Batch-upload delivery orders from an excel sheet': '<br>1- Create a new delivery order <br> 2- Request a return from a customer <br> 3- Request to exchange an order that we already delivered<br> 4- Create a new cash collection request<br> 5- Request supplies<br> 6- Batch-upload delivery orders from an excel sheet',
    'Your orders got delivered successfully? Find out how to add a <b>payout method</b> to receive the collected cash by following ': 'Your orders got delivered successfully? Find out how to add a <b>payout method</b> to receive the collected cash by following ',
    'Looking for enhanced protection for your shipments? Explore <b>ShipBlu Shield</b> Plans by following ': 'Looking for enhanced protection for your shipments? Explore <b>ShipBlu Shield</b> Plans by following ',
    'Seeking a seamless delivery experience for your shipments? Discover our <b>packaging guidelines</b> by following ': 'Seeking a seamless delivery experience for your shipments? Discover our <b>packaging guidelines</b> by following ',
    'We are making efforts in expanding our network of covered zones. Stay updated on the current list of <b>uncovered zones</b> and areas by following ': 'We are making efforts in expanding our network of covered zones. Stay updated on the current list of <b>uncovered zones</b> and areas by following ',
    'this link': 'this link',
    'had an attempt:': 'had an attempt:',
    'Hello': 'Hello',
    'Delivery success rate': 'Delivery success rate',
    'Financial Center': 'Financial Center',
    'Service Fees': 'Service Fees',
    'Last Payout': 'Last Payout',
    'Notification Center': 'Notification Center',
    'Pickups this month': 'Pickups this month',
    'Returns this month': 'Returns this month',
    'You are on the': 'You are on the',
    'Entrepreneur': 'Entrepreneur',
    'Entrprise': 'Entrprise',
    ' pricing plan. You receive special discounts once you ship ': ' pricing plan. You receive special discounts once you ship ',
    ' order in a month.': ' order in a month.',
    'Average Shipping Fees': 'Average Shipping Fees',
    'Your dashboard gives you quick insights about your logistics needs.' : 'Your dashboard gives you quick insights about your logistics needs.',
    'Congratulations! Your account is now verified and you can request a pickup for your orders. Pickups requested before': 'Congratulations! Your account is now verified and you can request a pickup for your orders. Pickups requested before',
    'will be picked on the same day.': 'will be picked on the same day.',
    'Thank you for signing up with us! Your account is currently under review and requesting pickup is disabled. Reviews are typically complete with 1-2 business days. If you want to ship your orders today, you are welcome to drop off your packages at': 'Thank you for signing up with us! Your account is currently under review and requesting pickup is disabled. Reviews are typically complete with 1-2 business days. If you want to ship your orders today, you are welcome to drop off your packages at',
    'one of our locations': 'one of our locations',
    'and we will be happy to serve you.': 'and we will be happy to serve you.',
    'Phone number is not related to the customer': 'Phone number is not related to the customer',
    'Phone Number is not related to the customer': 'Phone Number is not related to the customer',
    'Wrong zone': 'Wrong zone',
    'Customer is avoiding the shipment': 'Customer is avoiding the shipment',
    'Customer wants to Change Address': 'Customer wants to Change Address',
    'Verification code is unavailable': 'Verification code is unavailable',
    'Customer doesn\'t have enough money': 'Customer doesn\'t have enough money',
    'Wrong COD': 'Wrong COD',
    'Are you sure you want to confirm these Counter Returns?': 'Are you sure you want to confirm these Counter Returns?',
    'Your merchant profile is incomplete. You can explore around with limited functionality.': 'Your merchant profile is incomplete. You can explore around with limited functionality.',
    'Click here': 'Click here',
    'to complete profile.': 'to complete profile.',
    'Your Location':'Your Location',
    'Let us help you create the perfect pricing plan tailored just for you. Get ready to take your business to the next level!': 'Let us help you create the perfect pricing plan tailored just for you. Get ready to take your business to the next level!',
    'Your Company Name "will be displayed on the shipping label"': 'Your Company Name "will be displayed on the shipping label"',
    'Your Avg. Monthly Volume': 'Your Avg. Monthly Volume',
    'URL Link (Website, IG Page, FB Page... etc)': 'URL Link (Website, IG Page, FB Page... etc)',
    'Industry': 'Industry',
    'Industries': 'Industries',
    'Get Started': 'Get Started',
    'Company Name': 'Company Name',
    'Are you sure to cancel these orders?': 'Are you sure to cancel these orders?',
    'password must contain': 'Password must contain',
    'At least one lowercase letter': 'At least one lowercase letter',
    'At least one uppercase letter': 'At least one uppercase letter',
    'At least one number': 'At least one number',
    'Minimum 8 characters': 'Minimum 8 characters',
    'Mixed': 'Mixed',
    'Coffee, Beans, Oil, Honey & Herbs': 'Coffee, Beans, Oil, Honey & Herbs',
    'Accessories': 'Accessories',
    'Mobile & Laptop Accessories': 'Mobile & Laptop Accessories',
    'Home Accessories': 'Home Accessories',
    'Apparel, Fashion and Clothes': 'Apparel, Fashion and Clothes',
    'Pharmaceutical Medicine, and supplements': 'Pharmaceutical Medicine, and supplements',
    'Beauty Cosmetics, Perfumes & Personal Care': 'Beauty Cosmetics, Perfumes & Personal Care',
    'Detergent and Cleaners': 'Detergent and Cleaners',
    'Jewelry & Expensive Collectibles': 'Jewelry & Expensive Collectibles',
    'Car & Motorcycle Accessories': 'Car & Motorcycle Accessories',
    'Electronics': 'Electronics',
    'Gifts & Printed Materials': 'Gifts & Printed Materials',
    'Eyeglasses and Contact Lenses': 'Eyeglasses and Contact Lenses',
    'Vending, Coffee & Food Machines': 'Vending, Coffee & Food Machines',
    'Books': 'Books',
    'Pet Supplies & Accessories': 'Pet Supplies & Accessories',
    'Bags, Packs, Bottles & Flasks': 'Bags, Packs, Bottles & Flasks',
    'Documents': 'Documents',
    'Handmade Products': 'Handmade Products',
    'Shortfall': 'Shortfall',
    'Agents Transactions': 'Agents Transactions',
    'Full Amount': 'Full Amount',
    'partial Amount': 'partial Amount',
    'No Show': 'No Show',
    'Please, choose one of the following actions before completing the task list': 'Please, choose one of the following actions before completing the task list',
    'Settle Shortfall': 'Settle Shortfall',
    'Your Latest Statement': 'Your Latest Statement',
    'This section will be revised according to your next statement. "Cash on the way" refers to the specific amount of money that will be transferred to you.': 'This section will be revised according to your next statement. "Cash on the way" refers to the specific amount of money that will be transferred to you.',
    'Cash on the way ': 'Cash on the way ',
    'Cash Collection Balance ': 'Cash Collection Balance ',
    'Service Fees Due': 'Service Fees Due',
    'Amount On Hold': 'Amount On Hold',
    'Top Up': 'Top Up',
    'Deposit any amount into your account, fueling supplies requests and ensuring smooth customer refunds.': 'Deposit any amount into your account, fueling supplies requests and ensuring smooth customer refunds.',
    'Transactions History': 'Transactions History',
    'Deposit': 'Deposit',
    'all': 'all',
    'deposit': 'deposit',
    'payout': 'payout', 
    'refunds': 'refunds',
    'supplies': 'supplies',
    'transaction supplies request': 'supplies request',
    'transaction cash collected': 'cash collected',
    'transaction deposit': 'deposit',
    'transaction payout': 'payout',
    'transaction refunds': 'refunds',
    'Debit/Credit Card Top-up': 'Debit/Credit Card Top-up',
    'Bank Transfer Top-up': 'Bank Transfer Top-up',
    'Transfers must be from a bank account under your name.': 'Transfers must be from a bank account under your name.',
    'Please allow up to 2 business days to process your top-up request with Instapay.': 'Please allow up to 2 business days to process your top-up request with Instapay.',
    'Deposit Date:': 'Deposit Date:',
    'Deposited Amount:': 'Deposited Amount:',
    'Transaction Reference Number:': 'Transaction Reference Number:',
    'Confirm Deposit': 'Confirm Deposit',
    'Your request to deposit an amount of EGP': 'Your request to deposit an amount of EGP',
    'has been received and your account will be updated within 2 Business Days.': 'has been received and your account will be updated within 2 Business Days.',
    'Top-up Method': 'Top-up Method',
    'How much to top-up with?': 'How much to top-up with?',
    'Debit/Credit Card': 'Debit/Credit Card',
    'Bank Transfer': 'Bank Transfer',
    'Account Number (EGP)': 'Account Number (EGP)',
    'Beneficiary Name': 'Beneficiary Name',
    'Open the Instapay app and make a transfer with using these details.': 'Open the Instapay app and make a transfer with using these details.',
    'ShipBlu does not have a payment address on Instapay, please make sure to use the account number for transfers.': 'ShipBlu does not have a payment address on Instapay, please make sure to use the account number for transfers.',
    'Sent a pickup request. You will receive a notification once done.': 'Sent a pickup request. You will receive a notification once done.',
    'This score represents the average BluScore of your picked orders.': 'This score represents the average BluScore of your picked orders.',
    'Return to your location': 'Return to your location',
    'Choose this option if you will get the Return Package from the ShipBlu warehouse': 'Choose this option if you will get the Return Package from the ShipBlu warehouse',
    'Upload Receipt': 'Upload Receipt',
    'Processing Fees': 'Processing Fees',
    'we will generate orders label and sent to you notification after finish it to download it.': 'we will generate orders label and sent to you notification after finish it to download it.',
    'All Selected': 'Filters',
    'Pickup requested': 'Pickup requested',
    'Out for return': 'Out for return',
    'in transit & en route': 'in transit & en route',
    'Total: ': 'Total: ',
    'By choosing "Cancel & Return", you acknowledge that you have communicated with your customer and verified their decision not to proceed with the order. Upon this confirmation, our team will initiate the process to return your order.': 'By choosing "Cancel & Return", you acknowledge that you have communicated with your customer and verified their decision not to proceed with the order. Upon this confirmation, our team will initiate the process to return your order.',
    'Thank you for choosing': 'Thank you for choosing',
    'Ship':'Ship',
    'Blu': 'Blu',
    'as your eCommerce Shipping partner.' : 'as your eCommerce Shipping partner.',
    '1st Attempt Delivery Rate:': '1st Attempt Delivery Rate:',
    'Your Average Shipping Fees': 'Your Average Shipping Fees',
    'Ship more, save more on your shipments with ShipBlu.': 'Ship more, save more on your shipments with ShipBlu.',
    'Your Base Price:': 'Your Base Price:',
    'Access Price Plan': 'Access Price Plan',
    'Refunds': 'Refunds',
    'Amounts On Hold': 'Amounts On Hold',
    'Access Wallet': 'Access Wallet',
    'returned': 'returned',
    'Subscribe to ShipBlu notifications to get the important updates.': 'Subscribe to ShipBlu notifications to get the important updates.',
    'You can disable notifications anytime from your browser.': 'You can disable notifications anytime from your browser.',
    'Later': 'Later',
    'Allow': 'Allow',
    'Wallet Balance': 'Wallet Balance',
    'Expected Payout': 'Expected Payout',
    'Instant Payout': 'Instant Payout',
    'Your Gateway to Swift Cash Flow Awaits': 'Your Gateway to Swift Cash Flow Awaits',
    'Get ready to transform the way you access and withdraw your confirmed collected cash from Cash on Delivery (COD) orders. This is designed to empower you to reinvest in your business, manage your finances effectively, and stay ahead in the fast-paced world of eCommerce.': 'Get ready to transform the way you access and withdraw your confirmed collected cash from Cash on Delivery (COD) orders. This is designed to empower you to reinvest in your business, manage your finances effectively, and stay ahead in the fast-paced world of eCommerce.',
    'Refunds In Progress': 'Refunds In Progress',
    'confirmed to return': 'Confirmed to Return',
    'confirm to return': 'Confirm to Return',
    'Product(s) out of': 'Product(s) out of',
    'Are you sure you want to confirm storing' : 'Are you sure you want to confirm storing',
    'Others': 'Others',
    'Stored Quantity': 'Stored QTY',
    'on its way': 'on its way',
    'lost': 'lost',
    'damaged': 'damaged',
    'forfeited': 'forfeited',
    'Please contact support': 'Please contact support',
    'Please open the camera permission': 'Please open the camera permission',
    'Services in Progress': 'Services in Progress',
    'Available Cash Balance': 'Available Cash Balance',
    'ShipBlu Upfront': 'ShipBlu Upfront',
    'Action Center': 'Action Center',
    'Your Wallet Balance': 'Your Wallet Balance',
    'Please confirm your request to receive cash for your completed orders today.': 'Please confirm your request to receive cash for your completed orders today.',
    '80% of Available Cash Balance': '80% of Available Cash Balance',
    'Instant Payout Fees (1.5%)': 'Instant Payout Fees (1.5%)',
    'Receive your cash for completed orders today!': 'Receive your cash for completed orders today!',
    'You receive': 'You receive',
    'Instant Payouts': 'Instant Payouts',
    'The description should preferably be written in Arabic and include the products with their exact quantities.': 'The description should preferably be written in Arabic and include the products with their exact quantities.',
    'OPS Admins': 'OPS Admins',
    'OPS Admin': 'OPS Admin',
    'Add OPS Admin': 'Add OPS Admin',
    'Edit OPS Admin': 'Edit OPS Admin',
    'Create your account': 'Create your account',
    'Ship Smarter.. Grow Faster': 'Ship Smarter.. Grow Faster',
    'Must contain alphabets only': 'Must contain alphabets only',
    'Must be a valid email': 'Must be a valid email',
    'Must be a valid mobile number': 'Must be a valid mobile number',
    'At least one capital letter': 'At least one capital letter',
    'At least one small letter': 'At least one small letter',
    'At least one special characters:': 'At least one special character:',
    'Password must be at least 8 characters': 'At least 8 characters',
    'Passwords don’t match': 'Passwords don’t match',
    'Have an account? Sign in': 'Have an account? Sign in',
    'Your account is currently under review, you are welcome to drop off your packages at ' : 'Your account is currently under review, you are welcome to drop off your packages at ',
    ' until your profile is verified.': ' until your profile is verified',
    'Please contact support team to cancel this supply!': 'Please contact support team to cancel this supply!',
    'Payouts': 'Payouts',
    'Statement Payouts': 'Statement Payouts',
    'Upfront Payouts': 'Upfront Payouts',
    'Automated Codes': 'Automated Codes',
    'Order search': 'Order search'
  },
  ar: {
    'view more': 'عرض المزيد',
    'Orders Auto Processing': 'معالجة الطلبات تلقائيًا',
    'Automatic Pickup, Return, Collection Request': 'طلب استلام , استرجاع , تحصيل نقدي تلقائي',
    'No.of products': 'عدد المنتجات',
    'Shipment': 'طلب توصيل',
    'Dashboard': 'الصفحة الرئيسية',
    'Operations': 'العمليات',
    'Orders': 'الطلبات',
    'Support': 'الدعم',
    'Accounts': 'الحسابات',
    'Account': 'الحساب',
    'Merchants': 'التجار',
    'Merchant View': 'عرض التاجر',
    'Invoices': 'الفواتير',
    'Statements': 'كشوف الحساب ',
    'Management': 'إدارة',
    'Warehouses': 'المخازن',
    'Captains': 'مديرين المخازن',
    'Agents': 'مناديب الشحن',
    'Transporters': 'مسئولين النقل',
    'Points': 'النقاط',
    'Shipments': 'طلبات التوصيل',
    'Exchanges': 'طلبات الاستبدال',
    'Supplies Requests': 'مواد التغليف',
    'Insights': 'تحليلات بيانية',
    'Supplies Inventory': 'المخزون',
    'Restock Requests': 'طلبات تخزين',
    'Settings': 'الاعدادات',
    'Need Help?': 'تحتاج مساعدة؟',
    'Pickup Points': 'نقاط الاستلام',
    'Fulfillment': 'التخزين',
    'fulfillment': 'التخزين',
    'FulFillment': 'المخزن',
    'We are still working on adding this feature, check back soon!': 'ما زلنا نعمل على إضافة هذه الخاصية. من فضلك تابع مرة أخرى قريباً!',
    'We are working to bring you some great features here soon': 'نحن نعمل على توفير بعض الخصائص الرائعة هنا قريباً',
    'Name': 'اﻻسم',
    'Add Transporter': 'أضف مسئول نقل',
    'Add': 'اضافة',
    'Cancel': 'الغاء',
    'Tracking No.': 'رقم التتبع',
    'Created On': 'تاريخ الطلب',
    'Transaction Created On': 'تاريخ العملية',
    'Merchant Name': 'اسم التاجر',
    'Customer Name': 'اسم العميل',
    'Customer Location': 'عنوان العميل',
    'Customer Phone': 'رقم تليفون العميل',
    'Status': 'حالة الطلب',
    'Actions': 'خيارات',
    'View': 'عرض',
    'Cash Deposit': 'ايداع نقدي',
    'You can take a photo with mobile phones only!': 'يمكنك التقاط الصورة بالهواتف المحموله فقط!',
    'Cancel Shipment': 'حذف الطلب',
    'Print Label': 'طباعة بوليصة الشحن',
    'Download': 'تحميل',
    'Code': 'رمز',
    'Size': 'الحجم',
    'Capacity': 'السعه',
    'Linked Accounts': 'حسابات الدخول',
    'Customer Info': 'بيانات العميل',
    'Location': 'العنوان',
    'Phone': 'رقم التليفون',
    'Packages': 'الطرود',
    'CoD': 'الدفع عند الاستلام',
    'Cash on Delivery': 'الدفع عند الاستلام',
    'View Return': 'عرض المرتجع',
    'Edit Return': 'تعديل المرتجع',
    'Edit': 'تعديل',
    'Request Pickup': 'طلب استلام',
    'Cancel Request': 'حذف الطلب',
    'Store URL': 'العنوان الالكتروني للمتجر',
    'Store Phone': 'تليفون المتجر',
    'Store Email': 'البريد الإلكتروني الخاص بالمتجر',
    'First Name': 'الاسم الاول',
    'Pricing Table': 'جدول الأسعار',
    'Last Name': 'اسم العائلة',
    'Delete': 'حذف',
    'Change Password': 'تغيير كلمه المرور',
    'New Merchant Account': 'حساب تاجر جديد',
    'Email': 'البريد الإلكتروني',
    'Phone Number': 'رقم التليفون',
    'Your customer phone number': 'رقم التليفون',
    'Add Merchant': 'اضافه تاجر',
    'Password': 'كلمه المرور',
    'Confirm Password': 'تأكيد كلمه المرور',
    'New Transporter': 'اضافة مسئول نقل',
    'Change': 'تغيير',
    'Main Warehouse': 'المخزن الرئيسي',
    'Secondary Warehouses': 'المخازن التابعة',
    'New Agent': 'مندوب شحن جديد',
    'Task Sheet': 'مهام الشحن',
    'Agent': 'مندوب شحن',
    'Captain': 'مدير المحزن',
    'Merchant': 'التاجر',
    'New Captain': 'اضافة مدير مخزن',
    'Warehouse': 'المخزن',
    'Upload photo': 'رفع صورة',
    'Remove': 'حذف',
    'Save Changes': 'حفظ التغييرات',
    'Reset': 'إعادة ضبط',
    'General': 'الرئيسية',
    'Allowed JPG, GIF or PNG. Max size of 800kB': 'Allowed JPG, GIF or PNG. Max size of 800kB',
    'View Merchant': 'عرض بيانات التاجر',
    'Edit Merchant': 'تعديل بيانات التاجر',
    'Merchant Information': 'بيانات التاجر',
    'Drop Image or': 'إسقاط الصورة أو ',
    'Browse': 'تصفح',
    'Governorate': 'المحافظة',
    'City': 'المدينة',
    'Zone': 'المنطقة',
    'Zones': 'المناطق',
    'Address 1': 'العنوان (رقم البيت ،الشارع)',
    'Address 2': 'العنوان (رقم الشقة ،رقم الدور)',
    'Address 3': 'العنوان (ملاحظات أخري)',
    'CoD Fees': 'رسوم الدفع عند الاستلام',
    'Cash on Delivery Fees': 'رسوم الدفع عند الاستلام',
    'Cash On Delivery': 'الدفع عند الاستلام',
    'Confirm': 'تأكيد',
    'Zip Code': 'الرمز البريدي',
    'New Item': 'أضف مكونات الطلب',
    'Quantity': 'الكمية',
    'Price': 'السعر',
    'Add Supply Items': 'أضف مكونات طلب التوريد',
    'Edit Supply Items': 'تعديل مكونات طلب التوريد',
    'Preview': 'عرض',
    'Fulfillment Insights': 'الاحصائيات',
    'Help': 'مساعدة',
    'To contact your ShipBlu team, you can always reach us at': 'للاتصال بفريق العمل، يمكنك دائمًا الوصول إلينا على',
    'and your account manager will follow up with you shortly': 'وسيقوم مدير حسابك بالمتابعة معك في أفرب وقت',
    'New Shipment': 'اضافة طلب توصيل جديدة',
    'New Return': 'طلب إرجاع جديد',
    'New Exchange': 'طلب استبدال جديد',
    'Exchange': 'تبديل',
    'Exchange Packages': 'تبديل الطرود',
    'Pickup Information': 'بيانات الاستلام',
    'New Cash Collection': 'طلب تحصيل نقدي جديد',
    'Add Cash Collection': 'اضافه تحصيل نقدي',
    'Cash Collected': 'اجمالى ما تم استلامه',
    'Cash In-hand': 'اجمالي مع المندوب',
    'Paid by credit card': 'اجمالي الدفع عن طريق بطاقة الائتمان',
    'New Supply': 'توريد جديد',
    'Upload Multiple Orders': 'تحميل طلبات متعددة',
    'Overview': 'النظرة العامة',
    'Scheduled Pickups': 'طلبات الاستلام',
    'Scheduled Returns': 'طلبات المرتجعات',
    'Cash Balance': 'الرصيد النقدي',
    'Order Type': 'نوع الطلب',
    'Issue Type': 'نوع المشكلة',
    'Return to Store': 'اعادة الي المتجر',
    'Re-attempt Delivery': 'إعادة محاولة التوصيل',
    'Add Shipment': 'اضافة طلب توصيل',
    'Customer Information': 'بيانات العميل',
    'Customer Address 1': '(العنوان (رقم البيت والشارع',
    'Customer Address 2': 'العنون (رقم الشقة، معلم معروف)',
    'Customer Address 3': 'العنوان (ملاحظات أخري)',
    'Package Size': 'حجم الطرد',
    'Description': 'الوصف',
    'Shipment Details': 'تفاصيل الطلب',
    'Service Level': 'سرعة التوصيل',
    'Delivery Fees': 'رسوم التوصيل',
    'Retrurn Fees': 'رسوم الارتجاع',
    'Cash Refund': 'أسترجاع النقود',
    'Refund Fees': 'رسوم الاسترداد',
    'Amount (EGP)': 'المبلغ (جنيه)',
    'Fragile/Hazardous': 'قابلة للكسر / خطرة',
    'Fragile': 'قابلة للكسر',
    'Additional Information': 'ملاحظات اضافية',
    'Merchant Order Reference No.': 'الرقم المرجعي لطلب الشراء',
    'Pickup Notes': 'ملاحظات الاستلام',
    'Your account is': 'تم ايقاف حسابك',
    'On hold': '.',
    'You only have view access. Contact with your account manager.': 'لديك صلاحيه للعرض فقط. تواصل مع مدير حسابك.',
    'Please, do not forget to add any applicable fees': 'من فضلك ، لا تنس إضافة أي رسوم خدمة',
    '(return, refund) to return Amount!': '(عودة ، استرداد) مبلع المرتجع!',
    '(delivery, CoD) to CoD Amount!': '(التسليم ، الدفع عند الاستلام) إلى مبلغ الدفع عند الاستلام!',
    'Add Return': 'اضافه مرتجع',
    'Upload Shipments': 'رفع طلبات توصيل',
    'Exchange Order': 'طلب استبدال',
    'Download Template': 'تحميل النموذج',
    'Drop Excel File or': 'قم بإسقاط ملف اكسل أو',
    'Pickup to Exchange': 'طلب تبديل',
    'Pickup to Return': 'طلب ارتجاع',
    'From Warehouse': 'من مخزن',
    'To Warehouse': 'إلي مخزن',
    'En Route': 'جاري نقل طلبك إلى الفرع المختص',
    'Arrived': 'تم التوصيل',
    'Receive': 'تسلم',
    'Dispatch': 'إرسال',
    'Scan Tracking Number': 'صور رقم التتبع',
    'Scan With Package': 'صور بوليصة الطرد',
    'Collect Cash': 'استلام النقود',
    'Tracking Number': 'رقم التتبع',
    'From': 'من',
    'To': 'إلي',
    'Pick Up': 'استلم',
    'Edit Shipment': 'تعديل الطلب',
    'Order Information': 'بيانات الطلب',
    'Products declared value': 'قيمة المنتجات المعلنة',
    'Insurance fees will be applied on this amount in case the COD amount = 0': 'سيتم تطبيق رسوم التأمين على هذا المبلغ في حال ادخاله',
    'This amount will not be shown to your customer': 'لن يظهر هذا المبلغ لعميلك',
    'Merchant Order': '',
    'Reference No.': 'الرقم المرجعي',
    'Close': 'اغلاق',
    'Store Name': 'اسم المتجر',
    'Return Packages': 'الطرود المرتجعة',
    'We have attempted delivery for 3 times but failed.': 'لقد حاولنا التسليم 3 مرات لكننا لم نتمكن.',
    'Cash-only orders. No shipment to be delivered.': 'طلبات تحصيل نقود فقط بدون توصيل طرود',
    'Select any': 'اختر أي',
    'shipment to exchange. We will come pick up the new shipment first.': 'شحنة للتبديل. سنأتي لاستلام الشحنة الجديدة أولاً.',
    //Agent
    'Good Morning': 'صباح الخير',
    'Loading today\'s task sheet': 'جاري تحميل مهام اليوم',
    'Please Wait': 'من فضلك انتظر',
    'Countinue': 'استمر',
    'Today': 'اليوم',
    'Supplies': 'مواد التغليف',
    'Deliveries & Pickups': 'استلام و توصيل',
    'Deliveries': 'توصيل',
    'Pickups': 'استلام',
    'Collect': 'أجمع',
    'Get Labels': 'أخذ بوليصة الشحن',
    'Cash Collection': 'طلبات تحصيل النقود',
    'Task Cash Collection': 'اجمالي تحصيل اليوم',
    'Cash Collections': 'طلبات تحصيل النقود',
    'Your Total Commission': 'اجمالي العمولة',
    'Get Ready': 'استعد',
    'EGP': 'جنيه',
    'Address Information': 'معلومات العنوان',
    'Take Image': 'إلتقط صورة',
    'Getting Ready': 'مستعد',
    'Live Tracking Link': 'رابط التتبع',
    'Start Google Maps': 'بدأ خرائط جوجل',
    'Open Google Map': 'خرائط جوجل',
    'Checkout Packages': 'طلبات التوصيل',
    'Collect Supplies': 'جمع التوريدات',
    'Take Cash': 'احصل على النقود',
    'Take': 'احصل على',
    'Cash': 'نقدى',
    'Scan': 'صور',
    'Go!': '!انطلق',
    'Save': 'حفظ',
    'Complete checks above to start.': 'استكمل المهام للبدء',
    'You are ready to go!': '!جاهز للبدء',
    'Stops': 'نقاط الوقوف',
    'Pickup': 'استلام',
    'Delivery': 'توصيل',
    'Start Location': 'نقطة البداية',
    'Navigate': 'ابدأ القيادة',
    'Arrive': 'وصلت',
    'Next Stop': 'نقطة الوقوف التالية',
    'Checkout at warehouse': 'التسليم في المخزن',
    'Address': 'العنوان',
    'Contact Person': 'مسئول التواصل',
    'We might have been here before': 'ربما كنا هنا من قبل',
    'Add Address Info': 'إضافة معلومات العنوان',
    'Delivery Notes': 'ملاحظات التوصيل',
    'Drop Supplies': 'توصيل مواد التغليف',
    'Service': 'خدمة',
    'Delivery Only': 'توصيل فقط',
    'Confirm Pickups': 'تأكيد استلام',
    'Thanks For a successful Delivery!': '!شكراً لتوصيل الطلب بنجاح',
    'NEXT': 'التالي',
    'Next': 'التالى',
    'REPORT FAILURE': 'ابلغ عن عدم التوصيل',
    'Failed to Deliver': 'لم اتمكن من التوصيل',
    'Scan For Successful Delivery': 'صور البوليصة للتسليم',
    'Choose reason for failed delivery': ' اختر سبب عدم التوصيل',
    'Have you notified your manager?': 'هل أبلغت مديرك؟',
    'Have you received approval to fail delivery from your manager?': 'هل تلقيت الموافقة على فشل التسليم من مديرك؟',
    'Yes': 'نعم',
    'NO': 'لا',
    'FLEET MANAGER': 'مدير العمليات',
    'FAILED DELIVERY': 'فشل في التوصيل',
    'Checkout': 'انهاء',
    'Failed Drop Offs': 'فشل التوصيل',
    'Drop Pickups': 'تنزيل الاستلام',
    'Hand Over Cash': 'تسليم النقود',
    'Hand Over': 'تسليم',
    'Supplies Delivered': 'تم توصيل طلبات التوريد',
    'Pickups Picked Up': 'تم الاستلام',
    'Failed Deliveries': 'شحنات لم يتم توصيلها',
    'END DAY!': '!انهاء اليوم',
    'Account Settings': 'اعدادات الحساب',
    'Logout': 'تسجيل خروج',
    'PACKAGES DELIVERED': 'وصول الطلبات',
    'Great job! You made': 'عمل جيد ! حصلت علي',
    'Thank you': 'شكرا لك',
    'I Collected Cash': 'استلم النقدية',
    'Pickup & Return': 'استلام و مرتجعات',
    'Return': 'المرتجع',
    'Delivery & Return': 'توصيلات و عائدات',
    'Returns': 'المرتجعات',
    'Collect Returns': 'استلام مرتجعات',
    'Returns Delivered': 'توصيل المرتجعات',
    'Give Money': 'تسليم نقود',
    //client
    'ShipBlu Tracking System': 'ShipBlu نظام تتبع',
    'Welcome Dear Customer': 'مرحبا بك عزيزي العميل',
    'Please input your shipment Tracking Number': 'يرجى إدخال رقم تتبع شحنتك',
    'Track Order': 'تتبع الطلب',
    'Shipped On': 'تم شحنها في',
    'Recipient': 'المستلم',
    'Help us get to you faster!': 'ساعدنا في الوصول إليك بشكل أسرع!',
    'Select your delivery location on the map': 'حدد موقع الاستلام الخاص بك على الخريطة',
    'Your package is scheduled to be delivered on': 'تم تحديد موعد تسليم الطرد الخاصة بك في',
    'Want to change day? Select below': 'تريد تغيير يوم الاستلا؟ اختر اليوم المناسب لك بالأسفل',
    'Your selection': 'اختيارك',
    'Select Preferred Delivery Window': 'حدد وقت التسليم المناسب لك',
    'You can choose more than one time': 'يمكنك اختيار أكثر من وقت',
    'Delivery Preference': 'خيارات التوصيل',
    'Expected Delivery': 'وقت التوصيل المتوقع',
    'Success': 'نجاح',
    'User': 'المستخدم',
    'Failed': 'فشل',
    'Error. Please try again!': 'حدث خطأ غير متوقع. حاول مرة اخرى !',
    'Not Found!': 'لا يوجد!',
    'Head Of Fleet': 'مدير العمليات',
    'New Head Of Fleet': 'مدير عمليات جديد',
    'Heads Of Fleet': 'مديرين العمليات',
    'Customer Agent': 'مندوب عملاء',
    'New Customer Agent': 'مندوب عملاء جديد',
    'Customer Agents': 'مناديب العملاء',
    'Merchant Agent': 'مندوب تجار',
    'New Merchant Agent': 'مندوب تجار جديد',
    'Merchant Agents': 'مناديب التجار',
    'Username': 'اسم المستخدم',
    'Add Captain': 'اضافه مدير مخزن',
    'Edit Captain': 'تعديل مدير مخزن',
    'Add Head Of Fleet': 'اضافه مدير عمليات',
    'Edit Head Of Fleet': 'تعديل مدير العمليات',
    'Add Merchant Agent': 'اضافه مندوب تجار',
    'Edit Merchant Agent': 'تعديل مندوب تجار',
    'Add Customer Agent': 'اضافه مندوب عملاء',
    'Edit Customer Agent': 'تعديل مندوب عملاء',
    'National ID': 'الرقم القومي',
    ' has been created successfully!': ' تم اضافته بنجاح!',
    ' has been updated successfully!': ' تم نعديله بنجاح!',
    ' has been downloaded successfully!': ' تم تحميله بنجاح!',
    ' has been changed successfully!': ' تم تغييره بنجاح!',
    ' has been deleted successfully!': ' تم حذفه بنجاح!',
    'Transporter': 'مسئول نقل',
    'Data': 'البيانات',
    'Template': 'النموذج',
    'Amount': 'المبلغ',
    'Order Info': 'بيانات الطلب',
    'Notes': 'ملاحظات',
    'New Supplies Request': 'طلب مواد تغليف جديد',
    'Order View': 'عرض الطلب',
    'Select packages to return': 'اختر الطرود للارتجاع',
    'Package': 'طرد',
    'Return Order': 'طلب ارتجاع',
    'Delivery Order': 'طلب توصيل',
    'Your account is not subscribed to our fulfillment service.': 'حسابك غير مشترك في خدمة التخزين لدينا.',
    'Wanna explore great savings and exceptional customer experience?': 'هل تريد معرفة المزيد عن توفير مصاريف التخزين وخدمة عملاء فريدة؟',
    'Talk to us now!': 'تواصل معنا الان!',
    'Alerts': 'تنبيهات',
    'Orders Feed': 'الطلبات',
    'Edit Transporter': 'تعديل مسئول نقل',
    //Other
    'Merchant Info': 'بيانات التاجر',
    'Pricing': 'الأسعار',
    'Cash Processing': 'رسوم تحصيل النقود',
    'Accounting': 'الحسابات',
    'New Merchant': 'تاجر جديد',
    'Merchant Pricing': 'جدول أسعار التاجر',
    'Merchant Cash Processing Fees': 'رسوم تحصيل النقود للتاجر',
    'Cash processing fees': 'رسوم تحصيل النقود',
    'Cash processing fees max': 'أقصي حد لرسوم تحصيل النقود',
    'Cash processing percentage': 'نسبة تحصيل النقود',
    'Cash processing threshold': 'الحد الأدني لرسوم تحصيل النقود',
    'Pricing lookup': 'الأسعار',
    'Open in a new tab': 'افتح في نافذة جديدة',
    //New
    'Capacity Utilization': 'السعة المستغلة',
    'All Orders': 'قائمة الطلبات',
    'Paste link here': 'أنسخ الرابط هنا',
    'Add Task List': 'اضافه مهام الشحن',
    'Downtown Cairo Warehouse': 'مخزن وسط القاهرة',
    'New Cairo Warehouse': 'مخزن القاهرة الجديدة',
    'Alexandria Warehouse': 'مخزن الاسكندرية',
    'October Warehouse': 'مخزن أكتوبر',
    'Mansoura Warehouse': 'مخزن المنصورة',
    'Tanta Warehouse': 'مخزن طنطا',
    'Monoufia Warehouse': 'مخزن المنوفية',
    'Task List': 'مهام الشحن',
    'Task Lists': 'قوائم مهام الشحن',
    'Last Mile Agents': 'مناديب العملاء',
    'First Mile Agents': 'مناديب التجار',
    'Created By': 'انشأ بواسطه',
    'Assigned To': 'مسند إلي',
    'New Task List': 'اضافه مهام شحن',
    'Delivery Orders': 'طلبات التوصيل',
    'delivery orders': 'طلبات التوصيل',
    'Agent Type': 'نوع المندوب',
    'Abort Task List': 'ايقاف مهام الشحن',
    'Task List View': 'عرض مهام الشحن',
    'ID': 'رقم التسلسل',
    'Tracking Portal': 'صفحة العميل',
    'Delivery Window': 'وقت التسليم المفضل',
    'Call': 'اتصال',
    'Below are orders with missing customer inputs!': 'فيما يلي الطلبات مع مدخلات العملاء المفقودة!',
    'Statement Number': 'رقم كشف الحساب',
    'Issue Date': 'تاريخ الإصدار',
    'Due Date': 'تاريخ الاستحقاق',
    'Total Amout': 'المبلغ الإجمالي',
    'Link to PDF': 'رابط الملف',
    'Subscription': 'الاشتراك',
    'Pickup Point': 'نقطة الاستلام',
    'DropOff Point': 'نقطة التوصيل',
    'DropOff Counter': 'تسليم في المخزن',
    'Merchant Pickup Points': 'نقاط استلام التاجر',
    'New Pickup Point': 'نقطة استلام جديدة',
    'Add Pickup Point': 'اضافه نقط أستلام',
    'PUDOs': 'نقاط استلام عملاء',
    'PUDO': 'نقطة استلام عميل',
    'New PUDO': 'New PUDO',
    'Add PUDO': 'ADD PUDO',
    'Display Name': 'اسم العرض',
    'Google Maps Link': 'رابط خرائط جوجل',
    'In-house Orders': 'الطلبات بالمخزن',
    'Task Sheets': 'قوائم مهام الشحن',
    'Merchant API Keys': 'Merchant API Keys',
    'New API Key': 'API Key جديد',
    'API Key': 'API Key',
    'Renew': 'تجديد',
    'How will we get your package?': 'كيف سنستلم شحنتك؟',
    'Warehouse View': 'عرض المخزن',
    'Hi': 'مرحبا',
    'I\'m at': 'انا في',
    'Drop': 'وصل',
    'Going to': 'ذاهب الي',
    'Pick': 'التقط',
    'Drop Off': 'توصيل',
    'Drop Offs': 'توصيل',
    'Confirm Loading': 'تم التحميل',
    'Package to': 'ذاهبه الي',
    'Exit': 'خروج',
    'You don\'t have assigned task lists!': 'لا يوجد لديك مهام شحن اليوم!',
    'New Delivery Order': 'طلب توصيل جديد',
    'Zone Groups': 'مجموعات المناطق',
    'Zone Group': 'مجوعة المناطق',
    'New Zone Group': 'مجوعة مناطق جديدة',
    'Edit Zone Group': 'تعديل المناطق',
    'Pricing Packages': 'باقات الأسعار',
    'Scan with barcode or click camera': 'مسح بالباركود أو اضغط الكاميرا',
    'Submit': 'تأكيد',
    'Warning': 'تحذير',
    'Please, complete drop off or pickup!': 'من فضلك ، أكمل التوصيل أو الاستلام!',
    'Confirm Pickups CheckIn': 'تأكيد دخول الطلبات للمخزن',
    'Confirm Cash Collected': 'تأكيد استلام النقود',
    'Khazna': 'الخزنة',
    'Khazna Transactions': 'عمليات الخزنة',
    'New Khazna Transaction': 'ايداع/سحب جديد',
    'Transaction Type': 'نوع العملية',
    'Issued By': 'ايداع/سحب عن طريق',
    'Reference Number': 'الرقم المرجعي',
    'Add Khazna Transaction': 'اضف عملية جديدة',
    'Edit Khazna Transaction': 'تعديل',
    'Deposit at Warehouse': 'استلام بالمخزن',
    'Withdraw': 'ايداع في حساب الشركة',
    'withdraw': 'ايداع في حساب الشركة',
    'Cash received at warehouse successfully!': 'تم استلام النقود في المخزن بنجاح',
    'Customer Support': 'خدمة العملاء',
    'Billing Days': 'ايام الفواتير',
    'Missing Input': 'تفاصيل مفقودة',
    'Estimated Delivery Date': 'يوم التسليم المتوقع',
    'Current': 'الحالية',
    'All': 'الكل',
    'Completed': 'تم الانتهاء',
    'Agent Name': 'اسم المندوب',
    'Last Mile Agent': 'مندوب عملاء',
    'First Mile Agent': 'مندوب تجار',
    'Number Of Stops': 'نقاط الوقوف',
    'Number Of Packages': 'عدد الطرود',
    'Requests': 'الطلبات',
    'Delivered On': 'تم التوصيل في',
    'Returned On': 'تم الارتجاع في',
    'New PDF has been generated successfully. Click download!': 'تم إنشاء ملف PDF جديد بنجاح. اضغط تحميل !',
    'Generate PDF': 'انشاء ملف PDF',
    'Discounts': 'خصومات',
    'Net Service Fees Due': 'تكلفة الخدمة',
    'Net COD Due': 'صافي تحصيل النقود',
    'Accounting Status': 'الحالة المحاسبية',
    'Expected Deposit Date': 'تاريخ الايداع المتوقع',
    'Statement PDF': 'ملف كشف الحساب',
    'Bank Deposits': 'الايداعات البنكية',
    'Bank Deposit': 'ايداع بنكي',
    'Bank Reference Number': 'الرقم المرجعي',
    'Deposit Date': 'تاريخ الايداع',
    'Invoice Number': 'رقم الفاتورة',
    'Subtotal': 'المجموع',
    'VAT': 'ضريبة القيمة المضافة',
    'Total': 'المجموع الكلي',
    'Link to Order': 'رابط الطلب',
    'Download Invoice': 'تنزيل الفاتورة',
    'Merchant Invoices': 'فواتير التاجر',
    'Deposited On': 'تاريخ الايداع',
    'Deposit Slip': 'قسيمه الايداع',
    'Statement': 'كشف حساب',
    'New Bank Deposit': 'ايداع بنكي جديد',
    'Merchant Bank Deposits': 'الايداعات البنكيه للتاجر',
    'Merchant Statements': 'كشوف حساب التاجر',
    'PACKAGES DELIVERED & CASH COLLECTED': 'تم التوصيل و استلام النقديه',
    'This Order is part of an exchange order . Please, Check the price on the exchange order.': 'هذا الطلب جزء من طلب استبدال. من فضلك, تحقق من السعر في طلب الاستبدال الخاص به.',
    'Delivery failed. Thanks for trying.': 'لقد فشل التوصيل. شكرا لمحاولتك.',
    'Delivery Time': 'وقت التسليم الفعلي',
    'Tracking': 'التتبع',
    'Not found!': 'غير موجود',
    'Picked up On': 'تاريخ الاستلام',
    'No. Packages': 'عدد الطرود',
    'From here you can see your cash balance': 'من هنا، يمكنك معرفة اجمالى رصيدك النقدي معنا والمتوقع ايداعه في حسابك البنكي',
    'From here you can see the total scheduled pickups': 'من هنا، يمكنك عرض اجمالى طلبات التوصيل المتوقع أن يستلمها مندوبنا منكم',
    'From here you can see the total scheduled returns': 'من هنا، يمكنك عرض إجمالي طلبات المرتجعات المتوقع استلامها من العملاء',
    'From here you can see the total alerts on orders being delivered': 'من هنا، يمكنك معرفة عدد التنبيهات على طلبات التوصيل التي حاولنا ايصالها ولم نتمكن من المرة الأولي',
    'From here you can add new delivery order': 'من هنا، يمكنك اضافة طلب توصيل جديد',
    'Done!': 'اتمام',
    'Back': 'عودة',
    'From here you can': 'من هنا، يمكنك',
    '1- Add new delivery order': '١- اضافة طلب توصيل جديد',
    '2- Add new return': '٢- طلب استرجاع من العميل',
    '3- Add new exchange': '٣- اضافة طلب استبدال',
    '4- Add new cash collection': '٤- اضافة طلب تحصيل نقدى',
    '5- Add new supplies request': '٥- طلب مواد تغليف',
    '6- Upload a large number of shipments using excel sheet': '٦- اضافة عدد من الشحنات باستخدام ملف اكسيل',
    'From here you can see all ': 'من هنا، يمكنك عرض ',
    'You can select status then will appear all delivery orders belongs to this status': 'يمكنك تصفية وعرض الطلبات بناءً علي حالتها',
    'Date of adding order on ShipBlu': 'تاريخ اضافة الشحنة على التطبيق',
    'Customer\'s Name': 'اسم العميل',
    'Customer\'s Address': 'عنوان العميل',
    'Customer\'s Phone': 'رقم تليفون العميل',
    'Number of pieces or packages sent to the customer': 'عدد الطرود المرسلة للعميل',
    'Total amount to be collected': 'اجمالى المبلغ المطلوب تحصيله',
    'Date of receiving order': 'اليوم الذي استلم فيه مندوبنا الطلب من متجركم',
    'Order status': 'حالة الطلب',
    'Order arrival time': 'اليوم الذي قام فيه منودبنا بتوصيل الطلب',
    'Options to view all shipment data': 'اختيارات لادارة طلباتك مثل الغاء الطلب أو تعديله',
    'Pickup Date': 'تاريخ الاستلام',
    'Storage section is currently under development and will be available soon': 'إذا كنت مشتركاً في خدمة التخزين لدينا، يمكنك ادارة منتجاتك من هذا القسم',
    'Scan with barcode or click camera return orders': 'مسح بالباركود أو اضغط الكاميرا  للطلبات المرتجعة',
    'Confirm DropOffs': 'تاكيد تنزيل',
    'Take a Tour': 'شرح التطبيق',
    'Please, refresh the page to start a tour': 'من فضلك، قم بتحديث الصفحة لبدء جولة',
    'Governorates Deliveries': 'تسليم المحافظات',
    'Orders Awaiting Action': 'محاولات توصيل الطلبات',
    'Move order to another task sheet': 'نقل الطلب الي مهام شحن اخر',
    'Remove order from task sheet': 'حذف الطلب من مهام الشحن',
    'Add Orders': 'اضافه طلبات',
    'Complete Task List': 'اكمال مهام الشحن',
    ' has been added to task sheet successfully!': ' تمت اضافتها الي مهام الشحن بنجاح! ',
    'Summary': 'النظرة العامة',
    'Governorates': 'المحافظات',
    'Are you sure you want to ': 'هل انت متاكد انك تريد ',
    'Are you sure you have returned this order to the merchant?': 'هل أنت متأكد من إعادة هذا الطلب إلى التاجر؟',
    'cancel this order?': 'الغاء هذا الطلب ؟',
    'Returned to Origin': 'عاد الي المتجر',
    'Return to Origin': 'يعود الي المتجر',
    'return this order to origin?': 'اعاده هذا الطلب الي المتجر؟',
    'Delivered': 'تم التوصيل',
    'Returned': 'الارتجاع في',
    'Your mobile browser does not support location services!': 'هذا المتصفح لا يدعم خدمات الموقع الجغرافي!',
    'Please, allow location services on your phone!': 'من فضلك، قم بالسماح للتطبيق باستخدام خدمات الموقع الجغرافي!',
    'Order': 'الطلب',
    'Add Tracking Event': 'اضافه حالة للطلب',
    'Picked-up Orders': 'طلبات مع مندوب الاستلام',
    'Today Refunds': 'سداد للعملاء اليوم',
    'Today’s CoD': 'استلام نقدي اليوم',
    'In-house Cash': 'اجمالي النقد بالخزنة',
    'Today’s Packages': 'طرود التوصيل اليوم',
    'Today’s Pickups': 'استلامات اليوم',
    'Today’s Exchanges': 'تبديلات اليوم',
    'Today’s Returns': 'مرتجعات العملاء اليوم',
    'Today’s Deliveries': 'توصيلات اليوم',
    'Deliveries Today': 'توصيلات اليوم',
    'Pickups Today': 'استلامات اليوم',
    'Failure Reasons': 'اسباب فشل التوصيل',
    'Request Return': 'طلب ارتجاع',
    'Customer Address': 'عنوان العميل',
    'Slot Location': 'مكان التخزين',
    'Scheduled Delivery Date': 'وقت التسليم المتوقع',
    'Transaction': 'العمليه',
    'Review Required': 'المراجعة مطلوبة',
    'Needs Review': 'يحتاج إلى مراجعة',
    'Invoice isn\'t ready': 'الفاتورة غير جاهزة',
    'Packages Base Price': 'التكلفة الأساسية',
    'VAT (14%)': 'ضريبه القيمة المضافة (%14)',
    'Postal Taxes (10%)': 'ضريبه خدمات بريدية (%10)',
    'Total Price': 'المبلغ الاجمالي',
    'Estimated Price': 'تكلفة الشحن المتوقعة',
    'Destination Governorate': 'اسم المحافظة',
    'Handshake Type': 'نوع التسليم',
    'Value (EGP)': '(جنيه) التكلفة',
    'Preferred Delivery Date': 'يوم التسليم المفضل',
    'All prices include taxes (VAT and Postal).': 'الأسعار تشمل ضريبة القيمة المضافة وضريبة الخدمات البريدية',
    'No Slip Available': 'لا يوجد صورة للايداع',
    'Add Delivery Order': 'اضافه طلب توصيل',
    'CASH COLLECTED': 'تم تحصيل النقود',
    'Thanks For a successful Cash Collection!': 'شكرا لتحصيل النقود بنجاح!',
    'Cash Collection failed. Thanks for trying.': 'لقد فشل تحصي النقود. شكرا لمحاولتك.',
    'Collect Request': 'طلب تحصيل',
    'We can\'t scan orders in terminal states!': 'عفواً، هذا الطلب تم توصيله أو ارتجاعه بالفعل ولا يمكن تعديله',
    'Time': 'الوقت',
    'Counter DropOff': 'تسليم في المخزن',
    'Tracking Event': 'حالة الطلب',
    'القاهرة - Cairo': 'القاهرة',
    'Cairo - القاهرة': 'القاهرة',
    'الاسكندرية - Alexandria': 'الاسكندرية',
    'Alexandria - الاسكندرية': 'الاسكندرية',
    'الساحل الشمالي - North Coast': 'الساحل الشمالى',
    'الدقهلية  - Al-Dakahlia': 'الدقهلية',
    'القليوبية - Qalyubia': 'القليوبية',
    'Qalyubia - القليوبية': 'القليوبية',
    'المنوفية - Monoufia': 'المنوفية',
    'Monoufia - المنوفية': 'المنوفية',
    'El Gharbia - الغربية': 'الغربية',
    'البحيرة - Al-Beheira': 'البحيرة',
    'Al-Beheira - البحيرة': 'البحيرة',
    'Kafr El Sheikh - كفر الشيخ': 'كفر الشيخ',
    'اسيوط -Assiut': 'اسيوط',
    'الفيوم - Fayoum': 'الفيوم',
    'بني سويف - Beni Suef': 'بني سويف',
    'الاسماعيلية - Ismailiya': 'الاسماعيلية',
    'السويس - Suez': 'السويس',
    'البحر الأحمر- Al-Bahr Al-Ahmar': 'البحر الأحمر',
    'القاهرة الجديدة - New Cairo': 'القاهرة الجديدة',
    'وسط البلد - Downtown Cairo': 'وسط البلد',
    'اكتوبر - October': 'اكتوبر',
    'وسط البلد والجمرك - Downtown Alex & Gomrok': 'وسط البلد والجمرك',
    'Agamy & El-Amreya - العجمي والعامرية': 'العجمي والعامرية',
    'شرق الاسكندرية والمنتزة - East Alex & Montazah': 'شرق الاسكندرية والمنتزة',
    'الساحل - Sahel': 'الساحل',
    'المنصورة - Mansoura': 'المنصورة',
    'بنها - Banha': 'بنها',
    'Shebeen Al Kom - شبين الكوم': 'شبين الكوم',
    'Mahala El Kobra - المحلة الكبري': 'المحلة الكبري',
    'طنطا - Tanta': 'طنطا',
    'دمنهور - Damanhour': 'دمنهور',
    'اسيوط - Assiut': 'اسيوط',
    'الغردقة - Hurghada': 'الغردقة',
    'El Gouna - الجونة': 'الجونة',
    'التجمع الثالث - 3rd Settlement': 'التجمع الثالث',
    'التجمع الخامس - 5th Settlement': 'التجمع الخامس',
    '5th Settlement - District No 1': '5th Settlement - District No 1',
    '5th Settlement - District No 2': '5th Settlement - District No 2',
    '5th Settlement - District No 3': '5th Settlement - District No 3',
    '5th Settlement - District No 4': '5th Settlement - District No 4',
    '5th Settlement - District No 5': '5th Settlement - District No 5',
    'الشويفات - El-Shouyfat': 'الشويفات',
    'الدبلوماسيين - El-Diplomasyeen': 'الدبلوماسيين',
    'القطامية - El-Kattameya': 'القطامية',
    'ارابيلا - Arabella': 'ارابيلا',
    'غرب الجولف - Gharb El Golf': 'غرب الجولف',
    'Al Rehab - الرحاب': 'الرحاب',
    'الياسمين 1 - El-Yasmeen 1': 'الياسمين 1',
    'الياسمين 2 - El-Yasmeen 2': 'الياسمين 2',
    'الياسمين 3 - El-Yasmeen 3': 'الياسمين 3',
    'الياسمين 4 - El-Yasmeen 4': 'الياسمين 4',
    'الياسمين 5 - El-Yasmeen 5': 'الياسمين 5',
    'الياسمين 6 - El-Yasmeen 6': 'الياسمين 6',
    'الياسمين 7 - El-Yasmeen 7': 'الياسمين 7',
    'الياسمين 8 - El-Yasmeen 8': 'الياسمين 8',
    'البنفسج 1 - El-Banafseg 1': 'البنفسج 1',
    'البنفسج 2 - El-Banafseg 2': 'البنفسج 2',
    'البنفسج 3 - El-Banafseg 3': 'البنفسج 3',
    'البنفسج 4 - El-Banafseg 4': 'البنفسج 4',
    'البنفسج 5 - El-Banafseg 5': 'البنفسج 5',
    'البنفسج 6 - El-Banafseg 6': 'البنفسج 6',
    'البنفسج 7 - El-Banafseg 7': 'البنفسج 7',
    'البنفسج 8 - El-Banafseg 8': 'البنفسج 8',
    'البنفسج 9 - El-Banafseg 9': 'البنفسج 9',
    'البنفسج 10 - El-Banafseg 10': 'البنفسج 10',
    'البنفسج 11 - El-Banafseg 11': 'البنفسج 11',
    'البنفسج 12 - El-Banafseg 12': 'البنفسج 12',
    'التجمع الأول - 1st Settlement': 'التجمع الأول',
    '1st Settlement - North Youth Housing': '1st Settlement - North Youth Housing',
    '2nd Settlement - South Youth Housing': '2nd Settlement - South Youth Housing',
    'كايرو فستيفال سيتي - Cairo Festival City': 'كايرو فستيفال سيتي',
    'شرق الأكاديمية - Sharq El Academia': 'شرق الأكاديمية',
    'جنوب الاكاديمية - Ganoob Al Academia - Area A': 'جنوب الاكاديمية',
    'جنوب الاكاديمية - Ganoob Al Academia - Area B': 'جنوب الاكاديمية',
    'جنوب الاكاديمية - Ganoob Al Academia - Area C': 'جنوب الاكاديمية',
    'جنوب الاكاديمية - Ganoob Al Academia - Area D': 'جنوب الاكاديمية',
    'جنوب الاكاديمية - Ganoob Al Academia - Area\u00a0E': 'جنوب الاكاديمية',
    'جنوب الاكاديمية - Ganoob Al Academia - Area F': 'جنوب الاكاديمية',
    'منطقة المستثمرين الجنوبية - South Investors Area': 'منطقة المستثمرين الجنوبية',
    'منطقة المسثمرين الشمالية - North Investors Area': 'منطقة المسثمرين الشمالية',
    'الأندلوس - El Andalous': 'الأندلوس',
    'اللوتس - El-Lotus': 'اللوتس',
    'النرجس 1 - El-Narges 1': 'النرجس 1',
    'النرجس 2 - El-Narges 2': 'النرجس 2',
    'النرجس 3 - El-Narges 3': 'النرجس 3',
    'النرجس 4 - El-Narges 4': 'النرجس 4',
    'النرجس 5 - El-Narges 5': 'النرجس 5',
    'النرجس 6 - El-Narges 6': 'النرجس 6',
    'النرجس 7 - El-Narges 7': 'النرجس 7',
    'النرجس 8 - El-Narges 8': 'النرجس 8',
    'المنطقة الأولي - Area 1': 'المنطقة الأولي',
    'المنطقة الثالثة - Area 3': 'المنطقة الثالثة',
    'أبو الهول - Abo El Houl': 'أبو الهول',
    'العاشر من رمضان - 10th of Ramadan': 'العاشر من رمضان',
    'El Shorouk - الشروق': 'الشروق',
    'مدينة المستقبل - Future City': 'مدينة المستقبل',
    'مدينتي - Madinaty': 'مدينتي',
    'العبور - Obour': 'العبور',
    'مدينة الطلائع - El Talae3 City': 'مدينة الطلائع',
    'هيلوبوليس الجديدة - New Heliopolis City': 'هيلوبوليس الجديدة',
    'مدينة بدر - Badr City': 'مدينة بدر',
    'جمعية أحمد عرابي - Ahmed Oraby Association': 'جمعية أحمد عرابي',
    'Al Manial - المنيل': 'المنيل',
    'جاردن سيتي - Garden City': 'جاردن سيتي',
    'الزمالك - Zamalek': 'الزمالك',
    'Masr El Kadema - مصر القديمة': 'مصر القديمة',
    'عابدين - Abdeen': 'عابدين',
    'بولاق - Bulaq': 'بولاق',
    'العجوزة - Agouza': 'العجوزة',
    'الدقي - Dokki': 'الدقي',
    'الجيزة - Giza': 'الجيزة',
    'Giza - الجيزة': 'الجيزة',
    'North Coast - الساحل الشمالي': 'الساحل الشمالي',
    'Al-Dakahlia - الدقهلية': 'الدقهلية',
    'Damanhour - دمنهور': 'دمنهور',
    'المهندسين - Mohandeseen': 'المهندسين',
    'ميت عقبة - Mit Akaba': 'ميت عقبة',
    'Ard El Lewa - ارض اللوا': 'ارض اللوا',
    'فيصل - Faisal': 'فيصل',
    'الهرم - Haram': 'الهرم',
    'حدائق الأهرام - Hadayek El Haram': 'حدائق الأهرام',
    'امبابة - Imbaba': 'امبابة',
    'El Matareya - المطرية': 'المطرية',
    'El Qobbah - القبة': 'القبة',
    'Al Marj - المرج': 'المرج',
    'Road El Farag - روض الفرج': 'روض الفرج',
    'العباسية - Abbassia': 'العباسية',
    'Al Wayli - الوايلي': 'الوايلي',
    'Al Daher': 'Al Daher',
    'شبرا - Shubra': 'شبرا',
    'Shubra El Kheima - شبرا الخيمة': 'شبرا الخيمة',
    'Al Muqattam - المقطم': 'المقطم',
    'الهضبة الوسطي - El-Hadaba El-Wosta': 'الهضبة الوسطى',
    'حلوان - Helwan': 'حلوان',
    'المعادي - Maadi': 'المعادى',
    'شمال القاهرة - Uptown Cairo': 'شمال القاهرة',
    'الزيتون - El-Zaytoun': 'الزيتون',
    'جسر السويس - Gesr elsuez': 'جسر السويس',
    'عين شمس - Ain Shams': 'عين شمس',
    'مدينة نصر - Nasr City': 'مدينة نصر',
    'شيراتون - Sheraton': 'شيراتون',
    'مصر الجديدة - Heliopolis': 'مصر الجديدة',
    'Abu Rawash - ابورواش': 'ابورواش',
    'Alex Desert Road (Before Toll Station)': 'Alex Desert Road (Before Toll Station)',
    'Alex Desert Road (After Toll Station)': 'Alex Desert Road (After Toll Station)',
    'New Giza': 'New Giza',
    'القرية الذكية - Smart Village': 'القرية الذكية',
    'Pyramids Heights': 'Pyramids Heights',
    'Garana Residence': 'Garana Residence',
    'Azizia Villas': 'Azizia Villas',
    'Katr El Nada': 'Katr El Nada',
    'دريم لاند - Dream Land': 'دريم لاند',
    'حدايق الاهرام - Pyramids Gardens': 'حدائق الاهرام',
    '6th of October City - اكتوبر': 'اكتوبر',
    '6th of October City - District 1': '6th of October City - District 1',
    '6th of October City - District 2': '6th of October City - District 2',
    '6th of October City - District 3': '6th of October City - District 3',
    '6th of October City - District 4': '6th of October City - District 4',
    '6th of October City - District 5': '6th of October City - District 5',
    '6th of October City - District 6': '6th of October City - District 6',
    '6th of October City - Al Bashayer District': '6th of October City - Al Bashayer District ',
    '6th of October City - District 8': '6th of October City - District 8',
    '6th of October City - District 10': '6th of October City - District 10',
    '6th of October City - District 11': '6th of October City - District 11',
    '6th of October City - District 12': '6th of October City - District 12',
    '6th of October City - Youth Residence': '6th of October City - Youth Residence',
    '6th of October City - Al Motameyz District': '6th of October City - Al Motameyz District',
    '6th of October City - West Somid': '6th of October City - West Somid',
    'Sheikh Zayed City - الشيخ زايد': 'الشيخ زايد',
    'Sheikh Zayed City - El Rabwa Compound': 'Sheikh Zayed City - El Rabwa Compound ',
    'Sheikh Zayed City - Westown Residence': 'Sheikh Zayed City - Westown Residence',
    'Sheikh Zayed City - Sodic Allegria': 'Sheikh Zayed City - Sodic Allegria',
    'Sheikh Zayed City - Casa Compound': 'Sheikh Zayed City - Casa Compound',
    'Sheikh Zayed City - Beverly Hills Zayed': 'Sheikh Zayed City - Beverly Hills Zayed',
    'Sheikh Zayed City - District 1': 'Sheikh Zayed City - District 1',
    'Sheikh Zayed City - District 2': 'Sheikh Zayed City - District 2',
    'Sheikh Zayed City - District 3': 'Sheikh Zayed City - District 3',
    'Sheikh Zayed City - District 4': 'Sheikh Zayed City - District 4',
    'Sheikh Zayed City - District 5': 'Sheikh Zayed City - District 5',
    'Sheikh Zayed City - District 7': 'Sheikh Zayed City - District 7',
    'Sheikh Zayed City - District 8': 'Sheikh Zayed City - District 8',
    'Sheikh Zayed City - District 9': 'Sheikh Zayed City - District 9',
    'Sheikh Zayed City - District 10': 'Sheikh Zayed City - District 10',
    'Sheikh Zayed City - District 11': 'Sheikh Zayed City - District 11',
    'Sheikh Zayed City - District 12': 'Sheikh Zayed City - District 12',
    'Sheikh Zayed City - District 14': 'Sheikh Zayed City - District 14',
    'Sheikh Zayed City - District 16': 'Sheikh Zayed City - District 16',
    'El Hadara - الحضرة': 'الحضرة',
    'El Labban - اللبان': 'اللبان',
    'El Shatby - الشاطبي': 'الشاطبى',
    'كرموز - Karmoz': 'كرموز',
    'Kom El Deka - كوم الدقة': 'كوم الدقة',
    'Mahatet El Raml - محطة الرمل': 'محطة الرمل',
    'محرم بيك - Moharam Bek': 'محرم بيك',
    'وسط البلد - Downtown Alexandria': 'وسط البلد',
    'El Atareen - العطارين': 'العطارين',
    'El Azarita - الازاريطة': 'الازاريطة',
    'El Ibrahimiya - الابراهيمية': 'الابراهيمية',
    'بحري - Bahary': 'بحرى',
    'El Anfoushi - الانفوشي': 'الانفوشى',
    'El Gomrok - الجمرك': 'الجمرك',
    'El Mansheya - المنشية': 'المنشية',
    'El Qabary - القباري': 'القبارى',
    'El Wardeyan - الورديان': 'الورديان',
    'العجمي - Agamy': 'العجمى',
    'أبو يوسف - Abou Youssef': 'ابو يوسف',
    'العامرية - El-Amreya': 'العامرية',
    'البيطاش - El-Bitash': 'البيطاش',
    'الديخيلة - El-Dekhela': 'الديخيلة',
    'الهانوفيل - El-Hanoveel': 'الهانوفيل',
    'المكس - El-Max': 'المكس',
    'أكتوبر - October': 'اكتوبر',
    'باكوس - Bakos': 'باكوس',
    'بولكلي - Bolkly': 'بولكلي',
    'كامب شيزار - Camp Chezar': 'كامب شيزار',
    'كليوباترا - Cleopatra': 'كليوباترا',
    'El Saraya - السراية': 'السراية',
    'فليمنج - Fleming': 'فليمنج',
    'جناكلس - Gianaclis': 'جناكلس',
    'جليم - Glim': 'جليم',
    'كقر عبده - Kafr Abdu': 'كقر عبده',
    'لوران - Louran': 'لوران',
    'رشدي - Roshdy': 'رشدي',
    'سابا باشا - Saba Basha': 'سابا باشا',
    'سان ستيفانو - San Stefano': 'سان ستيفانو',
    'سيدي جابر - Sidi Gaber': 'سيدي جابر',
    'سموحة - Smouha': 'سموحة',
    'سبورتنج - Sporting': 'سبورتنج',
    'ستانلي - Stanley': 'ستانلي',
    'ثروت - Tharwat': 'ثروت',
    'زيزينيا - Zezenia': 'زيزينيا',
    'Abu Kir - ابوقير': 'ابوقير',
    'El Asafra - العصافرة': 'العصافرة',
    'El Maamora - المعمورة': 'المعمورة',
    'El Mandara - المندرة': 'المندرة',
    'El Montaza - المنتزة': 'المنتزة',
    'El Soyof - السيوف': 'السيوف',
    'ميامي - Miami': 'ميامي',
    'سيدي بشر - Sidi Bishr': 'سيدي بشر',
    'فكتوريا - Victoria': 'فكتوريا',
    'عزبة الشال': 'عزبة الشال',
    'مدينة مبارك': 'مدينة مبارك',
    'سندوب': 'سندوب',
    'مدينة الفردوس': 'مدينة الفردوس',
    'مدينة السلام': 'مدينة السلام',
    'Madinet Al-Salam - مدينة السلام': 'مدينة السلام',
    '6 October - السادس من أكتوبر': 'السادس من أكتوبر',
    'New Cities - المدن الجديدة': 'المدن الجديدة',
    'حي الجامعة': 'حي الجامعة',
    'ميت خميس': 'ميت خميس',
    'طلخا': 'طلخا',
    'ميت عنتر': 'ميت عنتر',
    'منية سندوب': 'منية سندوب',
    'بلطيم - Balteem': 'بلطيم',
    'بيلا - Bela': 'بيلا',
    'البرلس - Borolles': 'البرلس',
    'دسوق - Desouq': 'دسوق',
    'الرياض - El-Reyad': 'الرياض',
    'فوه - Fuwwah': 'فوه',
    'حمول - Hamool': 'حمول',
    'كفر الشيخ - Kafr Alsheikh': 'كفر الشيخ',
    'مطوبس - Mutubas': 'مطوبس',
    'قلين - Qeleen': 'قلين',
    'سيدي سالم - Sidi Salem': 'سيدى سالم',
    'ابنوب - Abanoub': 'ابنوب',
    'Abo Teg - ابوتيج': 'ابو تيج',
    'ديروط - Dayrout': 'ديروط',
    'El Badary - البداري': 'البداري',
    'El Fath - الفتح': 'الفتح',
    'El Ghanaym - الغنايم': 'الغنايم',
    'El Qawsaia - القوصية': 'القوصية',
    'منفلوط - Manfalout': 'منفلوط',
    'اسيوط الجديدة - New Assuit': 'اسيوط الجديدة',
    'صدفا - Sadfa': 'صدفا',
    'ساحل سليم - Sahel Selim': 'ساحل سليم',
    'اطسا - Atsa': 'اطسا',
    'ابشواي - Ebsheway': 'ابشوى',
    'طمية - Tamya': 'طمية',
    'يوسف الصديق - Yousef Elsedeq': 'يوسف الصديق',
    'ببا - Biba': 'ببا',
    'الفشن - Elfashn': 'الفشن',
    'الواسطى - Elwasty': 'الواسطى',
    'إهناسيا - Ihnasya': 'إهناسيا',
    'ناصر - Nasser': 'ناصر',
    'بني سويف الجديدة - New Beni Suef': 'بنى سويف الجديدة',
    'سمسطا - Samsta': 'سمسطا',
    'أبو عطوة - Abu Atwa': 'أبو عطوة',
    'أبو خليفة - Abu Khalifah': 'أبو خليفة',
    'أبو صوير - Abu Soweir': 'أبو صوير',
    'أبو سلطان - Abu sultan': 'أبو سلطان',
    'التل الكبير - Al Tall Al kabir': 'التل الكبير',
    'القصاصين - El Kasasin': 'القصاصين',
    'الصالحية الجديدة - El Saleheya Al gadida': 'الصالحية الجديدة',
    'فايد - Fayed': 'فايد',
    'الحي الأول - Hay 1': 'الحى الأول',
    'الحي الثاني - Hay 2': 'الحى الثانى',
    'الحي الثالث - Hay 3': 'الحى الثالث',
    'الكيلو 11 - Kilo 11': 'الكيلو 11',
    'قنطرة غرب - Qantara Gharab': 'قنطرة غرب',
    'قنطرة شرق - Qantara Shark': 'قنطرة شرق',
    'سرابيوم - Sarabium': 'سرابيوم',
    'أبو رديس - Abu Rudeis': 'أبو رديس',
    'أبو زنيمة - Abu Zenima': 'أبو زنيمة',
    'منطقة السويس - Al Suez District': 'منطقة السويس',
    'العدبية - Al-Adabya': 'العدبية',
    'عتاقة - Ataka District': 'عتاقة',
    'السلام - El Salam District': 'السلام',
    'الأربعين - El-Arbeen District': 'الأربعين',
    'الجناين - Elganaien District': 'الجناين',
    'فيصل - Faisal District': 'فيصل',
    'El-Hawamdeya - الحوامدية': 'الحوامدية',
    'ElBadrasheen - البدرشين': 'البدرشين',
    'الحوامدية - El-Hawamdeya': 'الحوامدية',
    'البدرشين - ElBadrasheen': 'البدرشين',
    'New Accounting Account': 'أضافة محاسب',
    'Add Accounting': 'أضافة محاسب',
    'Edit Accounting': 'تعديل محاسب',
    'Send': 'ارسال',
    'Export': 'استخراج',
    'Customer Email': 'البريد الإلكتروني للعميل',
    'Customer Governorate': 'محافظة العميل',
    'Customer City': 'مدينة العميل',
    'Customer Zone': 'منطقة العميل',
    'Customer Governorate & City': 'محافظة ومدينة العميل',
    'Merchant Phone': 'رقم تليفون التاجر',
    'Merchant URL': 'العنوان الإلكتروني للتاجر',
    'Slots': 'الأرفف',
    'slots': 'الأرفف',
    'Bins': 'سلات النقل',
    'bins': 'سلات النقل',
    'View Packages': 'عرض الطرود',
    'Package ID': 'رقم الطرد',
    'Awaiting Calls': 'قائمة الاتصالات',
    'City/Zone': 'المحافظة/المنطقة',
    'Original Delivery Date': 'تاريخ التسليم',
    'Attempts': 'عدد المحاولات',
    'Outcome': 'خيارات',
    'Confirmed': 'تم التأكيد',
    'Fail Order': 'فشل الطلب',
    'Orders Awaiting Call': 'طلبات في انتظار الاتصال بالعميل',
    'Orders Awaiting Actions': 'طلبات فى انتظار اوامر',
    'Awaiting Actions': 'قائمه العمليات',
    'Notifications': 'الاشعارات',
    'Invoice Date': 'تاريخ الفاتورة',
    'Statement Date': 'تاريخ كشف الحساب',
    'Confirm delivery?': 'تأكيد التوصيل؟',
    'Supply': 'مواد التغليف',
    'You are all set. Thank you for calling our customers and making them happy': 'لقد قمت من الانتهاء من كافة المكالمات المطلوبة. شكراً لاتصالك بعملائنا والحرص علي رضائهم',
    'You don\'t have any scheduled pickups': 'لا يوجد طلبات استلام قادمة',
    'Create orders by clicking the \'+\' button above. We will come over to pick them up.': 'ادخل طلبات التوصيل عن طريق الضغط علي علامة الـ \'+\' في أعلي الشاشة وسيأتي مندوبنا للاستلام في أول موعد استلام قادم',
    'We give your customers a premium delivery service to come buy again from you.': 'نقوم بتوصيل طلبك للعميل وتقديم خدمة مميزة تزيد رضائه عن منتجك ورغبته في الشراء من متجرك مرة أخري',
    'Sit back and wait for your money to be collected and deposited. Our billing is fully automated.': 'طلباتك في أمان معنا. فقط استرخِ وتأكد من أن مدفوعات عملائك ستصلك في أقرب وقت لأن نظامنا المحاسبي مميكن بالكامل',
    'PUDO View': 'عرض نقطة استلام عميل',
    'Customer unreachable': ' تعثر الوصول للعميل',
    'Adjustment': 'تصحيح',
    'Search': 'بحث',
    'Enter tracking number or phone number': 'ادخل رقم التتبع او رقم التليفون',
    'You are now online. Some local updates were synced successfully!': 'You are now online. Some local updates were synced successfully!',
    'Data stored in your device and will be synced when you reconnect online!': 'Data stored in your device and will be synced when you reconnect online!',
    'Receive Returns': 'استلام مرتجعات',
    'Refuse Return': 'رفض مرتجع',
    'Choose reason for refused Return': 'اختر سبب رفض المرتجع',
    'The Package is damaged': 'الطرد تالف',
    'There is a missing item in the package': 'هناك عنصر مفقود فى الطرد',
    'The AWB is missing': 'بوليصة الشحن مفقودة',
    'I don’t have this package on me': 'الطرد ليس معى',
    'Merchant is having technical issues': 'التاجر لديه مشاكل فنية',
    'Refuse Returns': 'رفض مرتجعات',
    'REPORT REFUSE': 'ابلغ عن رفض المرتجع',
    'Please, download our Android application by clicking': 'من فضلك، قم بتحميل تطبيق الموبايل عن طريق الضغط علي',
    'this link.': 'الرابط التالي.',
    'This web application will stop working soon.': 'سوف نقوم بايقاف العمل بتطبيق الويب قريباً',
    'RTO Requested': 'طلب مرتجع',
    'rto requested': 'طلب مرتجع',
    'Delivery Attempts': 'محاولات التسليم',
    'Upload Orders': 'رفع الطلبات',
    'Confirm return': 'تأكيد استلام',
    'Postpone Returns': 'تأجيل المرتجعات',
    'Pickup Days': 'أيام الاستلام',
    'Integrations': 'برمجيات',
    'Declined': 'رفض',
    'Approved': 'قيول',
    'Payment Account': 'حساب المدفوعات',
    'Payment Accounts': 'حسابات المدفوعات',
    'Payments': 'المدفوعات',
    'Date': 'التاريخ',
    'New Payment': 'مدفوعات جديدة',
    'COD': 'الدفع عند الاستلام',
    'Payment': 'المدفوعات',
    'Drop File or': 'ادراج ملف أو',
    'New Payment Account': 'حساب مدفوعات جديد',
    'Account Name': 'اسم الحساب',
    'Account Number': 'رقم الحساب',
    'Number': 'الرقم',
    'Add Payment Account': 'أضافة حساب مدفوعات',
    'Create Bag': 'اضافه حقيبه',
    'You can scan packages after creating a bag.': 'يمكنك اضافه الطرود بعد اضافه الحقيبه.',
    'Paid To': 'مدفوع الى',
    'Criteria': 'Criteria',
    'Discount': 'الخصم',
    'Value': 'التكلفة',
    'New Discount': 'اضافة خصم جديد',
    'Pricing Package': 'باقة الأسعار',
    'New Pricing Package': 'باقة اسعار جديدة',
    'Add Pricing Package': 'اضافة باقة اسعار',
    'Master Bags': 'حقائب شحن',
    'Google Sheet URL': 'رابط جوجل شيت',
    'Refunds Enabled': 'اتاحة سداد المرتجعات',
    'Refunds Credit Limit': 'الحد المسموح لسداد المرتجعات',
    'This is all the cash we have': 'هذه القيمة تعبر عن مجموع ما تم جمعه',
    'collected on your behalf as seen': 'لجميع الطلبات في حسابك معنا كما يراها',
    'by our accounting team.': 'فريق الحسابات.',
    'This is all the service fees and': 'هذه القيمة تعبر عن مجموع تكلفة',
    'refunds you owe to ShipBlu as': 'الخدمات وارتجاع نقود في حسابك كما',
    'seen by our accounting team.': 'يراها فريق الحسابات.',
    'This is the expected cash balance': 'هذه القيمة تعبر عن صافي حسابك ',
    'to be deposited in your next': 'معنا والمتوقع ايداعه مع اصدار',
    'statement as seen by our': 'كشف الحساب القادم يراها فريق',
    'accounting team.': 'الحسابات.',
    'Last updated: ': 'آخر تحديث: ',
    'This is all the cash': 'هذه القيمة تعبر عن مجموع ما تم جمعه لجميع الطلبات في حسابك معنا كما يراها فريق الحسابات.',
    'This is all the service': 'هذه القيمة تعبر عن مجموع تكلفة الخدمات وارتجاع نقود في حسابك كما يراها فريق الحسابات.',
    'This is the expected': 'هذه القيمة تعبر عن صافي حسابك معنا والمتوقع ايداعه مع اصدار كشف الحساب القادم.',
    'COD Balance': 'رصيد تحصيل النقود',
    'Service Fees & Refunds Due': 'رسوم الخدمة وسداد المرتجعات',
    'Net Cash Balance': 'صافي رصيدك النقدي',
    'Slot': 'الرف',
    'Slot View': 'عرص الرف',
    'Bin View': 'عرض سله النقل',
    'Seal': 'القفل',
    'Slot Name': 'أسم الرف',
    'Empty Slot': 'تفريغ الرف',
    'Checkout Date': 'تاريخ خروج الطرد',
    'adjustment': 'adjustment',
    'Type': 'النوع',
    'Move': 'نقل',
    'GPS Loaction': 'موقع GPS',
    'UNZONED': 'UNZONED',
    'Bin Name': 'أسم سلة النقل',
    'Scanned Successfully': 'طرود داخل السلة',
    'Count of Master Bags': 'عدد حقائب الشحن',
    'Orders In-Bag': 'عدد الطلبات فى حقائب الشحن',
    'Seal No.': 'رقم القفل',
    'Bag Type': 'نوع الحقيبة',
    'Incoming': 'الحقائب الواردة',
    'Outgoing': 'الحقائب الصادرة',
    'Seal Number': 'رقم القفل',
    'Packages In-Bag': 'عدد الطرود فى الحقيبة',
    'Master Bag View': 'عرض حقائب الشحن',
    'Master Bag Information': 'بيانات حقيبة الشحن',
    'To warehouse': 'الى مخزن',
    'Current warehouse': 'المخزن الحالى',
    'Please, open camera permission from your browser!': 'من فضلك، قم باعطاء صلاحيات استخدام الكاميرا للمتصفح',
    'Growth Hackers': 'هاكرز النمو',
    'Growth Hacking': 'هاكرز النمو',
    'New Growth Hacker': 'هاكر نمو جديد',
    'Edit Growth Hacker': 'تعديل هاكر النمو',
    'Add Growth Hacker': 'اضافة هاكر نمو',
    'Other': 'اخري',
    'Count': 'العدد',
    'Item': 'صنف',
    'New Inventoy Item': 'اضافه مخزون جديد',
    'Credit Notes': 'ملاحظات الائتمان',
    'Promo Code': 'كود خصم',
    'If you have a promo code': 'كود خصم',
    'Exception Discount': 'خصم استثنائى',
    'Promo Codes': 'أكواد خصم',
    'Exception Discounts': 'خصومات استثنائية',
    'Scope': 'المجال',
    'Service Types': 'انواع الطلب',
    'Auto Apply': 'ذاتي التطبيق',
    'Start Date': 'تاريخ البداية',
    'Expiry Date': 'تاريخ النهاية',
    'Units': 'الوحدات',
    'Unit': 'الوحدة',
    'Limitations': 'خواص الاستعمال',
    'Use Limit per Code': 'الحد الاقصي لمرات الاستعمال',
    'Use Limit per Merchant': 'الحد الاقصي لمرات الاستعمال لكل تاجر',
    'EGP Limit per Code': 'الحد الاقصي للخصم',
    'EGP Limit per Merchant': 'الحد الاقصي للخصم لكل تاجر',
    'Qualifier': 'المؤهلات',
    'Source Governorates': 'اماكن الشحن',
    'Destination Governorates': 'اماكن التوجه',
    'New Promo Code': 'كود خصم جديد',
    'Add Promo Code': 'اضافة كود خصم',
    'Service Type': 'نوع الطلب',
    'New Exception Discount': 'خصم استثنائى جديد',
    'Add Exception Discount': 'اضافة خصم استثنائى',
    'Edit Promo Code': 'تعديل كود الخصم',
    'Edit Exception Discount': 'تعديل الخصم الاستثنائى',
    'Task Sheet Order': 'طلب مهام الشحن',
    'Custody': 'عهدة نقدية',
    'Sharkia Warehouse': 'مخزن الشرقية',
    'Are you sure to receive': 'تأكيد استلام عهدة نقدية بمبلغ ',
    'Are you sure to collect cash': 'تأكيد استلام المبلغ',
    'Blacklist': 'قائمة الحظر',
    'Reason': 'السبب',
    'Block Customer': 'حظر العميل',
    'Block': 'حظر',
    'Are you sure to remove this customer from blacklist?': 'هل أنت متأكد من إزالة هذا العميل من قائمة الحظر؟',
    'Customer\'s phone is turned off': 'هاتف العميل مغلق',
    'Customer is not answering our calls': 'العميل لا يرد على الهاتف',
    'Wrong address provided': 'العنوان خاطئ',
    'Address not completed': 'العنوان غير كامل',
    'No one at home': 'لا أحد بالمنزل',
    'Out Of Zone': 'خارج نطاق التغطيه',
    'Customer Rescheduled': 'العميل طلب التأجيل',
    'Customer request to change address': 'طلب العميل تغيير العنوان',
    'Customer refused to accept the shipment': 'رفض العميل استلام الشحنه',
    'Customer didn\'t have Money': 'لم يكن لدى العميل مال كافي',
    'customer didn\'t have money': 'لم يكن لدى العميل مال كافي',
    'Customer evade to receive': 'العميل يتهرب من الاستلام',
    'Customer wants to open the package': 'العميل طلب فتح الشحنة',
    'Task sheet already closed.': 'مهام الشحن مغلقة بالفعل.',
    ' has been blocked successfully!': ' تم حظره بنجاح! ',
    'Packaging does not follow ShipBlu guidelines': 'تغليف الشحنة غير مطابق للتعليمات',
    'Copy link': 'انسخ الرابط',
    'Incoming Orders': 'طلبات للمراجعة',
    'Magic': 'ماجيك',
    'Magic Links': 'روابط ماجيك',
    'copied to clipboard!': 'تم نسخ الرابط!',
    'Link': 'الرابط',
    'Enabled': 'تمكين',
    'Copy': 'نسخ',
    'Current Usage': 'الاستخدام الحالي',
    'Max Usage': 'أقصى استخدام',
    'am': 'ص',
    'pm': 'م',
    'Open Parcel': 'يسمح بفتح الشحنه',
    ' not found!': ' لا يوجد',
    'Please select the order you want to ship!': 'يرجى تحديد الاوردر الذى تريد شحنه',
    'Please select order you want to download!': 'يرجى تحديد الاوردر الذى تريد تحميله!',
    'Please select the order you want to request!': 'يرجى تحديد الاوردر الذى تريد طلبه!',
    'Please select only the created order you want to cancel!': 'يرجى تحديد فقط الاوردر الذي تم انشاءه الذى تريد الغاءه!',
    'Please select the order you want to print!': 'يرجى تحديد الاوردر الذى تريد طباعته!',
    'This order is not pickup requested, We can\'t add it to your task list!': 'هذا الطلب غير مطلوب ، لا يمكننا إضافته إلى قائمة المهام الخاصة بك!',
    'The web app is no longer supported on Android devices. Please, use the Android app from: ': ' تم ايقاف دعم تطبيق الويب. من فضلك قم بتحميل تطبيق الاندرويد من: ',
    'Tracking number, merchant name or reference number...': 'رقم التتبع أو اسم التاجر أو الرقم المرجعي ...',
    'Webhook': 'Webhook',
    'Webhooks': 'Webhooks',
    'Webhook Events': 'Webhook Events',
    'Select your dropoff point:': 'اختر نقطه التوصيل:',
    'Select your pickup point:': 'اختر نقطه الاستلام:',
    'Customer Information & Address': 'بيانات العميل و العنوان',
    'Full Name': 'الاسم الكامل',
    'Your customer full name': 'الاسم الكامل',
    'Email Address': 'البريد الإلكتروني',
    'Additional Phone Number': 'رقم تليفون اضافي',
    'The parcel': 'الشحنه',
    'Can Open': 'يسمح بفتح الشحنه',
    'Can\'t Open': 'لا يسمح بفتح الشحنه',
    'Cash On Delivery (COD) ': 'الدفع عند الاستلام ',
    '"Notes" will be printed on the order\'s label.': 'ستتم طباعة "الملاحظات" على ملصق الطلب.',
    'Do you want to leave notes?': 'هل تريد ترك ملاحظات؟',
    'your notes..': 'ملاحظاتك..',
    'Add your delivery notes here': 'ملاحظاتك',
    'Done': 'اتمام',
    'Add Description': 'اضف وصف',
    'Add Supplies Items': 'اضف طرود جديدة',
    'In order to complete your exchange fill in the required info below': 'لتكملة عملية التبديل ادخل البيانات الموضحة بالاسفل',
    'Add new Exchange Request': 'طلب استبدال جديد',
    'Here you can add all the supplies you need': 'هنا تقدر تضيف احتياجك من طرود الشحن',
    'Create Order': 'اضافه طلب',
    'Create Return': 'اضافه مرتجع',
    'Holidays': 'العطل',
    'Holiday': 'عطلة',
    'Add Holiday': 'إضافة عطلة',
    'Applies To': 'ينطبق على',
    'First Mile Orders': 'طلبات مناديب التجار',
    'Last Mile Orders': 'طلبات مناديب العملاء',
    'RTOs': 'طلبات مرتجعه',
    'Order Tracking': 'تتبع الطلب',
    'Good Job!': 'تم بنجاح!',
    'Your order has been created,': 'تم انشاء الطلب.',
    'You can now request your pickup!': 'الان يمكنك طلب استلامه من طلبات التوصيل.',
    'Success!': 'تم بنجاح!',
    'We are working on your export,': 'نعمل الان على استخراج طلبك،',
    'We will send you a notification once it’s done.': 'سيصلك اشعار خلال لحظات عند اتمامه.',
    'Your exchange hass been created. We’ll come to pickup the order first,': 'لقد تم انشاء طلب التبديل. سنقوم باستلام الطلب منك اولا.',
    'Find your order in Exchanges!': 'تستطيع متابعه طلبك في طلبات التوصيل.',
    'Performance': 'الاداء',
    'Merchant Profile': 'ملف التاجر',
    'Financials': 'الحسابات',
    'Tax Profile': 'الملف الضريبى',
    'Payout Methods': 'طرق الدفع',
    'Tax Registration Number': 'رقم التسجيل الضريبى',
    'Building Number': 'رقم المبنى',
    'Legal Name': 'الاسم القانونى',
    'Street': 'الشارع',
    'Tax Profile Type': 'نوع الملف الضريبى',
    'Exchange Request': 'طلب استبدال',
    'Select the delivered order you want, and pickup to exchange!': 'قم بتحديد الطلب المراد استبداله و اطلب بديل من الخيارات!',
    'Upload Accepted E-Invoices': 'تحميل الفواتير الإلكترونية المقبولة',
    'Export E-Invoices': 'استخراج الفواتير الإلكترونية',
    ' field is required': ' هذا الحقل مطلوب',
    ' field only accepts english letters': ' هذا الحقل يقبل الحروف والأرقام الانجليزية فقط',
    'Tax profile only accepts english letters': 'يجب كتابة اسم الشركة والعنوان بالحروف والأرقام الانجليزية فقط',
    'The legal name': '',
    'The governorate': '',
    'The city': '',
    'The street': '',
    'The building number': '',
    'The full name': '',
    'The tax registration number': '',
    'The tax registration number only accepts english numbers': 'هذا الحقل يقبل ارقام فقط',
    'Export Task Lists': 'استخراج مهام الشحن',
    'Select agent type!': 'حدد نوع المندوب',
    'Select your export range!': 'حدد نطاق التصدير الخاص بك',
    'delivery-orders': 'طلبات التوصيل',
    'cash-collections': 'طلبات تحصيل النقود',
    'returns': 'المرتجعات',
    'exchanges': 'طلبات الاستبدال',
    'orders': 'قائمة الطلبات',
    'orders-awaiting-action': 'محاولات توصيل الطلبات',
    'Delivery Operations': 'عمليات التسليم',
    'Fulfillment Operations': 'عمليات التخزين',
    'Fulfillment Dashboard': 'نظرة عامة',
    'Product Catalog': 'قائمة المنتجات',
    'Stock Requests': 'طلبات اضافة للمخزون',
    'Fulfillment Orders': 'طلبات تسليم',
    'Inventory': 'مستويات المخزون',
    'Reports': 'التقارير',
    'Delivery Pricing Packages': 'باقات أسعار التوصيل',
    'Fulfillment Packages': 'باقات أسعار التخزين',
    'Are you sure to remove this product?': 'هل انت متأكد من ازالة هذا المنتج؟',
    'Product': 'المنتج',
    ' has been subscribed successfully!': ' تم الاشتراك بنجاح!',
    'Stock request': 'طلب التخزين',
    'Stock Request View': 'عرض طلب النخزين',
    'FC Operations Supervisor': 'مدير عمليات التخزين',
    'Add FC Operations Supervisor': 'أضف مدير عمليات التخزين',
    'Edit FC Operations Supervisor': 'تعديل مدير عمليات التخزين',
    'Stock Request Info': 'تفاصيل طلب التحزين',
    'SBSIN': 'SBSIN',
    'SKU': 'SKU',
    'SKU Code': 'SKU رمز',
    'Group': 'المجموعة',
    'Weight (kg)': 'الوزن (كجم)',
    'Height (cm)': 'الطول (سم)',
    'Width (cm)': 'العرض (سم)',
    'Length (cm)': 'الارتفاع (سم)',
    'Channels': 'منصات البيع',
    'Supplier': 'المورد',
    'Product Group': 'مجموعة المنتج',
    'Current Inventory': 'المخزون الحالى',
    'Reorder count': 'الحد الأدني لطلب زيادة المخزون',
    'New Product': 'منتج جديد',
    'Edit Product': 'تعديل المنتج',
    'Generate': 'اقترح رقم تلقائي',
    'Product Name': 'اسم المنتج',
    'Color': 'اللون',
    'Dimensions': 'الأبعاد',
    'Weight': 'الوزن',
    'Incoming Quantity': 'الكمية المرسلة',
    'Received Quantity': 'الكمية المستقبلة',
    'Flags': 'علامات مميزة',
    'Fulfillment Center': 'مخزن',
    'Number Of Vehicles': 'عدد عربات الشحن',
    'Add New Product': 'اضافة منتج جديد',
    'Add more products': 'أضف المزيد من المنتجات',
    'By': 'بواسطة',
    'WROs': 'طلبات الاستلام بالمخزن',
    'Request ID': 'رقم الطلب',
    'Number of SKUs': 'عدد المنتجات',
    'Total Quantity': 'اجمالي الكميات',
    'Received On': 'تم استلامها في',
    'Sales': 'مبيعات',
    'Roles': 'الأدوار',
    'Ready to Send': 'جاهزة للارسال',
    'Current Inventory Level': 'مستوي المخزون الحالي',
    'Assiut Warehouse': 'مخزن أسيوط',
    'Gesr El Suez Warehouse': 'مخزن جسر السويس',
    'merchant_name': 'اسم التاجر',
    'customer_name': 'اسم العميل',
    'item_count': 'عدد الطرود',
    'Master Bag': 'حقيبه شحن',
    'Supply Request': 'مواد التغليف',
    'number_of_packages': 'عدد الطرود',
    'El Kilo 21 - 21 الكيلو': '21 الكيلو',
    'El-Bitash Qbli - البيطاش قبلي': 'البيطاش قبلي',
    'El Soyof Qbli - السيوف قبلي': 'السيوف قبلي',
    'Borj El Arab - برج العرب': 'برج العرب',
    'جامعة القاهرة - Cairo University': 'جامعة القاهرة',
    'البحر الاعظم - Al Bahr Al Aazam': 'البحر الاعظم',
    'الكنيسة - Al Kenisah': 'الكنيسة',
    'سقارة - Sakaraa': 'سقارة',
    'خاتم المرسلين - Khatem El Morsaleen': 'خاتم المرسلين',
    'المريوطية - Maryotia': 'المريوطية',
    'الرماية - Rimaya': 'الرماية',
    'الطالبية - Eltalbia': 'الطالبية',
    'العمرانية - El Omraniya': 'العمرانية',
    'المنيب - Elmoneeb': 'المنيب',
    'مصر الجديدة-النزهة الجديدة - Heliopolis-New Nozha': 'مصر الجديدة-النزهة الجديدة',
    'مصر الجديدة-الماظة - Heliopolis-Almaza': 'مصر الجديدة-الماظة',
    'مصر الجديدة-الحجاز - Heliopolis-Elhegaz': 'مصر الجديدة-الحجاز',
    'مصر الجديدة-كلية البنات - Heliopolis-Kolet Elbanat': 'مصر الجديدة-كلية البنات',
    'مصر الجديدة-منشية البكري - Heliopolis-Manshet Elbakry': 'مصر الجديدة-منشية البكري',
    'مصر الجديدة-روكسي - Heliopolis-Roxy': 'مصر الجديدة-روكسي',
    'المعادي-كورنيش المعادي - Maadi-Ridge': 'المعادي-كورنيش المعادي',
    'المعادي-المعادي الجديدة - Maadi-New Maadi': 'المعادي-المعادي الجديدة',
    'المعادي-صقر قريش - Maadi-Sakr Korish': 'المعادي-صقر قريش',
    'المعادي-البساتين - Maadi-Elbasateen': 'المعادي-البساتين',
    'المعادي-دار السلام - Maadi-Dar Elsalam': 'المعادي-دار السلام',
    'المعادي-ثكنات المعادي - Maadi-Sakanat': 'المعادي-ثكنات المعادي',
    'المعادي-دجلة - Maadi-Degla': 'المعادي-دجلة',
    'المعادي-اللاسلكي - Maadi-Ellaselky': 'المعادي-اللاسلكي',
    'المعادي-المعراج - Maadi-Elmaaraj': 'المعادي-المعراج',
    'المعادي-الزهراء - Maadi-Zahraa': 'المعادي-الزهراء',
    'مدينة نصر الأوتوستراد - Nasr City Autostrad': 'مدينة نصر الأوتوستراد',
    'مدينة نصر مساكن الظباط - Nasr City Masakin Aldubbat': 'مدينة نصر مساكن الظباط',
    'مدينة نصر الواحة - Nasr City Alwaha': 'مدينة نصر الواحة',
    'مدينة نصر الحى الثامن- Nasr City 8th District': 'مدينة نصر الحى الثامن',
    'زهراء مدينة نصر - Zahraa Nasr City': 'زهراء مدينة نصر',
    'مدينة نصر الحي العاشر - Nasr City 10th District': 'مدينة نصر الحي العاشر',
    'مدينة نصر المقاولين العرب - Nasr City Arab Contractors': 'مدينة نصر المقاولين العرب',
    'مدينة نصر الوفاء والأمل - Nasr City Elwafa w Alamal': 'مدينة نصر الوفاء والأمل',
    'مدينة نصر الجبل الأحمر - Nasr City Aljabal Alahmar': 'مدينة نصر الجبل الأحمر',
    'مدينة نصر الحى السابع - Nasr City 7th District': 'مدينة نصر الحى السابع',
    'مدينة نصر عباس العقاد - Nasr City Abbas ElAkkad': 'مدينة نصر عباس العقاد',
    'مدينة نصر - Nasr City Emtedad Ramsis': 'مدينة نصر',
    'مدينة نصر منشية ناصر - Nasr City Monshaat Naser': 'مدينة نصر منشية ناصر',
    'مدينة نصر الأستاد - Nasr City Cairo Stadium': 'مدينة نصر الأستاد',
    'مدينة نصر جامعة الأزهر - Nasr City Al Azhar University': 'مدينة نصر جامعة الأزهر',
    'مدينة نصر الحي السادس - Nasr City 6th District': 'مدينة نصر الحي السادس',
    'مركز منيا القمح': 'مركز منيا القمح',
    'مركز مشتول السوق': 'مركز مشتول السوق',
    'مركز كفر صقر': 'مركز كفر صقر',
    'مركز ههيا': 'مركز ههيا',
    'مركز فاقوس': 'مركز فاقوس',
    'مركز ديرب نجم': 'مركز ديرب نجم',
    'مركز بلبيس': 'مركز بلبيس',
    'مركز أولاد صقر': 'مركز أولاد صقر',
    'مركز الابراهيمية': 'مركز الابراهيمية',
    'مركز الحسينية': 'مركز الحسينية',
    'مركز أبوكبير': 'مركز أبوكبير',
    'مركز أبو حماد': 'مركز أبو حماد',
    'مركز الزقازيق': 'مركز الزقازيق',
    'مركز القناطر الخيرية': 'مركز القناطر الخيرية',
    'مركز قليوب': 'مركز قليوب',
    'مركز قها': 'مركز قها',
    'مركز شبين القناطر': 'مركز شبين القناطر',
    'مركز طوخ': 'مركز طوخ',
    'مركز كفر شكر': 'مركز كفر شكر',
    'مركز تلا': 'مركز تلا',
    'مركز قويسنا': ' مركز قويسنا',
    'مركز منوف': 'مركز منوف',
    'مركز بركة السبع': 'مركز بركة السبع',
    'مركز الشهداء': 'مركز الشهداء',
    'مركز أشمون': 'مركز أشمون',
    'مركز الباجور': 'مركز الباجور',
    'قسم بورفؤاد': 'قسم بورفؤاد',
    'قسم شرطة الزهور': 'قسم شرطة الزهور',
    'قسم الشرق': 'قسم الشرق',
    'قسم المناخ': 'قسم المناخ',
    'قسم الجنوب': 'قسم الجنوب',
    'قسم العرب': 'قسم العرب',
    'كفر سعد': 'كفر سعد',
    'قسم شرطة الضواحي': 'قسم شرطة الضواحي',
    'البطيخ': 'البطيخ',
    'الزرقا': 'الزرقا',
    'فارسكور': 'فارسكور',
    'دمياط': 'دمياط',
    'مركز شبراخيت': 'مركز شبراخيت',
    'مركز رشيد': 'مركز رشيد',
    'مركز كوم حمادة': 'مركز كوم حمادة',
    'مركز كفر الدوار': 'مركز كفر الدوار',
    'مركز إيتاى البارود': 'مركز إيتاى البارود',
    'مركز إدكو': 'مركز إدكو',
    'مركز حوش عيسى': 'مركز حوش عيسى',
    'مركز ابو المطامير': 'مركز ابو المطامير',
    'مركز المحمودية': 'مركز المحمودية',
    'مركز الدلنجات': 'مركز الدلنجات',
    'مركز ابوحمص': 'مركز ابوحمص',
    'مركز زفتى': 'مركز زفتى',
    'مركز سمنود': 'مركز سمنود',
    'مركز قطور': 'مركز قطور',
    'مركز كفر الزيات': 'مركز كفر الزيات',
    'مركز بسيون': 'مركز بسيون',
    'مركز السنطة': 'مركز السنطة',
    'مركز منية النصر': 'مركز منية النصر',
    'مركز دكرنس': 'مركز دكرنس',
    'مركز المنزلة': 'مركز المنزلة',
    'مركز السنبلاوين': 'مركز السنبلاوين',
    'مركز أجا': 'مركز أجا',
    'قسم ميت غمر': 'قسم ميت غمر',
    'مركز نبروة': 'مركز نبروة',
    'مركز شربين': 'مركز شربين',
    'مركز بلقاس': 'مركز بلقاس',
    'El Soyof Bahree - السيوف بحري': 'السيوف بحري',
    'المنصورة': 'المنصورة',
    'مركز طلخا': 'مركز طلخا',
    'مركز بنها': 'مركز بنها',
    'مركز شبين الكوم': 'مركز شبين الكوم',
    'مركز المحلة الكبرى': 'مركز المحلة الكبرى',
    'مركز طنطا': 'مركز طنطا',
    'مركز دمنهور': 'مركز دمنهور',
    'مركز بيلا': 'مركز بيلا',
    'مركز دسوق': 'مركز دسوق',
    'مركز الرياض': 'مركز الرياض',
    'مركز فوه': 'مركز فوه',
    'مركز الحامول': 'مركز الحامول',
    'مركز كفر الشيخ': 'مركز كفر الشيخ',
    'مركز مطوبس': 'مركز مطوبس',
    'مركز قلين': 'مركز قلين',
    'مركز سيدى سالم': 'مركز سيدى سالم',
    'مركز أسيوط': 'مركز أسيوط',
    'مركز أبنوب': 'مركز أبنوب',
    'مركز ساحل سليم': 'مركز ساحل سليم',
    'مركز صدفا': 'مركز صدفا',
    'مركز منفلوط': 'مركز منفلوط',
    'مركز القوصية': 'مركز القوصية',
    'مركز الغنايم': 'مركز الغنايم',
    'مركز الفتح': 'مركز الفتح',
    'مركز البدارى': 'مركز البدارى',
    'مركز ديروط': 'مركز ديروط',
    'مركز أبوتيج': 'مركز أبوتيج',
    'Customer name, ': 'اسم العميل',
    'Tracking number, ': 'الرقم المرجعي',
    'or reference number...': ' أو الرقم المرجعي ...',
    'We will automatically create a fulfillment order for you. Once pickup is requested, the fulfillment order is requested to ship.': 'هنضيفلك أوردر التخزين أوتوماتيك، وأول ما تطلب استلام، هنطلب أن أوردر التخزين يتجهز.',
    'Fulfilling with us?': 'بتخزن معانا؟',
    'Update Products': 'تحديث المنتجات',
    'Add products': 'اضافة منتجات',
    'fulfillment products': 'تخزين المنتجات',
    'Merchant Display Name': 'الاسم التجارى',
    'Cannot create/update fulfillment order after delivery order creation.': 'لا يمكن اضافة أو تعديل طلب تخزين بعد طلب استلام طلب التوصيل.',
    'Cannot create/update fulfillment order after return order creation.': 'لا يمكن اضافة أو تعديل طلب تخزين بعد طلب استلام طلب الارتجاع.',
    'URO View': 'عرض طلبات التخزين الغير معرفة',
    'URO Info': 'معلومات طلب التخزين الغير معرف',
    'Main Stock Request': 'طلب المخزون الرئيسي',
    'New stock Request': 'طلب مخزون جديد',
    'UROs': 'طلبات تخزين غير معرفة',
    'Should return at least one product': 'يجب ارجاع منتج واحد على الأقل',
    'This package has been scan successfully': 'تم مسح الطرد بنجاح',
    'Upload Bank Deposits': 'رفع ايداعات بنكيه',
    'Successful Deliveries': 'توصيلات تمت',
    'Total Packages': 'اجمالي الطرود',
    'Gross Revenue + VAT': 'الدخل الاجمالي بالضرائب',
    'Gross Revenue': 'الدخل الاجمالى',
    'Failed Attempts': 'محاولات التوصيل',
    'Net Revenue': 'الدخل الصافى',
    'Total CoD Collected To Date': 'اجمالي تحصيل النقود لليوم',
    'Cancel Shipments': 'الغاء الطلب',
    'Select orders criteria you want to search!': 'اختر فلتر للطلبات للبحث',
    'Select orders statuses you want to export!': 'اختر حالات الطلبات لاستخراجها',
    'Select your search range!': 'اختر الفترة الزمنية للبحث',
    'No Date': 'لا يوجد تاريخ',
    'Return Attempts': 'محاولات الاستلام',
    'Customer': 'العميل',
    'All Logs': 'سجل التعديلات',
    'Hide logs ..': 'اخفاء التعديلات..',
    'Show logs ..': 'اظهار التعديلات..',
    'Outcoming Quantity': 'الكمية الصادرة',
    'Product SKU': 'كود المنتج',
    'Outbound Products': 'المنتجات الصادرة',
    'Inbound Products': 'المنتجات المرسلة',
    'Account Manager': 'Account Manager',
    'Subscribe to Fulfillment': 'اشترك في خدمة التخزين',
    'Committed Volume': 'حجم التعاقد',
    'Force Scan Package Size Barcode': 'فرض مسح حجم الشحنة بالباركود',
    'Access Financials': 'صلاحية الاطلاع علي الحسابات',
    'Delivery Pricing': 'باقات أسعار التوصيل',
    'Fulfillment Pricing': 'باقات أسعار التحزين',
    'Default': 'Default',
    'Set Default': 'Set Default',
    'Opening Balance': 'الرصيد الافتتاحي',
    'Wallet Number': 'رقم المحفظة',
    'Bank Name': 'اسم البنك',
    'Bank': 'البنك',
    'Wallet': 'المحفظة',
    'Visit Days': 'أيام التوصيل',
    'Primary Warehouse': 'المخزن الرئيسي',
    'Select All Zones': 'تحديد كل المناطق',
    'Today’s Last-mile': 'توصيلات اليوم',
    'Today’s First-mile': 'استلامات اليوم',
    'Today’s Financials': 'حسابات اليوم',
    'Customer Pickups': 'مرتجعات من العملاء',
    'Merchant Pickups': 'استلامات من التجار',
    'Merchant Returns': 'مرتجعات إلي التجار',
    'In-House Cash EGP': 'رصيد خزنة المخزن',
    'COD EGP': 'COD جنيه',
    'Refunds EGP': 'Refunds جنيه',
    'Cash Collections EGP': 'Cash Collections جنيه',
    'Warehouse Stats': 'احصائيات المخزن',
    'In-Coming Orders': 'شحنات قادمة',
    'In-House RTOs': 'شحنات مرتجعة للتاجر',
    'Incoming Master Bags': 'حقائب شحنات قادمة',
    'Outgoing Master Bags': 'حقائب شحنات مرسلة',
    'DW': 'وقت التسليم المفضل',
    'GPS': 'GPS',
    'PD': 'يوم التسليم المفضل',
    'Return request date': 'تاريخ طلب الارتجاع',
    'Return attempts': 'محاولات الاستلام',
    'Return date': 'تاريخ الارتجاع',
    'Request Date': 'تاريخ الطلب',
    'Col - Attempts': 'محاولات تحصيل النقود',
    'Col - Date': 'تاريخ التحصيل',
    'Actual Quantity': 'الكمية الفعلية',
    'Cash Requests': 'الطلبات النقدية',
    'Today\'s task list': 'مهام الشحن اليومية',
    'Previous task lists': 'مهام الشحن السابقة',
    'First Mile': 'مندوب التاجر',
    'Last Mile': 'مندوب العميل',
    'Select a date': 'اختر التاريخ',
    'Filter': 'تصفية',
    'In-House Orders': 'الطلبات بالمخزن',
    'Details': 'التفاصيل',
    'Action': 'الاجراء',
    'Code / Tracking No.': 'الرمز / رقم التتبع',
    'Products': 'المنتجات',
    'Regular': 'عادى',
    'Total Flyers Selected:': 'العدد الكلي لمواد التغليف:',
    'SLA': 'SLA',
    'Online Orders': 'طلبات أونلاين',
    'Update CoD': 'تعديل الCOD',
    'Update Packages': 'تعديل الطرود',
    'Update SLA': 'تعديل الSLA',
    'Open Packages': 'يسمح بفتح الشحنة',
    'Generate Magic Link': 'انشاء رابط ماجيك',
    'New Stock Request': 'طلب مخزون جديد',
    'Are you sure to delete?': 'هل انت متأكد انك تريد الحذف؟',
    'Unidentified Stock Requests': 'طلبات تخزين غير معرفة',
    'Stock Request': 'طلب التخزين',
    'Product Description': 'وصف المنتج',
    'Confirm to Ship': 'تأكيد الشحن',
    'Return Orders': 'طلبات ارتجاع',
    'Order ID': 'رقم الطلب',
    'No. of Items': 'عدد العناصر',
    'inbound': 'الوارد',
    'outbound': 'الصادر',
    'Packaging': 'التغليف',
    'Shipping Info': 'معلومات الشحن',
    'other': 'اخر',
    'Inbound Order': 'طلب ارتجاع',
    'Outbound Order': 'طلب تسليم',
    'Merchant Reference': 'الرقم المرجعي',
    'Add packaging': 'اضافة مواد تغليف',
    'Account name': 'اسم الحساب',
    'The Legal Name': 'الاسم القانونى',
    'The Tax Registration Number': 'رقم السجل الضريبى',
    'eCommerce Store': 'eCommerce Store',
    'Endpoint': 'Endpoint',
    'Subscribed To': 'Subscribed To',
    'Payload': 'Payload',
    'Response': 'Response',
    'Slot Title': 'عنوان الرف',
    'No Space': 'لا يوجد مساحة',
    'View Bin': 'عرض سلات النقل',
    'Supply Inventory': 'مخزون',
    'Add Comment': 'اضافة تعليق',
    'Comment': 'تعليق',
    'CoD Balance': 'رصيد تحصيل النقود',
    'Service fees & Refunds due': 'رسوم الخدمة وسداد المرتجعات',
    'Net cash balance': 'صافي الرصيد النقدي',
    'Quickbooks last updated': 'اخر تحديث',
    'Drop Json File or': 'اسقاط ملف JSON او',
    'Payout Method': 'طريقة الدفع',
    'No. Merchants': 'عدد التجار',
    'Total Usage Count': 'اجمالي عدد الاستخدامات',
    'Total Usage Value': 'اجمالي قيمة الاستخدامات',
    'Threshold': 'الحد الأدني',
    'Time Window': 'وقت التوصيل',
    'Actual sent quantity': 'الكمية الفعلية المرسلة',
    'Create URO': 'اضافة طلب تخزين غير معرف',
    'Add URO': 'اضافة طلب تخزين غير معرف',
    'Inbound Orders': 'طلبات ارتجاع',
    'Outbound Orders': 'طلبات تسليم',
    'Update Product': 'تحديث المنتج',
    'Add Customer Support': 'اضافة خدمة عملاء',
    'Edit Customer Support': 'تعديل خدمة العملاء',
    '(Optional)': '(اختيارى)',
    'Reserved Inventory': 'المخزون المحجوز',
    'Total Current Inventory': 'اجمالي المخزون الحالي',
    'Total products': 'اجمالي عدد المنتجات',
    'Total Reserved Inventory': 'اجمالي المخزون المحجوز',
    'Sync Quickbooks': 'Sync Quickbooks',
    'Outbound Linked Tracking Number': 'رقم تتبع التسليم',
    'Inbound Linked Tracking Number': 'رقم تتبع الإرجاع',
    'Are you sure you want to confirm receiving': 'Are you sure you want to confirm receiving',
    'Product(s)?': 'Product(s)?',
    'Are you sure you want to cancel this stock request?': 'Are you sure you want to cancel this stock request?',
    'Your Active Insurance Plan': 'الباقه التأمينية الخاصة بك',
    'Insurance Fees / Order': 'رسوم التأمين لكل طلب التوصيل',
    'Payout Coverage': 'تغطية المدفوعات لطلب التوصيل',
    'Your default declared value :': 'القيمة الثابتة للمنتجات :',
    'Your default declared value': 'القيمة الثابتة للمنتجات',
    'Disable': 'تعطيل',
    'Insurance Plans': 'باقات شب بلو',
    'Submit Plan': 'تأكيد الباقة',
    'ShipBlu Ensures The Safe Transport Of Your Goods And Its Responsibilty For Lossess Or Damages Due To Negligence.': 'تضمن شيب بلو النقل الامن لبضائعك و مسؤوليتها عن الخسائر أو الاضرار الناتجة أثناء النقل',
    'Insurance Fees:': 'رسوم التأمين:',
    'The Insurance service fees per order': 'تكلفه الخدمه لكل طلب',
    'Payout Coverage %:': 'نسبة تغطية المدفوعات %:',
    'The Compensation amount in case of any loss or damage': 'مبلغ التعويض في حالة ضياع او تلف الشحنات.',
    'Your default declared value:': 'القيمة المعلنة الافتراضية الخاصه بك:',
    'Incase you have created an order with no cod or declared value,we will apply the default declared value': 'في حالة انشاء طلب بدون COD أو قيمة معلنة , فسنطبق القيمة المعلنة الافتراضية .',
    'Please Choose One Of The Below Insurance Plans': 'الرجاء اختيار واحدة من باقات التأمين أدناه .',
    'Insurance Fees Per Order': 'رسوم التأمين لكل طلب توصيل',
    'Payout Coverage For Order Up To': 'تغطية المدفوعات لطلب التوصيل حتي',
    'If COD For Order = 0.00 EGP': 'اذا كان الدفع عند الاستلام للطلب = 0.00 جم',
    'I Agree With The': 'أنا أوافق علي',
    'Terms and Conditions': 'الشروط و الأحكام',
    'Are you Syre That You Want This Insurance Plan ?': 'هل أنت متاكد أنك تريد خطة التأمين هذه ؟',
    ' Insurance Fees Per Order - ': ' رسوم التأمين لكل طلب توصيل - ',
    ' Payout Coverage':  ' نسبة تغطية المدفوعات',
    'Insurance History': 'سجل التأمين',
    'Insurance Package Name': 'أسم باقه التأمين',
    'Effective Date': 'تاريخ التنفيذ',
    'Are you Sure That You Want This Insurance Plan?': 'هل أنت متأكد أنك تريد خطة التأمين هذه؟',
    'Are you Sure That You Want To Disable This Insurance Plan?': 'هل أنت متأكد أنك تريد تعطيل خطة التأمين الخاصة بك؟',
    'ShipBlu Shield': 'باقات حماية شب بلو',
    'Are you sure you want to confirm inbounding': 'Are you sure you want to confirm inbounding',
    'ShipBlu Shield Fees': 'رسوم باقات حماية شب بلو',
    'Are you sure you want to cancel this supply?': 'Are you sure you want to cancel this supply?',
    'URO': 'طلبات التخزين الغير معرفة',
    'Operation Timeout. Please, try again!': 'العملية لم تكتمل. برجاء المحاولة مرة أخري',
    'Mobile Wallet': 'محفظة الهاتف المحمول',
    'Access Payout Methods': 'صلاحية طرق الدفع',
    'seconds': 'ثانية',
    'Confirm Account Number': 'أكد رقم الحساب',
    'Add Payout': 'إضافة الدفع',
    'Didn’t receive code?': 'ألم تتلق رمزًا؟',
    'Enter OTP Code sent to': 'أدخل رمز OTP المرسل إلى',
    'OTP Verification': 'التحقق من OTP',
    'Resend code': 'اعادة إرسال الرمز',
    'Next Statement Issuing Day': 'يوم إصدار الكشوفات',
    'Next Transfer Day': 'يوم التحويل المصرفي',
    'Your Statements & Bank Transfers Full Schedule': 'كشف الحساب الكامل و جدول التحويل المصرفي',
    'Bank Transfer Days': 'أيام التحويل المصرفي',
    'Statements Issuing Days': 'أيام إصدار الكشوفات',
    'from the previous week': 'من الأسبوع السابق',
    'Transfer covers': 'يغطي تحويل',
    'Monday': 'الإثنين',
    'Tuesday': 'الثلاثاء',
    'Wednesday': 'الأربعاء',
    'Thursday': 'الخميس',
    'Friday': 'الجمعة',
    'Saturday': 'السبت',
    'Sunday': 'الأحد',
    'Qty': 'كمية',
    'when you create a new order, do you want it to be pickup requested automatically or manually?': 'عند إنشاء طلب جديد ، هل تريد أن يتم طلب الاستلام تلقائيًا أو يدويًا؟',
    'Your choice will become the default and you can change it from your account settings': 'سيصبح اختيارك هو الخيار الافتراضي ويمكنك تغييره من إعدادات حسابك',
    'Manual Pickup Request': 'طلب استلام يدوي',
    'Your newly created orders will be on "Created" status till you manually change them to "Pickup Requested"': 'ستكون طلباتك التي تم إنشاؤها حديثًا في حالة "تم الإنشاء" حتى تقوم بتغييرها يدويًا إلى "تم الاستلام"',
    'Your newly created orders will be on "pickup requested - return requested - collection requested" status automatically': 'ستكون طلباتك التي تم إنشاؤها حديثًا في حالة "طلب الاستلام - طلب الاسترجاع - طلب التحصيل النقدي" تلقائيًا',
    'make as default': 'اجعله الافتراضي',
    'Order Settings': 'إعدادات الطلب',
    'Pickup Request Default': 'طلب استلام إفتراضي',
    'Pickup Request preference updated successfully': 'تم تحديث اختياركم بنجاح',
    'Download Statement': 'تحميل البيانات',
    'The date we issue your next statement': 'تاريخ الذي نصدر فيه بيانك التالي',
    'The date we transfer the collected cash': 'تاريخ تحويل المبالغ النقدية المحصلة',
    'Collected & not yet transfered': 'ما تم تحصيله (ولم يتم تحويله)',
    'The cash we collected but not yet processed into a statement': 'المبالغ النقدية التي جمعناها ولكن لم تتم معالجتها بعد في بيان',
    'Cash on the way': 'قيمة التحويل القادم',
    'The cash amount we will transfer to you': 'المبلغ النقدي الذي سنقوم بتحويله إليك',
    'Transfer Details': 'تفاصيل التحويل',
    ',': '،',
    'add': 'اضافه',
    'edit': 'تعديل',
    'Picking Bins': 'صناديق الانتقاء',
    'The Transfer details will be available once the amount is successfully transferred to your account': 'ستكون تفاصيل التحويل متاحة بمجرد تحويل المبلغ بنجاح إلى حسابك',
    'Minya El Qamh - منيا القمح': 'منيا القمح',
    'Minya - المنيا': 'المنيا',
    'Suhaj - سوهاج': 'سوهاج',
    'Qina - قنا': 'قنا',
    'Al Montazah 1st - حى المنتزه اول': 'حى المنتزه اول',
    'Bahary – بحري': 'بحري',
    'Burj Al Arab and Al Ameria - برج العرب و العامرية': 'برج العرب و العامرية',
    'Al Awaid - العوايد': 'العوايد',
    'District East - حى شرقى': 'حى شرقى',
    'Al-Raml district - حى الرمل': 'Al-Raml district',
    'Mashtool El Souk - مشتول السوق': 'مشتول السوق',
    'Kafr Saqr - كفر صقر': 'كفر صقر',
    'Hihya - ههيا': 'ههيا',
    'Faqous - فاقوس': 'فاقوس',
    'Diyarb Negm - ديرب نجم': 'ديرب نجم',
    'Bilbeis - بلبيس': 'بلبيس',
    'Awlad Saqr - أولاد صقر': 'أولاد صقر',
    'ElIbrahimiya - الابراهيمية': 'الابراهيمية',
    'ElHusseiniya - الحسينية': 'الحسينية',
    'Abu Kabir - أبو كبير': 'أبو كبير',
    'Abu Hammad - أبو حماد': 'أبو حماد',
    'ELZagazig - الزقازيق': 'الزقازيق',
    'ElKanater ElKhayreya - القناطر الخيرية': 'القناطر الخيرية',
    'Qalyub - قليوب': 'قليوب',
    'Qaha - قها': 'قها',
    'Shebeen ElKanater - شبين القناطر': 'شبين القناطر',
    'Tukh - طوخ': 'طوخ',
    'Kafr Shokr - كفر شكر': 'كفر شكر',
    'Tala - تلا': 'تلا',
    'Quesna - قويسنا': 'قويسنا',
    'Menouf - منوف': 'منوف',
    'Berket ElSabaa - بركة السبع': 'بركة السبع',
    'ElShohdaa - الشهداء': 'الشهداء',
    'Ashmoun - أشمون': 'أشمون',
    'Portsaid - بورسعيد': 'بورسعيد',
    'ElBagor - الباجور': 'الباجور',
    'Kafr Saad - كفر سعد': 'كفر سعد',
    'Kafr ElBateekh - كفر البطيخ': 'كفر البطيخ',
    'ElZarka - الزرقا': 'الزرقا',
    'Faraskour - فارسكور': 'فارسكور',
    'Damietta - دمياط': 'دمياط',
    'Shabrakheet - شبراخيت': 'شبراخيت',
    'Rasheed - رشيد': 'رشيد',
    'Kom Hamada - كوم حمادة': 'كوم حمادة',
    'Kafr Aldawar - كفر الدوار': 'كفر الدوار',
    'Etay Albarood - إيتاى البارود': 'إيتاى البارود',
    'Edko - إدكو': 'إدكو',
    'Hosh Eissa - حوش عيسى': 'حوش عيسى',
    'Abo Almatameer - ابو المطامير': 'ابو المطامير',
    'Almahmoudia - المحمودية': 'المحمودية',
    'Aldalangat - الدلنجات': 'الدلنجات',
    'Abo Hommos - ابوحمص': 'ابوحمص',
    'ElRiyadh - الرياض': 'الرياض',
    'Mutuwbas - مطوبس': 'مطوبس',
    'Keleen - قلين': 'قلين',
    'ElHamoul - الحامول': 'الحامول',
    'Sidi Salem - سيدى سالم': 'سيدى سالم',
    'Biyla - بيلا': 'بيلا',
    'Fuwah - فوه': 'فوه',
    'Dessouk - دسوق': 'دسوق',
    'Zeftah - زفتى': 'زفتى',
    'Samanoud - سمنود': 'سمنود',
    'Ketour - قطور': 'قطور',
    'Kafr ElZayat - كفر الزيات': 'كفر الزيات',
    'Basyoun - بسيون': 'بسيون',
    'ElSantah - السنطة': 'السنطة',
    'Minyet ElNasr - منية النصر': 'منية النصر',
    'Dikrinis - دكرنس': 'دكرنس',
    'ElManzalah - المنزلة': 'المنزلة',
    'Simbalween - السنبلاوين': 'السنبلاوين',
    'Aga - أجا': 'أجا',
    'Mit Ghamr - ميت غمر': 'ميت غمر',
    'Nabarwuah - نبروة': 'نبروة',
    'Sherbin - شربين': 'شربين',
    'Belkas - بلقاس': 'بلقاس',
    'Talkha - طلخا': 'طلخا',
    'Hurghada - الغردقة': 'الغردقة',
    'Suez - السويس': 'السويس',
    'Ismailiya - الاسماعيلية': 'الاسماعيلية',
    'Beni Suef - بني سويف': 'بني سويف',
    'Fayoum - الفيوم': 'الفيوم',
    'Assiut - أسيوط': 'أسيوط',
    'Kafr ElSheikh - كفر الشيخ': 'كفر الشيخ',
    'Tanta - طنطا': 'طنطا',
    'ElMahala ElKobra - المحلة الكبرى': 'المحلة الكبرى',
    'Banha - بنها': 'بنها',
    'Mansoura - المنصورة': 'المنصورة',
    'Sahel - الساحل': 'الساحل',
    'East Alex & Montazah - شرق الاسكندرية والمنتزة': 'شرق الاسكندرية والمنتزة',
    'Downtown Alex & Gomrok - وسط البلد والجمرك': 'وسط البلد والجمرك',
    'New Cairo - القاهرة الجديدة': 'القاهرة الجديدة',
    'El Mandara Qibli - المندرة قبلي': 'المندرة قبلي',
    'El Asafra Qibli - العصافرة قبلي': 'العصافرة قبلي',
    'Sidi Bishr Qibli - سيدي بشر قبلي': 'سيدي بشر قبلي',
    'Oct Degla Palms - دجلة بالمز': 'دجلة بالمز',
    'Oct Industrial Zone - المنطقة الصناعية أكتوبر': 'المنطقة الصناعية أكتوبر',
    'Oct Palm Hills - بالم هيلز أكتوبر': 'بالم هيلز أكتوبر',
    'October Gardens - حدايق أكتوبر': 'حدايق أكتوبر',
    'El Amiria - الأميرية': 'الأميرية',
    'El Zawia El Hamra - الزاوية الحمراء': 'الزاوية الحمراء',
    'El Sharabia - الشرابية': 'الشرابية',
    'El Sabtiyyah - السبتية': 'السبتية',
    '15th May - ١٥ مايو': '١٥ مايو',
    'Saft Al Laban - صفط اللبن': 'صفط اللبن',
    'Bashtil - بشتيبل': 'بشتيبل',
    'Al Barajil - البرجيل': 'البرجيل',
    'El Waraaq - الوراق': 'الوراق',
    'Mivida - مافيدا': 'مافيدا',
    'Mountain View - ماونتن فيو': 'ماونتن فيو',
    '1st Settlement El Merage city - التجمع الأول الميراج': 'التجمع الأول الميراج',
    'El Kilo 21 - الكيلو ٢١': 'الكيلو ٢١',
    'El Bitash Qbli - البيطاش قبلي': 'البيطاش قبلي',
    'El Soyof Qibli - السيوف قبلي': 'السيوف قبلي',
    'Cairo University - جامعة القاهرة': 'جامعة القاهرة',
    'Al Bahr Al Aazam - البحر الأعظم': 'البحر الأعظم',
    'Al Kenisah - الكنيسة': 'الكنيسة',
    'Sakaraa - سقارة': 'سقارة',
    'Khatem El Morsaleen - خاتم المرسلين': 'خاتم المرسلين',
    'Maryotia - المريوطية': 'المريوطية',
    'Rimaya - الرماية': 'الرماية',
    'Eltalbia - الطالبية': 'الطالبية',
    'El Omraniya - العمرانية': 'العمرانية',
    'Elmoneeb - المنيب': 'المنيب',
    'Heliopolis New Nozha - مصر الجديدة النزهة الجديدة': 'مصر الجديدة النزهة الجديدة',
    'Heliopolis Almaza - مصر الجديدة الماظة': 'مصر الجديدة الماظة',
    'Heliopolis Elhegaz - مصر الجديدة الحجاز': 'مصر الجديدة الحجاز',
    'Heliopolis Ard El Golf - مصر الجديدة أرض الجولف': 'مصر الجديدة أرض الجولف',
    'Heliopolis Manshet Elbakry - مصر الجديدة منشية البكري': 'مصر الجديدة منشية البكري',
    'Heliopolis Roxy - مصر الجديدة روكسي': 'مصر الجديدة روكسي',
    'Maadi Ridge - المعادي كورنيش المعادي': 'المعادي كورنيش المعادي',
    'New Maadi - المعادي الجديدة': 'المعادي الجديدة',
    'Maadi Sakr Korish - المعادي صقر قريش': 'المعادي صقر قريش',
    'Maadi Elbasateen - المعادي البساتين': 'المعادي البساتين',
    'Maadi Dar Elsalam - المعادي دار السلام': 'المعادي دار السلام',
    'Maadi Sakanat - ثكنات المعادي': 'ثكنات المعادي',
    'Maadi Degla - المعادي دجلة': 'المعادي دجلة',
    'Maadi Ellaselky - المعادي اللاسلكي': 'المعادي اللاسلكي',
    'Maadi Elmaaraj - المعادي المعراج': 'المعادي المعراج',
    'Maadi Zahraa - المعادي الزهراء': 'المعادي الزهراء',
    'Nasr City El Ahly Club - مدينة نصر النادي الأهلي': 'مدينة نصر النادي الأهلي',
    'Nasr City Masakin Aldubbat - مدينة نصر مساكن الظباط': 'مدينة نصر مساكن الظباط',
    'Nasr City Alwaha - مدينة نصر الواحة': 'مدينة نصر الواحة',
    'Nasr City 8th District - مدينة نصر الحى الثامن': 'مدينة نصر الحى الثامن',
    'Zahraa Nasr City - زهراء مدينة نصر': 'زهراء مدينة نصر',
    'Nasr City 10th District - مدينة نصر الحي العاشر': 'مدينة نصر الحي العاشر',
    'Nasr City Arab Contractors - مدينة نصر المقاولين العرب': 'مدينة نصر المقاولين العرب',
    'Nasr City Elwafa w Alamal - مدينة نصر الوفاء والأمل': 'مدينة نصر الوفاء والأمل',
    'Nasr City Aljabal Alahmar - مدينة نصر الجبل الأحمر': 'مدينة نصر الجبل الأحمر',
    'Nasr City 7th District - مدينة نصر الحى السابع': 'مدينة نصر الحى السابع',
    'Nasr City Abbas ElAkkad - مدينة نصر عباس العقاد': 'مدينة نصر عباس العقاد',
    'Nasr City Emtedad Ramsis - مدينة نصر امتداد رمسيس': 'مدينة نصر امتداد رمسيس',
    'Monshaat Naser - منشية ناصر': 'منشية ناصر',
    'Nasr City Rabaa - مدينة نصر رابعة': 'مدينة نصر رابعة',
    'Nasr City Al Azhar University - مدينة نصر جامعة الأزهر': 'مدينة نصر جامعة الأزهر',
    'Nasr City 6th District - مدينة نصر الحي السادس': 'مدينة نصر الحي السادس',
    'Faisal District - فيصل': 'فيصل',
    'El Ganaien District - الجناين': 'الجناين',
    'El Arbeen District - الأربعين': 'الأربعين',
    'El Salam District - السلام': 'السلام',
    'Ataka District - عتاقة': 'عتاقة',
    'Al-Adabya - العدبية': 'العدبية',
    'Al Suez District - منطقة السويس': 'منطقة السويس',
    'Abu Zenima - أبو زنيمة': 'أبو زنيمة',
    'Abu Rudeis - أبو رديس': 'أبو رديس',
    'Sarabium - سرابيوم': 'سرابيوم',
    'Qantara Shark - قنطرة شرق': 'قنطرة شرق',
    'Qantara Gharab - قنطرة غرب': 'قنطرة غرب',
    'Kilo 11 - الكيلو ١١': 'الكيلو ١١',
    'Hay 3 - الحي الثالث': 'الحي الثالث',
    'Hay 2 - الحي الثاني': 'الحي الثاني',
    'Hay 1 - الحي الأول': 'الحي الأول',
    'Fayed - فايد': 'فايد',
    'Saleheya Al gadida - الصالحية الجديدة': 'الصالحية الجديدة',
    'El Kasasin - القصاصين': 'القصاصين',
    'Al Tall Al kabir - التل الكبير': 'التل الكبير',
    'Abu Sultan - أبو سلطان': 'أبو سلطان',
    'Abu Soweir - أبو صوير': 'أبو صوير',
    'Abu Khalifah - أبو خليفة': 'أبو خليفة',
    'Abu Atwa - أبو عطوة': 'أبو عطوة',
    'Samsta - سمسطا': 'سمسطا',
    'New Beni Suef - بني سويف الجديدة': 'بني سويف الجديدة',
    'Nasser - ناصر': 'ناصر',
    'Ihnasya - إهناسيا': 'إهناسيا',
    'Elwasta - الواسطى': 'الواسطى',
    'Elfashn - الفشن': 'الفشن',
    'Biba - ببا': 'ببا',
    'Yousef Elsedeq - يوسف الصديق': 'يوسف الصديق',
    'Tamya - طمية': 'طمية',
    'Ebsheway - ابشواي': 'ابشواي',
    'Atsa - اطسا': 'اطسا',
    'New Assuit - أسيوط الجديدة': 'أسيوط الجديدة',
    'Balteem - بلطيم': 'بلطيم',
    'Victoria - فكتوريا': 'فكتوريا',
    'Sidi Bishr Bahary - سيدي بشر بحري': 'سيدي بشر بحري',
    'Miami - ميامي': 'ميامي',
    'El Soyof Bahary - السيوف بحري': 'السيوف بحري',
    'Mandara Bahary - المندرة بحري': 'المندرة بحري',
    'El Asafra Bahary - العصافرة بحري': 'العصافرة بحري',
    'Abu Kir - أبوقير': 'أبوقير',
    'Zezenia - زيزينيا': 'زيزينيا',
    'Tharwat - ثروت': 'ثروت',
    'Stanley - ستانلي': 'ستانلي',
    'Sporting - سبورتنج': 'سبورتنج',
    'Smouha - سموحة': 'سموحة',
    'Sidi Gaber - سيدي جابر': 'سيدي جابر',
    'San Stefano - سان ستيفانو': 'سان ستيفانو',
    'Saba Basha - سابا باشا': 'سابا باشا',
    'Roshdy - رشدي': 'رشدي',
    'Louran - لوران': 'لوران',
    'Kafr Abdu - كفر عبده': 'كفر عبده',
    'Glim - جليم': 'جليم',
    'Gianaclis - جناكلس': 'جناكلس',
    'Fleming - فليمنج': 'فليمنج',
    'Cleopatra - كليوباترا': 'كليوباترا',
    'Camp Chezar - كامب شيزار': 'كامب شيزار',
    'Bolkly - بولكلي': 'بولكلي',
    'Bakos - باكوس': 'باكوس',
    'October - أكتوبر': 'أكتوبر',
    'El Max - الماكس': 'الماكس',
    'El Hanoveel - الهانوفيل': 'الهانوفيل',
    'El Dekhela - الدخيلة': 'الدخيلة',
    'El Bitash Bahree - البيطاش بحري': 'البيطاش بحري',
    'Abou Youssef - أبو يوسف': 'أبو يوسف',
    'Agamy - العجمي': 'العجمي',
    'Sheikh Zayed Districts - أحياء الشيخ زايد': 'أحياء الشيخ زايد',
    'Beverly Hills - بيفرلى هيلز': 'بيفرلى هيلز',
    'Casa Compound - كمبوند كازا زايد': 'كمبوند كازا زايد',
    'El Rabwa Compound - كومباوند الربوة': 'كومباوند الربوة',
    'West Somid - غرب سوميد': 'غرب سوميد',
    'Al Motameyz District - الحي المتميز': 'الحي المتميز',
    'Oct Youth Residence - أكتوبر اسكان الشباب': 'أكتوبر اسكان الشباب',
    'October Districts - أحياء السادس من أكتوبر': 'أحياء السادس من أكتوبر',
    'Al Bashayer District - البشاير': 'البشاير',
    '6th of October City - أكتوبر': 'أكتوبر',
    'Pyramids Gardens - حدايق الأهرام': 'حدايق الأهرام',
    'Dream Land - دريم لاند': 'دريم لاند',
    'Pyramids Heights - مرتفعات الأهرام': 'مرتفعات الأهرام',
    'Smart Village - القرية الذكية': 'القرية الذكية',
    'New Giza - نيو جيزة': 'نيو جيزة',
    'Oct Alex Desert Road - أكتوبر الطريق الصحراوي': 'أكتوبر الطريق الصحراوي',
    'Abu Rawash - أبورواش': 'أبورواش',
    'Heliopolis - مصر الجديدة': 'مصر الجديدة',
    'Sheraton - شيراتون': 'شيراتون',
    'Nasr City - مدينة نصر': 'مدينة نصر',
    'Ain Shams - عين شمس': 'عين شمس',
    'Gesr Elsuez - جسر السويس': 'جسر السويس',
    'El Zaytoun - الزيتون': 'الزيتون',
    'EL Fustat - الفسطاط': 'الفسطاط',
    'Maadi - المعادي': 'المعادي',
    'Helwan - حلوان': 'حلوان',
    'El Hadaba El Wosta - الهضبة الوسطي': 'الهضبة الوسطي',
    'Shubra Masr - شبرا مصر': 'شبرا مصر',
    'Abbassia - العباسية': 'العباسية',
    'El Marj - المرج': 'المرج',
    'Imbaba - امبابة': 'امبابة',
    'Hadayek El Haram - حدائق الأهرام': 'حدائق الأهرام',
    'Haram - الهرم': 'الهرم',
    'Faisal - فيصل': 'فيصل',
    'Ard El Lewa - أرض اللوا': 'أرض اللوا',
    'Mit Akaba - ميت عقبة': 'ميت عقبة',
    'Mohandeseen - المهندسين': 'المهندسين',
    'Dokki - الدقي': 'الدقي',
    'Agouza - العجوزة': 'العجوزة',
    'Bulaq - بولاق': 'بولاق',
    'Abdeen - عابدين': 'عابدين',
    'Zamalek - الزمالك': 'الزمالك',
    'Garden City - جاردن سيتي': 'جاردن سيتي',
    'Downtown Cairo - وسط البلد': 'وسط البلد',
    'Ahmed Oraby Association - جمعية أحمد عرابي': 'جمعية أحمد عرابي',
    'Badr City - مدينة بدر': 'مدينة بدر',
    'New Heliopolis City - هيلوبوليس الجديدة': 'القاهرة هيلوبوليس الجديدة',
    'El Talae3 City - مدينة الطلائع': 'مدينة الطلائع',
    'Obour - العبور': 'العبور',
    'Madinaty - مدينتي': 'مدينتي',
    'Future City - مدينة المستقبل': 'مدينة المستقبل',
    '10th of Ramadan - العاشر من رمضان': 'العاشر من رمضان',
    'Abo El Houl - أبو الهول': 'أبو الهول',
    'Area 3 - المنطقة الثالثة': 'المنطقة الثالثة',
    '5th Settlement Industrial Area - المنطقة الصناعية التجمع الخامس': 'المنطقة الصناعية التجمع الخامس',
    'El Narges 8 - النرجس ٨': 'النرجس ٨',
    'El Narges 7 - النرجس ٧': 'النرجس ٧',
    'El Narges 6 - النرجس ٦': 'النرجس ٦',
    'El Narges 5 - النرجس ٥': 'النرجس ٥',
    'El Narges 4 - النرجس ٤': 'النرجس ٤',
    'El Narges 3 - النرجس ٣': 'النرجس ٣',
    'El Narges 2 - النرجس ٢': 'النرجس ٢',
    'El Narges - النرجس': 'النرجس',
    'El Lotus - اللوتس': 'اللوتس',
    'El Andalous - الأندلس': 'الأندلس',
    'North Investors Area - منطقة المسثمرين الشمالية': 'منطقة المسثمرين الشمالية',
    'South Investors Area - منطقة المستثمرين الجنوبية': 'منطقة المستثمرين الجنوبية',
    'Ganoob Al Academia - Area F - جنوب الاكاديمية': 'Area F - جنوب الاكاديمية',
    'Ganoob Al Academia - Area E - جنوب الاكاديمية': 'Area E - جنوب الاكاديمية',
    'Ganoob Al Academia - Area D - جنوب الاكاديمية': 'Area D - جنوب الاكاديمية',
    'Ganoob Al Academia - Area C - جنوب الاكاديمية': 'Area C - جنوب الاكاديمية',
    'Ganoob Al Academia - Area B - جنوب الاكاديمية': 'Area B - جنوب الاكاديمية',
    'Ganoob Al Academia - جنوب الاكاديمية': 'جنوب الاكاديمية',
    'Sharq El Academia - شرق الأكاديمية': 'شرق الأكاديمية',
    'Cairo Festival City - كايرو فستيفال سيتي': 'كايرو فستيفال سيتي',
    '1st Settlement - مجاورات التجمع الأول': 'مجاورات التجمع الأول',
    '1st Settlement El Nakhil - التجمع الأول النخيل': 'التجمع الأول النخيل',
    'El Banafseg 12 - البنفسج ١٢': 'البنفسج ١٢',
    'El Banafseg 11 - البنفسج ١١': 'البنفسج ١١',
    'El Banafseg 10 - البنفسج ١٠': 'البنفسج ١٠',
    'El Banafseg 9 - البنفسج ٩': 'البنفسج ٩',
    'El Banafseg 8 - البنفسج ٨': 'البنفسج ٨',
    'El Banafseg 7 - البنفسج ٧': 'البنفسج ٧',
    'El Banafseg 6 - البنفسج ٦': 'البنفسج ٦',
    'El Banafseg 5 - البنفسج ٥': 'البنفسج ٥',
    'El Banafseg 4 - البنفسج ٤': 'البنفسج ٤',
    'El Banafseg 3 - البنفسج ٣': 'البنفسج ٣',
    'El Banafseg 2 - البنفسج ٢': 'البنفسج ٢',
    'El Banafseg - البنفسج': 'البنفسج',
    'El Yasmeen 8 - الياسمين ٨': 'الياسمين ٨',
    'El Yasmeen 7 - الياسمين ٧': 'الياسمين ٧',
    'El Yasmeen 6 - الياسمين ٦': 'الياسمين ٦',
    'El Yasmeen 5 - الياسمين ٥': 'الياسمين ٥',
    'El Yasmeen 4 - الياسمين ٤': 'الياسمين ٤',
    'El Yasmeen 3 - الياسمين ٣': 'الياسمين ٣',
    'El Yasmeen 2 - الياسمين ٢': 'الياسمين ٢',
    'El Yasmeen - الياسمين': 'الياسمين',
    'El Rehab - الرحاب': 'الرحاب',
    'Gharb El Golf - غرب الجولف': 'غرب الجولف',
    'Arabella - ارابيلا': 'ارابيلا',
    'El Kattameya - القطامية': 'القطامية',
    'El Diplomasyeen - الدبلوماسيين': 'الدبلوماسيين',
    'El Shouyfat - الشويفات': 'الشويفات',
    '5th Settlement - أحياء التجمع الخامس': 'أحياء التجمع الخامس',
    '5th Settlement - التجمع الخامس': 'التجمع الخامس',
    '3rd Settlement - التجمع الثالث': 'التجمع الثالث',
    'الشرقية - Sharkia': 'الشرقية',
    'Sharkia - الشرقية': 'الشرقية',
    'بورسعيد - Portsaid': 'بورسعيد',
    'دمياط - Damietta': 'دمياط',
    'الدقهلية - Al-Dakahlia': 'الدقهلية',
    'Last Date for Delivery': 'آخر موعد للتسليم',
    'Order Reference No.': 'الرقم المرجعي لطلب الشراء',
    '1st': '1-',
    '2nd': '2-',
    '3rd': '3-',
    'tracking order': 'تتبع الطلب',
    'View Detailed Timeline': 'عرض الجدول الزمني المفصل',
    'Detailed Timeline': 'الجدول الزمنى المفصل',
    'Packages Info': 'بيانات الشحنه',
    'Supplies type': 'نوع الطرود',
    'Opening Package': 'يمكن فتح الشحنه',
    'Return Point': 'نقطة الاستلام',
    'Apply': 'تنفيذ',
    'Apply Promo Code': 'تنفيذ كود الخصم',
    'cannot copy!': 'لم يتم النسخ!',
    'Packing Task Lists': 'قوائم مهام الانتقاء',
    'Picking Task Lists': 'قوائم مهام الانتقاء',
    'Packing Tasklists': 'قوائم الانتقاء',
    'pickup requested': 'تم طلب اﻹستلام',
    'delivered':'تم التسليم',
    'created': 'تم إنشاء طلبك',
    'cancelled': 'تم الإلغاء',
    'picked up': 'تم إستلام طلبك',
    'in transit': 'جاري التوصيل',
    'out for delivery': 'خارج للتوصيل',
    'out for pickup': 'جاري إستلام طلبك',
    'pickup rescheduled': 'تم جدولة إستلام طلبك',
    'Pickup rescheduled': 'الطلبات التي تمت جدولة إستلامها',
    'rescheduled': 'تم جدولة إستلام طلبك',
    'delivery attempted': 'تم محاولة التوصيل',
    'return to origin': 'إعادة الطلب إلى المصدر',
    'out for return': 'جاري اعادة طلبك',
    'return rescheduled': 'تم جدولة إعادة طلبك',
    'on hold': 'في الانتظار',
    'In Transit (NC)': 'جاري فرز طلبك (NC)',
    'In Transit (DW)': 'جاري فرز طلبك (DW)',
    'In Transit (OCT)': 'جاري فرز طلبك (OCT)',
    'In Transit (ALX)': 'جاري فرز طلبك (ALX)',
    'In Transit (MNS)': 'جاري فرز طلبك (MNS)',
    'In Transit (SYT)': 'جاري فرز طلبك (SYT)',
    'In Transit (MNF)': 'جاري فرز طلبك (MNF)',
    'In Transit (TNT)': 'جاري فرز طلبك (TNT)',
    'In Transit (SHR)': 'جاري فرز طلبك (SHR)',
    'In Transit (GSZ)': 'جاري فرز طلبك (GSZ)',
    'Cancel Order': 'الغاء الطلب',
    'Restart': 'إعادة إنشاء الطلب',
    'Copied': 'تم النسخ',
    'expected delivery date': 'تاريخ التسليم المتوقع',
    'Allow open packages': 'السماح بفتح الطرد',
    'Additional Package': 'طرد اضافى',
    'Supplies Shop': 'متجر مواد التغليف',
    'Supplies Orders': 'طلبات مواد التغليف',
    'Flyers': 'Flyers',
    'Boxes': 'صناديق',
    'Other Items': 'منتجات اخري',
    'Cart': 'عربه التسوق',
    'Your cart is empty': 'عربة التسوق فارغة',
    'Add items to your cart': 'إضافة منتجات إلى عربة التسوق الخاصة بك',
    'Large Box': 'صندوق كبير',
    'Small Box': 'صندوق صغير',
    'Medium Box': 'صندوق متوسط',
    'Try Beta': 'النسخة التجريبية',
    'Stable Release': 'النسخة المستقرة',
    'Free': 'مجانا',
    'Items': 'العناصر',
    'QTY': 'الكميه',
    'Small Flyer': 'Small Flyer',
    'Medium Flyer': 'Medium Flyer',
    'Large Flyer': 'Large Flyer',
    'Extra Large Flyer': 'Extra Large Flyer',
    'Bubble Wrap': 'Bubble Wrap',
    'Stretch Wrap': 'Stretch Wrap',
    'Fragile Stickers': 'Fragile Stickers',
    'Packing Tape': 'Packing Tape',
    'Security Stickers': 'Security Stickers',
    'Check out': 'تاكيد الطلب',
    'Assigned Merchants': 'التجار المعينون',
    'Our new': 'الآن، مع تقنية ',
    'technology is now verifying delivery attempts. Our system will automatically contact the customer to confirm the attempt reason provided by the courier. This ensures accurate and reliable information, improving our service and your experience.': 'يقوم نظامنا بالتأكد من صحة محاولات التوصيل تلقائياً عن طريق الاتصال بالعميل وطلب تأكيد محاولة التوصيل عن طريق المندوب. يمكن للعميل تأكيد محاولة التوصيل، أو تأكيد عدم تواصل المندوب معه. يستخدم فريقنا رد العميل لتحسين مستوي الخدمة.',
    'Got It': 'فهمتها',
    'ShipBlu Verify™ contacted the customer, and the delivery attempt was confirmed.': 'قام نظام ShipBlu Verify™  بالتواصل مع العميل، وقام العميل بتأكيد محاولة التوصيل.',
    'Our new ShipBlu Verify™ technology is now verifying delivery attempts. Our system will automatically contact the customer to confirm the attempt reason provided by the courier. This ensures accurate and reliable information, improving our service and your experience.': 'الآن، مع تقنية ShipBlu Verify™، يقوم نظامنا بالتأكد من صحة محاولات التوصيل تلقائياً عن طريق الاتصال بالعميل وطلب تأكيد محاولة التوصيل عن طريق المندوب. يمكن للعميل تأكيد محاولة التوصيل، أو تأكيد عدم تواصل المندوب معه. يستخدم فريقنا رد العميل لتحسين مستوي الخدمة.',
    'ShipBlu Verify™ contacted the customer, but the customer did not pick up the phone.': 'قام نظام ShipBlu Verify™  بالتواصل مع العميل، ولكن العميل لم يقم بالرد علي الاتصال.',
    'ShipBlu Verify™ contacted the customer, and the delivery attempt was not confirmed.': 'قام نظام ShipBlu Verify™  بالتواصل مع العميل، وقام العميل بتأكيد عدم دقة محاولة التوصيل.',
    'All successful data has been sent, and failed data has been downloaded into another file.': 'تم إرسال جميع البيانات الناجحة ، وتم تنزيل البيانات الخاطئه في ملف آخر.',
    'All SMS has been added successfully!': 'تم إضافة جميع الرسائل القصيرة بنجاح',
    'Outgoing Messages': 'الرسائل الصادرة',
    'External Links': 'روابط خارجية',
    'Send Individual Message': 'إرسال رسالة فردية',
    'Upload Multiple SMS': 'تحميل رسائل SMS متعددة',
    'ShipBlu Shield fees will be applied on this amount if it is provided.\n This amount will not be shown to your customer.': 'سيتم تطبيق رسوم باقات حماية شب بلو على هذا المبلغ إذا تم توفيره. \n لن يظهر هذا المبلغ لعميلك.',
    'Pickup from your location': 'استلم من موقعك',
    'Select your pickup Location:': 'اختر موقع الاستلام:',
    'More Information': 'معلومات اضافيه',
    'Full Address': 'العنوان بالكامل',
    'Packages Details': 'الطرود',
    'Number of Packages': 'عدد الطرود',
    'This is the amount we will collect from your customer': 'هذا هو المبلغ الذي سنجمعه من عميلك',
    'Your Delivery Notes...': 'ملاحظاتك...',
    'Add Package Description...': 'اضف وصف....',
    'The number of flyers or boxes': 'عدد الطرود',
    'Wrong File!': 'ملف خاطئ!',
    'Legacy Upload': 'التحميل العادي',
    'For help and guidance on how to upload multiple orders, check out our ': 'للمساعدة والتوجيه حول كيفية تحميل طلبات متعددة - تحقق من هذا ',
    'knowledge center': 'مركز المعرفة',
    'Click To Upload': 'انقر لتحميل',
    'Supports files of .xls and .xlsx formats': '(يدعم ملفات .xls و. xlsx)',
    'Upload File': 'رفع الملف',
    'Fill Manually': 'املأ يدويًا',
    'All added orders are with': 'جميع الطلبات المضافة',
    'Are you sure you want to proceed?': 'هل انت متأكد انك تريد المتابعة؟',
    'Merchant Reference Number': 'الرقم المرجعي ',
    'Secondary Phone': 'رقم تليفون اضافي',
    'Line 2': 'بيانات اضافيه للعنوان',
    'Optional columns': 'أعمده اختياريه',
    'Allow all packages to be opened': 'السماح بفتح جميع الحزم',
    'Open package': 'فتح الشحنه',
    'Add Extra Rows': 'أضف صفوف إضافية',
    'Discount Code': 'كود الخصم',
    'Products Declared Value': 'قيمة المنتجات المعلنة',
    'Counter Drop Off': 'تسليم الشحنات في مخازنا',
    'Number of packages': 'عدد الطرود',
    'We will auto-select the Governorate, City & Zone values based on the “Address” field you fill': 'سنقوم تلقائيًا بتحديد قيم المحافظة والمدينة والمنطقة بناءً على حقل "العنوان" الذي تملأه',
    'Line 1': 'العنوان',
    'or drag and drop your orders sheet': 'أو قم بسحب وإسقاط ورقة الطلبات الخاصة بك',
    'Mark all orders as Fragile': 'اجعل كل الطلبات قابله للكسر',
    'Successful orders': 'طلبات ناجحة',
    'Unsuccessful orders': 'طلبات غير الناجحة',
    'Replace File': 'استبدل الملف',
    'Address Line 2': 'بيانات اضافية للعنوان',
    'Choose this option if you will drop the package to the nearest ShipBlu branch': 'هذا الخيار إذا كنت ستقوم بتسليم الشحنة إلى أقرب فرع شيب بلو',
    'Are you sure to remove this row?': 'هل أنت متأكد من إزالة هذا الصف؟',
    'For help and guidance on how to pack your orders, check out our':'للحصول على المساعدة والإرشادات حول كيفية تغليف طلباتك ، تحقق من',
    'packaging guidelines':'إرشادات التغليف',
    'our':'الخاصة بنا',
    'We are constantly expanding our shipping coverage. Meanwhile, please check the current ': 'نحن نعمل باستمرار على توسيع نطاق تغطية الشحن لدينا. يرجى التحقق من ',
    'uncovered zones': 'المناطق الغير متوفرة',
    'NOW': ' حاليًا',
    'Add your order reference No.': 'أضف رقم مرجع الطلب الخاص بك.',
    'We will auto select the Governorate ,City & Zone based on what you filled in the “Full Address” field': 'سيتم اختيار بشكل تلقائي المحافظه, المدينه و المنطقة بنا علي العنوان الكامل الذي تم ادخاله',
    'The easiest way to find and share an exact location by sharing its W3words': 'اسهل طريقه لايجاد و مشاركه موقعك هي خلال What3words',
    'Choose this option if you will drop the Package to the nearest ShipBlu Branch': 'اختار هذا الاختيار ان كنت تريد ان توصل الشحنه الي اقرب فرع ل ShipBlu',
    'Print Returns': 'طباعه المرتجعات',
    'Move to Another Task List': 'نقل الطلب الي مهام شحن اخر',
    'This amount will be paid to your customer.': 'سيتم دفع هذا المبلغ لعميلك.',
    'Mokatam Warehouse': 'مخزن المقطم',
    'Refund orders will only be attempted if there are sufficient funds in your account.': 'يتم تنفيذ طلبات إرجاع النقود فقط في حالة وجود رصيد كافي في حسابك.',
    'Cash already collected or some orders are still with agent. Refresh the page.': 'تم استلام المبلغ بالفعل أو بعض الطلبات مازالت مع المندوب. قم بتحديث الصفحة.',
    'Please, confirm you collected': 'من فضلك، قم بتأكيد أنك قمت باستلام مبلغ',
    '. Type in the number below!': '. اكتب الرقم فى الخانة أسفله',
    'Are you sure to empty this slot?': 'هل انت متأكد انك تريد افراغ هذا الرف؟',
    'Are you sure to delete this package?': 'هل انت متأكد انك تريد حذف هذا الطرد؟',
    'Packages IDs': 'ارقام الطرود',
    'due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.': 'لعدم استخدامه لفترة طويلة. يمكنك التحدث مع أحد ممثلي فريق الدعم من خلال أيقونة الشات بأسفل الشاشة لإعادة تفعيل حسابك.',
    'Cancel Supplies Request': 'الغاء طلب مواد التغليف',
    'Download auto-zoning template': 'تحميل نموذج التقسيم التلقائى',
    'New Order': 'طلب جديد',
    'Please, Scan all packaes in the order first!':'من فضلك ، امسح جميع الحزم بالترتيب أولاً!',
    'Your Locations':'المواقع الخاصة بك',
    'attempted':'محاولة التوصيل',
    'Start Shipping!': 'ابدأ الشحن',
    'One per page': 'بوليصة لكل صفحة',
    'Expired': 'منتهي',
    'New': 'جديد',
    'App Notifications': 'اشعارات التطبيق',
    'pending payment': 'في انتظار الدفع',
    'expired': 'منتهي الصلاحية',
    'requested': 'مطلوب',
    'Payment Status': 'حاله السداد',
    'Pay': 'أدفع',
    'successful': 'ناجح',
    'failed': 'فشل',
    'Fees': 'رسوم',
    'pending': 'قيد الانتظار',
    'Price Details': 'تفاصيل السعر',
    'Payment Method': 'طريقه الدفع',
    'Payment Type': 'طريقة الدفع',
    'Your Order has been confirmed': 'تم تأكيد طلبك',
    'Select one of your locations': 'حدد أحد المواقع الخاصة بك',
    'Terms of Service': 'شروط الاستخدام',
    'I have read and agree to the Terms and Conditions.': 'لقد قرأت ووافقت على الشروط والأحكام.',
    'Accept': 'أوافق',
    'Decline': 'ارفض',
    'Autozoning Upload': 'تحميل منطقة ذاتى',
    'Shipping Fees': 'مصاريف الشحن',
    'FC Receiving Agent': 'مدير استلام التخزين',
    'FC Inbounding Agent': 'مدير توريد التخزين',
    'FC Storing Agent': 'مدير ترتيب التخزين',
    'FC Packing Agent': 'مدير تعبئه التخزين',
    'FC Picking Agent': 'مدير فرز التخزين',
    'submitted': 'تم الإرسال',
    'Ready': 'جاهز',
    'received': 'تم الاستلام',
    'inbounded': 'وارد',
    'stored': 'مخزن',
    'Cancelled': 'تم الإلغاء',
    'partially received': 'استقبلت جزئيا',
    'resolved': 'تم الحل',
    'processing': 'يتم المعالجة',
    'ready to ship': 'على استعداد للشحن',
    'confirmed to ship': 'أكد لشحن',
    'picking': 'الانتقاء',
    'packing': 'التعبئة',
    'shipped': 'تم شحنها',
    'completed': 'تم الانتهاء',
    'failed to receive': 'فشل في الاستلام',
    'Edit Bins': 'تعديل السلات',
    'Edit Bin': 'تعديل السلة',
    'Add Bins': 'اضافة سلة',
    'Add Bin': 'اضافة سلة',
    'Delete Bin': 'حذف سلة',
    'No. Products': 'عدد المنتجات',
    'Storage Units': 'وحدات تخزين',
    'Tote': 'حمل',
    'Box': 'صندوق',
    'Pallet': 'منصة نقالة',
    'Shelf': 'رفوف',
    'Picking List': 'قائمة الانتقاء',
    'Packing List': 'قائمة التعبئة',
    'new': 'جديد',
    'single': 'فردي',
    'failed to fulfill': 'فشل في التخزين',
    'Sign Up': 'ابدأ الشحن مع شيب بلو في خطوة واحدة',
    'Balance Due': 'الرصيد المستحق',
    'Cash Collection Balance': 'رصيد التحصيل النقدي',
    'Payout': 'المدفوعات',
    'Mark Reviewed': 'تمت المراجعة',
    'Are you sure you want to mark': 'هل أنت متأكد من مراجعة',
    'statements as reviewed?': 'كشف حساب؟',
    'Please, select issued statements.': 'من فضلك، اختر كشوف حساب لم يتم مراجعتها',
    'ready': 'جاهز',
    'reviewed': 'تمت مراجعته',
    'Estimated Pickup Date' : 'يوم الاستلام المتوقع',
    'Preferred Pickup Date': 'يوم الاستلام المفضل',
    'Last Date for Pickup': 'اخر موعد للاستلام',
    'Pickup Window': 'وقت الاستلام المفضل',
    'Cannot generate a shipping label for unzoned orders.': 'لا يمكنك طباعة بوليصة شحن لطلب غير محدد المنطقة',
    ' EGP': ' جنيه',
    'Select your payment method.': 'اختر طريقه الدفع',
    'Pending': 'قيد الانتظار',
    'Re-zoning':'إعادة تقسيم المناطق',
    'Address Line 1' : 'العنوان',
    'Your order has been confirmed successfully!' : 'تم تاكيد طلبك بنجاح',
    'Shipment doesn\'t follow the packaging guidelines' : 'تغليف الشحنة غير مطابق للتعليمات',
    'You can also filter by their status' : 'يمكنك أيضًا التصنيف حسب حالتهم',
    'Dashboard overview.' : 'نظرة عامة على لوحة القيادة.',
    'Click here to open the creation options' : 'انقر هنا لفتح خيارات الإنشاء',
    '<br>1- Create a new delivery order <br> 2- Request a return from a customer <br> 3- Request to exchange an order that we already delivered<br> 4- Create a new cash collection request<br> 5- Request supplies<br> 6- Batch-upload delivery orders from an excel sheet':'<br>١- اضافة طلب توصيل جديد <br> ٢- طلب استرجاع من العميل <br> ٣- اضافة طلب استبدال <br> ٤- اضافة طلب تحصيل نقدى<br> ٥- طلب مواد تغليف <br> ٦- اضافة عدد من الشحنات باستخدام ملف اكسيل',
    'Your orders got delivered successfully? Find out how to add a <b>payout method</b> to receive the collected cash by following ': 'تم توصيل شُحناتك بنجاح؟ اعرف ازاي تضيف طريقة لاستلام المبلغ المُجمع عن طريق الضغط على ',
    'Looking for enhanced protection for your shipments? Explore <b>ShipBlu Shield</b> Plans by following ': 'بتدور عن حماية كاملة لشُحناتك؟ اعرف أكتر عن <b>ShipBlu Shield</b> عن طريق الضغط على ',
    'Seeking a seamless delivery experience for your shipments? Discover our <b>packaging guidelines</b> by following ': 'بتدور على تجربة شحن سلسة ومضمونة لشُحناتك؟ اعرف أكثر عن <b>إرشادات التعبئة والتغليف</b> من خلال الضغط على ',
    'We are making efforts in expanding our network of covered zones. Stay updated on the current list of <b>uncovered zones</b> and areas by following ': 'احنا بنبذل جهود مستمرة لتوسيع شبكتنا من المناطق المغطاة. إعرف أكثر عن المناطق غير المغطاة في الوقت الحالي عن طريق الضغط على ',
    'this link': 'هذا الرابط',
    'had an attempt:': 'تمت محاولة توصيله ولكن',
    'Hello': 'مرحبا',
    'Delivery success rate': 'معدل نجاح التسليم',
    'Financial Center': 'الماليات',
    'Service Fees': 'تكلفه الشحن',
    'Last Payout': 'أخر تحويل نقدي',
    ' Pricing Plan. Your monthly order target is set at ': ' خطة التسعير. تم تعيين هدف الطلب الشهري الخاص بك على ',
    ' shipments. Aim to reach this target within the month and enjoy discounted prices for the remainder of that month.' :'شحنات. اهدف إلى الوصول إلى هذا الهدف خلال الشهر والاستمتاع بأسعار مخفضة لبقية ذلك الشهر.',
    'Your average shiping price': 'متوسط مبلغ الشحن خاصتك',
    'Your dashboard gives you quick insights about your logistics needs.' : 'تمنحك لوحة المعلومات الخاصة بك نظرة سريعة حول احتياجاتك اللوجستية.',
    'Click here to create a new delivery order.' : 'اضغط هنا لإنشاء طلب توصيل ',
    'Now, add customer details below' : 'الآن قم بإضافة تفاصيل العميل',
    'Great! Now, select the number of packages and optionally add description and cash on delivery amount.' : 'رائع! الآن، حدد عدد الطرود وأضف الوصف والمبلغ النقدي عند الاستلام',
    'Optionally, add a reference number for the order and leave any note for our delivery agent.' : 'يمكنك اضافة رقم مرجعي للطلب واترك أي ملاحظة لمندوب التوصيل لدينا.',
    'Your are now one-step ahead from creating your first order. Click Create Order now!' : 'أنت الآن في الخطوة الأخيرة لانشاء طلب التوصيل الأول لك. اضغط علي انشاء الطلب الآن!',
    'Here you can view all your delivery orders.' : 'هنا يمكنك عرض كافة طلبات التوصيل الخاصة بك.',
    'Now, print your shipping label' : 'الآن قم بطباعة بوليصة الشحن الخاصة بالطلبات',
    'If your profile is reviewed and verified, you can request pickup here.' : 'مع اتمام مراجعة ملفك الشخصي من قبل فريقنا، يمكنك طلب حضور مندوب للاستلام من هنا.',
    'You may request flyers or buy shipping boxes and supplies from here.' : 'يمكنك طلب الفلايرز أو شراء مستلزمات الشحن من هنا.',
    'Click here to add a payout method, know when you will get your COD and explore your pricing plan.' : 'اضغط هنا لإضافة حسابك البنكي لاستلام تحصيلاتك النقدية، ومعرفة أوقات تحويلها ومراجعة باقة الأسعار الخاصة بك.',
    'If you have larger monthly volume of shipping, contact our sales team to receive discounts. Thank you for choosing ShipBlu. We look forward to serve you!' : 'إذا كان لديك عدد شحنات أكبر شهريا، قم بالتواصل بفريق الدعم لدينا للحصول على خصومات خاصة. شكرًا لاختيارك شيب بلو. ونحن نتطلع لخدمتك!',
    'Notification Center': 'الاشعارات',
    'Pickups this month': 'استلامات هذا الشهر',
    'Returns this month': 'مرتجعات هذا الشهر',
    'You are on the': 'أنت علي باقة أسعار ',
    'Entrepreneur': 'رائد أعمال',
    'Entrprise':'شركات خاصة',
    ' pricing plan. You receive special discounts once you ship ': ' تمنحك الباقة خصومات خاصة عند شحن ',
    ' order in a month.': ' طلب في خلال الشهر.',
    'Average Shipping Fees': 'متوسط سعر الشحن',
    'Congratulations! Your account is now verified and you can request a pickup for your orders. Pickups requested before': 'أهلاً بيك في شيب بلو! تم تفعيل حسابك ويمكنك الآن طلب حضور مندوب استلام بشكل دوري لاستلام طلباتك للتوصيل. اطلب الاستلام قبل الساعة',
    'will be picked on the same day.': 'وسيصلك المندوب في نفس اليوم.',
    'Thank you for signing up with us! Your account is currently under review and requesting pickup is disabled. Reviews are typically complete with 1-2 business days. If you want to ship your orders today, you are welcome to drop off your packages at': 'شكراً لاستكمال بيانات حسابك! يقوم فريقنا الآن بمراجعة الحساب وعنوان الاستلام للتأكيد علي موعد الاستلام. يقوم بفريقنا بالمراجعة في خلال يومين عمل علي الأكثر. إدا كنت ترغب في توصيل طلباتك اليوم، يمكنك طباعة البوليصة واحضار الشحنات',
    'one of our locations': 'إلي أحد فروعنا',
    'and we will be happy to serve you.': 'قم بالتحدث مع فريق العمل من خلال ايقونة المحادثة بالأسفل للتنسيق.',
    'Phone number is not related to the customer': 'رقم التليفون لا يخص العميل',
    'Phone Number is not related to the customer': 'رقم التليفون لا يخص العميل',
    'Wrong zone': 'منطقه خاطئه',
    'Customer is avoiding the shipment': 'العميل يتهرب من الاستلام',
    'Customer wants to Change Address': 'يريد العميل تغيير العنوان',
    'Verification code is unavailable': 'كلمه المرور غير متوفره مع العميل',
    'Customer doesn\'t have enough money': 'العميل لا يملك المبلغ الكافي',
    'Wrong COD': 'يوجد خطأ في السعر',
    'Customer refused to accept delivery': 'رفض العميل استلام الشحنه',
    'Are you sure you want to confirm these Counter Returns?' : 'هل أنت متأكد أنك تريد تأكيد هذه الإرجاعات؟',
    'Your merchant profile is incomplete. You can explore around with limited functionality.': 'بعض تفاصيل حسابك غير مكتملة. يمكنك تجربة التطبيق مع وجود بعض الخصائص غير مفعلة. اضغط هنا لاستكمال بيانات حسابك',
    'Click here': 'اضغط هنا',
    'to complete profile.': 'لاستكمال بيانات حسابك.',
    'Your Location': 'الموقع الخاصة بك',
    'Let us help you create the perfect pricing plan tailored just for you. Get ready to take your business to the next level!': 'سنقوم بتحديث باقة أسعارك للحصول علي خصومات خاصة تساعدك في التوسع في أعمالك',
    'Your Company Name "will be displayed on the shipping label"': 'اسم الشركة أو الصفحة "نقوم باستخدام الاسم عند التواصل مع العملاء"',
    'Your Avg. Monthly Volume': 'متوسط عدد شحناتك الشهرية',
    'URL Link (Website, IG Page, FB Page... etc)': 'رابط موقعك أو صفحة السوشيال ميديا',
    'Industry': 'مجال العمل',
    'Industries': 'مجالات العمل',
    'Get Started': 'ابدأ الآن',
    'Company Name': 'اسم الشركه',
    'Are you sure to cancel these orders?': 'هل أنت متأكد من إلغاء هذه الطلبات؟',
    'password must contain': 'كلمة المرور يجب أن تحتوي على',
    'At least one lowercase letter': ' 1 حرف إنجليزي صغير على الأقل',
    'At least one uppercase letter': '1  حرف إنجليزي كبير على الأقل',
    'At least one number': '1  رقم على الأقل',
    'Minimum 8 characters': 'الحد الأدنى لعدد الأحرف هو 8',
    'Mixed': 'متنوع',
    'Coffee, Beans, Oil, Honey & Herbs': 'حبوب، قهوة، زيت، عسل وأعشاب',
    'Accessories': 'إكسسوارات',
    'Mobile & Laptop Accessories': 'إكسسوارات الموبايل والكمبيوتر',
    'Home Accessories': 'إكسسوارات منزلية',
    'Apparel, Fashion and Clothes': 'أزياء وملابس',
    'Pharmaceutical Medicine, and supplements': 'أدوية ومكملات غذائية',
    'Beauty Cosmetics, Perfumes & Personal Care': 'مستحضرات التجميل والعطور والعناية الشخصية',
    'Detergent and Cleaners': 'منظفات',
    'Jewelry & Expensive Collectibles': 'المجوهرات والمقتنيات الثمينة',
    'Car & Motorcycle Accessories': 'إكسسوارات السيارات والدراجات النارية',
    'Electronics': 'اﻷجهزة اﻹلكترونية',
    'Gifts & Printed Materials': 'الهدايا والمواد المطبوعة',
    'Eyeglasses and Contact Lenses': 'النظارات والعدسات اللاصقة',
    'Vending, Coffee & Food Machines': 'آلات البيع والقهوة والطعام',
    'Books': 'كتب',
    'Pet Supplies & Accessories': 'مستلزمات وملحقات الحيوانات الأليفة',
    'Bags, Packs, Bottles & Flasks': 'العلب والأكياس والزجاجات',
    'Documents': 'أوراق ووثائق',
    'Handmade Products': 'منتجات مصنوعة يدوية',
    'Shortfall': 'عجز',
    'Agents Transactions': 'Agents Transactions',
    'Full Amount': 'المبلغ الكامل',
    'partial Amount': 'المبلغ الجزئي',
    'No Show': 'لا يوجد',
    'Please, choose one of the following actions before completing the task list': 'من فضلك، اختر أحد الإجراءات التالية قبل إكمال قائمة المهام',
    'Settle Shortfall': 'تسوية النقص',
    'Your Latest Statement': 'آخر كشف حساب',
    'This section will be revised according to your next statement. "Cash on the way" refers to the specific amount of money that will be transferred to you.': 'سيتم تحديث هذا القسم وفقًا لكشف حسابك. يشير مصطلح "قيمة التحويل القادم" إلى المبلغ المحدد من المال الذي سيتم تحويله إليك.',
    'Cash on the way ': 'قيمة التحويل القادم',
    'Cash Collection Balance ': 'قيمة التحصيل النقدي',
    'Service Fees Due': 'قيمة رسوم خدمة الشحن',
    'Amount On Hold': 'مبالغ عمليات قيد التنفيذ',
    'Top Up': 'إيداع',
    'Deposit any amount into your account, fueling supplies requests and ensuring smooth customer refunds.': 'قم بإيداع أي مبلغ في حسابك، لطلب مواد التغليف وتنفيذ عمليات اﻹرجاع بشكل سلس.',
    'Transactions History': 'سجل العمليات',
    'Deposit': 'ايداع',
    'all': 'الكل',
    'deposit': 'إيداع',
    'payout': 'محولة', 
    'refunds': 'مردودة',
    'supplies': 'طلبات',
    'transaction supplies request': 'طلبات مواد التغليف',
    'transaction cash collected': 'تحصيل نقدي',
    'transaction deposit': 'إيداع',
    'transaction payout': 'مبالغ محولة',
    'transaction refunds': 'مبالغ مردودة',
    'Debit/Credit Card Top-up': 'إيداع عن طريق كروت الدفع المباشر',
    'Bank Transfer Top-up': 'إيداع عن طريق التحويل البنكي',
    'Transfers must be from a bank account under your name.': 'التحويلات يجب أن تكون من حساب بنكي بإسمك.',
    'Please allow up to 2 business days to process your top-up request with Instapay.': 'قد يتخذ مراجعة وتنفيذ طلبك إلى مدة قد تصل يومين عمل',
    'Deposit Date:': 'تاريخ اﻹيداع:',
    'Deposited Amount:': 'قيمة اﻹيداع:',
    'Transaction Reference Number:': 'الرقم المرجعى للمعاملة:',
    'Confirm Deposit': 'تأكيد اﻹيداع',
    'Your request to deposit an amount of EGP': 'لقد تم استلام طلبك لإيداع مبلغ ',
    'has been received and your account will be updated within 2 Business Days.': 'بالجنيه المصري وسيتم تحديث حسابك خلال يومين عمل.',
    'Top-up Method': 'طريقة الإيداع',
    'How much to top-up with?': 'المبلغ الذى ستقوم بدفعه',
    'Debit/Credit Card': 'بطاقة الخصم/الائتمان',
    'Bank Transfer': 'تحويل بنكي',
    'Account Number (EGP)': 'رقم الحساب (ج.م)',
    'Beneficiary Name': 'اسم صاحب الحساب',
    'Open the Instapay app and make a transfer with using these details.': 'افتح تطبيق إنستاباي وقم بعمل تحويل باستخدام هذه التفاصيل.',
    'ShipBlu does not have a payment address on Instapay, please make sure to use the account number for transfers.': 'شيب بلو ليس لديها عنوان دفع على إنستاباي، يرجى التأكد من استخدام رقم الحساب للتحويلات.',
    'Sent a pickup request. You will receive a notification once done.': '.ارسلت طلب استلام. سوف تتلقى إشعارًا بمجرد الانتهاء.',
    'This score represents the average BluScore of your picked orders.': 'هذه النتيجة تمثل متوسط الـ BluScore لطلباتك المستلمة.',
    'Return to your location': 'العودة إلى موقعك',
    'Choose this option if you will get the Return Package from the ShipBlu warehouse': 'اختر هذا الخيار إذا كنت ستحصل على حزمة الإرجاع من مستودع ShipBlu',
    'Upload Receipt': 'إضافة إيصال',
    'Processing Fees': 'رسوم التحويل',
    'we will generate orders label and sent to you notification after finish it to download it.': 'سنقوم بإنشاء ملصق الطلبات وإرسال إشعار إليك بعد الانتهاء منه لتنزيله.',
    'All Selected': 'الكل محدد',
    'Pickup requested': 'الطلبات التي تم طلب استلامها',
    'in transit & en route': 'جاري التوصيل & جاري نقل طلبك',
    'Total: ': 'المجموع: ',
    'By choosing "Cancel & Return", you acknowledge that you have communicated with your customer and verified their decision not to proceed with the order. Upon this confirmation, our team will initiate the process to return your order.': 'باختيارك "إلغاء وإرجاع"، فإنك تقر بأنك قد تواصلت مع عميلك وتحققت من قرارهم بعدم إستكمال الطلب. بناءً على هذا التأكيد، سيبدأ فريقنا في إجراءات إعادة طلبك.',
    'Thank you for choosing': 'شكرا لاختياركم',
    'Ship':'شيب',
    'Blu': ' بلو',
    'as your eCommerce Shipping partner.' : 'كشريك شحن لمتجركم اﻹلكتروني.',
    '1st Attempt Delivery Rate:': 'نسبة التسليم من المحاولة اﻷولى:',
    'Your Average Shipping Fees': 'متوسط سعر الشحن الخاص بك',
    'Ship more, save more on your shipments with ShipBlu.': 'كل ما تشحن أكتر مع شيب بلو، هتوفر أكتر في تكاليف الشحن بتاعتك.',
    'Your Base Price:': 'سعر الشحن اﻷساسي:',
    'Access Price Plan': 'اطلع على خطة الأسعار',
    'Refunds': 'سداد المرتجعات',
    'Amounts On Hold': 'المبالغ المعلقة',
    'Access Wallet': 'اطلع على المحفظة',
    'returned': 'مرتجع',
    'Subscribe to ShipBlu notifications to get the important updates.': 'اشترك في إشعارات ShipBlu للحصول على التحديثات الهامة.',
    'You can disable notifications anytime from your browser.': 'يمكنك تعطيل الإشعارات في أي وقت من متصفحك.',
    'Later': 'لاحقا',
    'Allow': 'السماح',
    'Wallet Balance': 'رصيد المحفظة',
    'Expected Payout': 'المدفوعات المتوقعة',
    'Instant Payout': 'سحب فوري',
    'Your Gateway to Swift Cash Flow Awaits': 'تحويلات فورية لتحصيلاتك',
    'Get ready to transform the way you access and withdraw your confirmed collected cash from Cash on Delivery (COD) orders. This is designed to empower you to reinvest in your business, manage your finances effectively, and stay ahead in the fast-paced world of eCommerce.': 'استعد لتجربة اسرع عملية سحب وتحويل لتحصيلات شُحناتك المؤكدة، وإللي هتساعدك تستثمر تاني في مشروعك، تدير حساباتك بشكل فعال، وتظل دائمًا في الصدارة في عالم التجارة الإلكترونية السريع.',
    'Refunds In Progress': 'مدفوعات للعملاء قيد التنفيذ',
    'confirmed to return': 'تأكيد الرجوع',
    'confirm to return': 'تأكيد الرجوع',
    'Are you sure you want to confirm storing':'هل أنت متأكد أنك تريد تأكيد تخزين؟',
    'Product(s) out of': 'منتج من',
    'Others': 'اخري',
    'Stored Quantity': 'الكمية المخزنة',
    'on its way': 'فى الطريق',
    'lost': 'ضائع',
    'damaged': 'تالف',
    'forfeited': 'مُصادَر',
    'Please contact support':'من فضلك تواصل مع الدعم',
    'Please open the camera permission': 'يرجى فتح إذن الكاميرا',
    'Services in Progress': 'خدمات قيد التنفيذ',
    'Available Cash Balance': 'الرصيد النقدي المتاح',
    'ShipBlu Upfront': 'التحصيل مقدما',
    'Action Center': 'مراجعه الطلبات',
    'Your Wallet Balance': 'رصيد المحفظه',
    'Please confirm your request to receive cash for your completed orders today.': 'من فضلك، قم بتأكيد طلب السحب الفوري ليصلك التحويل اليوم.',
    '80% of Available Cash Balance': '80% من الرصيد النقدي المتاح',
    'Instant Payout Fees (1.5%)': 'مصاريف السحب الفوري (1.5%)',
    'Receive your cash for completed orders today!': 'احصل على أموالك مقابل الطلبات المكتملة اليوم!',
    'You receive': 'استلام',
    'Instant Payouts': 'سحب فوري',
    'The description should preferably be written in Arabic and include the products with their exact quantities.': 'الوصف يُفضل أن يكون مكتوبًا باللغة العربية ويتضمن المنتجات مع أعدادها.',
    'OPS Admins': 'مشرفين العمليات',
    'OPS Admin': 'مشرف العمليات',
    'Add OPS Admin': 'اضافة مشرف العمليات',
    'Edit OPS Admin': 'تعديل مشرف العمليات',
    'Create your account': 'إنشاء حساب جديد',
    'Ship Smarter.. Grow Faster': 'اشحن أذكى .. اكبر أسرع',
    'Must contain alphabets only': 'يجب أن يحتوي على أحرف أبجدية فقط',
    'Must be a valid email': 'يرجى إدخال بريد إلكتروني صالح',
    'Must be a valid mobile number': 'يجب أن يكون رقم الهاتف المحمول صالحًا',
    'At least one capital letter': 'على الاقل حرف انجليزي واحد كابيتال',
    'At least one small letter': 'على الاقل حرف انجليزي واحد صغير',
    'At least one special characters:': 'على الأقل حرف خاص واحد:',
    'Password must be at least 8 characters': 'يجب أن تكون كلمة المرور 8 أحرف على الأقل',
    'Passwords don’t match': 'كلمات المرور لا تتطابق',
    'Have an account? Sign in': 'لديك حساب؟ سجّل الدخول',
    'Your account is currently under review, you are welcome to drop off your packages at ': 'يقوم فريقنا الآن بمراجعة حسابك ولكن يمكنك تسليم شحناتك',
    ' until your profile is verified.': ' حتي يتم التحقق من ملفك الشخصي.',
    'Payouts': 'الإيداعات',
    'Statement Payouts': 'إيداعات كشف الحساب',
    'Upfront Payouts': 'إيداعات Upfront',
    'Automated Codes': 'أكواد خصم تلقائية',
    'Order search': 'البحث عن الطلبات'
  }
}